import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { Option } from 'src/tools/form/types/Option';

export enum FileType {
    Administrative = 1,
    Pedagogic,
    Positioning,
    Track,
    Handicap,
    Exchange,
    Formation,
    Certification,
    Other,
}
export const fileTypeAsOptions: Option[] = [
    {
        id: FileType.Administrative,
        label: I18nKeys.FORM_LABEL_ADMINISTRATIVE
    },
    {
        id: FileType.Pedagogic,
        label: I18nKeys.FORM_LABEL_PEDAGOGIC
    },
    {
        id: FileType.Positioning,
        label: I18nKeys.FORM_LABEL_POSITIONING
    },
    {
        id: FileType.Track,
        label: I18nKeys.FORM_LABEL_TRACK
    },
    {
        id: FileType.Handicap,
        label: I18nKeys.FORM_LABEL_HANDICAP
    },
    {
        id: FileType.Exchange,
        label: I18nKeys.FORM_LABEL_EXCHANGE
    },
    {
        id: FileType.Formation,
        label: I18nKeys.FORM_LABEL_FORMATION
    },
    {
        id: FileType.Certification,
        label: I18nKeys.FORM_LABEL_CERTIFICATION
    },
    {
        id: FileType.Other,
        label: I18nKeys.LABEL_OTHER
    },
] 