import { Entity, Upload, Address, User, Employee } from 'src/model/model';

export   class Company extends Entity {
  name: string = '';

  siret: string = '';

  capital: number;

  phone: string = '';

  website: string = '';

  avatar: Upload | File = new Upload();

  address: Address = new Address();

  workers: User[] = [];

  manager: User;

  constructor(data: Partial<Company> = null, clone: boolean = false) {
    if (!clone && data instanceof Company) return data;
    super(data);

    this.name = data?.name ?? '';
    this.siret = data?.siret ?? '';
    this.capital = data?.capital ?? null;
    this.phone = data?.phone ?? '';
    this.website = data?.website ?? '';
    this.avatar = data?.avatar ? new Upload(data.avatar as Upload) : null;
    this.address = data?.address ? new Address(data.address) : null;
    this.manager = data?.manager ? new Employee(data.manager) : null;
    this.workers = data?.workers?.map((worker) => new Employee(worker)) ?? [];
  }
}
