import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { Attendance, Event } from 'src/model/model';
import EntityHelper from 'src/services/Entity/Helpers/EntityHelper';

interface SessionTrackState {
    events: Event[];
    selectedEvent: Event;
    selectedAttendance: Attendance;
}

const initialState: SessionTrackState = {
    events: [],
    selectedEvent: null,
    selectedAttendance: null,
};

const slice = createSlice({
    name: 'sessionTrack',
    initialState,
    reducers: {
        setEvents(state: SessionTrackState, action: PayloadAction<{ events: Event[] }>) {
            state.events = action.payload.events
        },
        setSelctedEvent(state: SessionTrackState, action: PayloadAction<{ event: Event }>) {
            state.selectedEvent = action.payload.event
        },
        setSelctedAttendance(state: SessionTrackState, action: PayloadAction<{ attendance: Attendance }>) {
            state.selectedAttendance = action.payload.attendance;
        },
        updateEvent(state: SessionTrackState, action: PayloadAction<{ event: Event }>) {
            state.events = EntityHelper.merge(state.events, action.payload.event);
            state.selectedEvent = action.payload.event;
            state.selectedAttendance = EntityHelper.find(action.payload.event.attendances, state.selectedAttendance.id);
        },

    }
});

export const reducer = slice.reducer;

export const dispatchEvents =
    (events: Event[]): AppThunk =>
        async (dispatch) => {
            dispatch(
                slice.actions.setEvents({ events })
            );
        };
export const dispatchSelectedEvent =
    (event: Event): AppThunk =>
        async (dispatch) => {
            dispatch(
                slice.actions.setSelctedEvent({ event })
            );
        };
export const dispatchSelectedAttendance =
    (attendance: Attendance): AppThunk =>
        async (dispatch) => {
            dispatch(
                slice.actions.setSelctedAttendance({ attendance })
            );
        };
export const dispatchUpdateEvent =
    (event: Event): AppThunk =>
        async (dispatch) => {
            dispatch(
                slice.actions.updateEvent({ event: new Event(event) })
            );
        };


export default slice;
