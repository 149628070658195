import { Address } from 'src/model/model';

export default function addressFormData(
  formData: FormData,
  address: Address
): FormData {
  if (!address) return null;
  formData.set('address[city]', address?.city ?? '');
  formData.set('address[country]', address?.country ?? '');
  formData.set('address[street]', address?.street ?? '');
  formData.set('address[zipCode]', address?.zipCode ?? '');

  return formData;
}
