import { LogicalOperator } from 'src/misc/enums/Paginator/LogicalOperator';
import {
  GetAllParams,
  PaginatorFilterParams
} from 'src/misc/types/Paginator/GetAllParams';
import Paginated from 'src/misc/types/Paginator/Paginated';
import {
  Event,
  ExternalPerson,
  Folder,
  PositioningInterview,
  TrainingCenter,
  UserQuizAnswer
} from 'src/model/model';
import { fetcher } from 'src/utils/fetcher';
import { generateQueryParams } from 'src/utils/generateQueryParams';
import { Option } from 'src/tools/form/types/Option';
import { Target } from 'src/misc/enums/Shared/Target';
import { DateTimeFormat } from 'src/misc/enums/DateTimeFormat';
import dayjs, { Dayjs } from 'dayjs';
import { LevelTest } from 'src/model/Training/Folder/Student/Positioning/PostioningTest';

const PREFIX = '/api/folders';
export default class FolderApiFetcher {
  static async getAll(params: GetAllParams) {
    const queryParams: PaginatorFilterParams = {
      ...params,
      logicalOperator: LogicalOperator.OR,
      searchFields: ['searchValue']
    };
    const response = await fetcher.get<Paginated<Folder[]>>(
      PREFIX + generateQueryParams(queryParams) + `&target=${Target.Student}`
    );
    response.data.data = response.data.data.map(
      (folder: Folder) => new Folder(folder)
    );
    return response;
  }

  static async create(folder: any) {
    const dataToSend = {
      ...folder,
      session:
        typeof folder.session === 'number' ? folder.session : folder.session.id
    };
    const response = await fetcher.post<Folder>(`${PREFIX}`, dataToSend);

    response.data = new Folder(response.data);

    return response;
  }

  static async update(folder: Folder) {
    const dataToSend = {
      ...folder,
      ababandonedAta:
        folder.abandonedAt &&
        dayjs(folder.abandonedAt).format(DateTimeFormat.DefaultDateFormatToAPI),
      canceledAt:
        folder.canceledAt &&
        dayjs(folder.canceledAt).format(DateTimeFormat.DefaultDateFormatToAPI)
    };
    const response = await fetcher.put<Folder>(
      `${PREFIX}/${folder.id}`,
      dataToSend
    );

    response.data = new Folder(response.data);

    return response;
  }

  static async getExternalPersonFolders(externalPerson: ExternalPerson) {
    const response = await fetcher.get<Folder[]>(
      `${PREFIX}/external-person/${externalPerson.id}`
    );

    response.data = response.data.map((folder) => new Folder(folder));

    return response;
  }

  static async getFoldersbyEvent(eventId: number) {
    const response = await fetcher.get<{
      studentFolders: Folder[];
      trainerFolders: Folder[];
    }>(`${PREFIX}/event/${eventId}`);

    response.data.studentFolders = response.data.studentFolders.map(
      (folder) => new Folder(folder)
    );
    response.data.trainerFolders = response.data.trainerFolders.map(
      (folder) => new Folder(folder)
    );

    return response;
  }

  static async getForSignatureSheetPerPeriod(sessionId: number, start: Dayjs, end: Dayjs) {
    const response = await fetcher.get<{
      studentFolders: Folder[];
      trainerFolders: Folder[];
      events: Event[];
    }>(`${PREFIX}/attendance-file/session/${sessionId}`
      + `&start=${start.format(DateTimeFormat.DefaultDateFormatToAPI)}`
      + `&end=${end.format(DateTimeFormat.DefaultDateFormatToAPI)}`
    );

    response.data.studentFolders = response.data.studentFolders.map(
      (folder) => new Folder(folder)
    );

    response.data.trainerFolders = response.data.trainerFolders.map(
      (folder) => new Folder(folder)
    );

    response.data.events = response.data.events.map(
      (event) => new Event(event)
    );

    return response;
  }


  static async delete(id: number) {
    return fetcher.delete<{}>(`${PREFIX}/${id}`);
  }

  static async archive(id: number) {
    return fetcher.put<Folder>(`${PREFIX}/archive/${id}`, {});
  }

  static async makeDecision(folder: Folder, decision: boolean) {
    return fetcher.put<Folder>(`${PREFIX}/${folder.id}/decision`, { decision });
  }

  static async getAllForSelectOptions() {
    const response = await fetcher.get<Option[]>(`${PREFIX}/select/options`);

    return response.data;
  }

  static async setPositioningInterview(
    folder: Folder,
    positioningInterview: PositioningInterview
  ) {
    const dataToSend = {
      ...positioningInterview,
      date: dayjs(positioningInterview.date).format(
        DateTimeFormat.DefaultDateFormatToAPI
      )
    };
    const response = await fetcher.post<Folder>(
      `${PREFIX}/${folder.id}/positioning-interview`,
      dataToSend
    );

    response.data = new Folder(response.data);

    return response;
  }

  static async addPostioningInterviewFile(
    folder: Folder,
    positioningInterview: PositioningInterview
  ) {
    const formData = new FormData();
    formData.append('file', positioningInterview.file as File);

    const response = await fetcher.post<Folder>(
      `${PREFIX}/${folder.id}/positioning-interview/${positioningInterview.id}/file`,
      formData
    );

    response.data = new Folder(response.data);

    return response;
  }

  static async updatePositioningInterview(
    folder: Folder,
    positioningInterview: PositioningInterview
  ) {
    const dataToSend = {
      ...positioningInterview,
      date: dayjs(positioningInterview.date).format(
        DateTimeFormat.DefaultDateFormatToAPI
      )
    };
    const response = await fetcher.put<Folder>(
      `${PREFIX}/${folder.id}/positioning-interview/${positioningInterview.id}`,
      dataToSend
    );

    response.data = new Folder(response.data);

    return response;
  }

  static async getOneById(id: number) {
    const response = await fetcher.get<Folder>(`${PREFIX}/${id}`);

    response.data = new Folder(response.data);

    return response;
  }

  static async getOneByIdForDetails(id: number) {
    const response = await fetcher.get<Folder>(`${PREFIX}/${id}/details`);

    response.data = new Folder(response.data);

    return response;
  }

  static async abandon(folder: Folder) {
    const dataToSend = {
      ...folder,
      abandnoedAt: dayjs(folder.abandonedAt).format(
        DateTimeFormat.DefaultDateFormatToAPI
      )
    };
    const response = await fetcher.post<Folder>(
      `${PREFIX}/${folder.id}/abandon`,
      dataToSend
    );

    response.data = new Folder(response.data);

    return response;
  }

  static async cancel(folder: Folder) {
    const dataToSend = {
      ...folder,
      canceledAt: dayjs(folder.canceledAt).format(
        DateTimeFormat.DefaultDateFormatToAPI
      )
    };
    const response = await fetcher.post<Folder>(
      `${PREFIX}/${folder.id}/cancel`,
      dataToSend
    );

    response.data = new Folder(response.data);

    return response;
  }

  static async setPositioningLevelTest(folder: Folder, levelTest: LevelTest) {
    const dataToSend = {
      ...levelTest,
      sentAt: dayjs().format(DateTimeFormat.DefaultDateFormatToAPI)
    };
    const response = await fetcher.post<Folder>(
      `${PREFIX}/${folder.id}/positioning-level-test`,
      dataToSend
    );

    response.data = new Folder(response.data);

    return response;
  }

  static async setInterviewerQuizAnswer(
    folder: Folder,
    interviewerQuizAnswer: UserQuizAnswer
  ) {
    const response = await fetcher.post<Folder>(
      `${PREFIX}/${folder.id}/positioning-interview_answer`,
      { interviewerQuizAnswer: interviewerQuizAnswer.id }
    );

    response.data = new Folder(response.data);

    return response;
  }
}
