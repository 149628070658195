import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import Guest from 'src/components/Auth/Guest/Guest';
import { Loader } from 'src/components/Loader/Loader';
import { RouteParts } from '../../misc/enums/Router/RouteParts';

const LoginPage = Loader(
  lazy(() => import('src/pages/Auth/Login/LoginPage'))
);

const AccountActivationPage = Loader(
  lazy(() => import('src/pages/Auth/AccountActivation/AccountActivationPage'))
)

const PasswordResettingPage = Loader(
  lazy(() => import('src/pages/Auth/PasswordResetting/PasswordResettingPage'))
)


const authRoutes: RouteObject[] = [
  {
    path: RouteParts.LOGIN,
    element: (
      <Guest>
        <LoginPage />
      </Guest>
    )
  },
  {
    path: RouteParts.ACTIVATE_ACCOUNT,
    element: (
      <Guest>
        <AccountActivationPage />
      </Guest>
    )
  },
  {
    path: RouteParts.RESET_PASSWORD,
    element: (
      <Guest>
        <PasswordResettingPage />
      </Guest>
    )
  },
];

export default authRoutes;
