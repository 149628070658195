import { createContext, useEffect, useReducer } from 'react';
import { ExternalPerson, User } from 'src/model/model';
import UsersApiFetcher from 'src/services/Users/Api/UsersApiFetcher';
import AutheticationApiFetcher from 'src/services/Authentication/Api/AuthenticationApiFercher';
import ApiResponse from 'src/misc/types/ApiResponse';
import {
  ImpersonationResponse,
  LoginResponse
} from 'src/misc/types/AuthCredentials';
import UserFactory from 'src/misc/factories/UserFactory';
import { LocalstorageKeys } from 'src/misc/enums/LocalstorageKeys';
import {
  Action,
  AuthContextValue,
  AuthProviderProps,
  AuthState,
  ExitImpersonationAction,
  ImpersonateAction,
  InitializeAction,
  LoginAction,
  UpdateUserAction
} from 'src/misc/types/JWTAuthContextActions';
import { JwtActionType } from 'src/misc/enums/JwtActionType';
import useToast from 'src/hooks/useToast';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RouteParts } from 'src/misc/enums/Router/RouteParts';
import wait from 'src/utils/wait';
import { devMode } from 'src/api-path';
import { responseIsOk } from 'src/utils/fetcher';
import { useHttp } from 'src/hooks/useHttp';
import TrainingCenterApiFetcher from 'src/services/TrainingCenter/Api/TrainingCenterApiFetcher';
import { useDispatch } from 'src/store';
import { dispatchTrainingCenters } from 'src/slices/trainingCenters';
import { dispatchFolders } from 'src/slices/externalPersonFolders';
import SettingsApiFetcher from 'src/services/Settigns/Api/SettingsApiFetcher';
import { dispatchSettings } from 'src/slices/settings';
import FolderApiFetcher from 'src/services/Folder/Api/FolderApiFecther';

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isImpersonated: false,
  user: null,
  impersonator: null
};

const setSession = (
  token: string | null,
  mercuryToken: string | null,
  impersonatorToken: string | null = null
): void => {
  if (token) {
    localStorage.setItem(LocalstorageKeys.TOKEN, token);
  } else {
    localStorage.removeItem(LocalstorageKeys.TOKEN);
  }

  if (mercuryToken) {
    localStorage.setItem(LocalstorageKeys.MERCURY_TOKEN, mercuryToken);
  } else {
    localStorage.removeItem(LocalstorageKeys.MERCURY_TOKEN);
  }

  if (impersonatorToken) {
    localStorage.setItem(
      LocalstorageKeys.IMPERSONATOR_TOKEN,
      impersonatorToken
    );
  } else {
    localStorage.removeItem(LocalstorageKeys.IMPERSONATOR_TOKEN);
  }
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const {
      isAuthenticated,
      user,
      impersonator,
      isImpersonated,
      isInitialized
    } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized,
      user,
      isImpersonated,
      impersonator
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    isInitialized: true,
    isAuthenticated: false,
    user: null,
    impersonator: null,
    isImpersonated: false
  }),
  IMPERSONATE: (state: AuthState, action: ImpersonateAction): AuthState => {
    const { user, impersonator, isImpersonated } = action.payload;

    return {
      ...state,
      isInitialized: true,
      isImpersonated,
      user,
      impersonator
    };
  },
  EXIT_IMPERSONATION: (
    state: AuthState,
    action: ExitImpersonationAction
  ): AuthState => {
    const { isInitialized, user } = action.payload;

    return {
      ...state,
      isInitialized,
      user,
      impersonator: null,
      isImpersonated: false
    };
  },
  UPDATE_USER: (state: AuthState, action: UpdateUserAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      user: UserFactory.create(user, true)
    };
  }
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(new ApiResponse<LoginResponse>({})),
  logout: (withGreeting: boolean = true) => Promise.resolve(),
  impersonate: () =>
    Promise.resolve(new ApiResponse<ImpersonationResponse>({})),
  exitImpersonation: () => Promise.resolve(),
  dispatchUser: (user: User) => Promise.resolve()
});

export function AuthProvider(props: AuthProviderProps) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { sendRequest } = useHttp();
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const token = window.localStorage.getItem(LocalstorageKeys.TOKEN);
        const impersonatorToken = window.localStorage.getItem(
          LocalstorageKeys.IMPERSONATOR_TOKEN
        );

        if (token) {
          let impersonator: User = null;
          const response = await sendRequest(() =>
            UsersApiFetcher.getConnectedUser()
          );

          if (impersonatorToken) {
            let impersonateResponse = await sendRequest(() =>
              UsersApiFetcher.getConnectedUser(impersonatorToken)
            );
            impersonator = impersonateResponse.data;
          }

          const settingsResponse = await sendRequest(() =>
          SettingsApiFetcher.getSettings()
        );

        if (responseIsOk(settingsResponse)) {
          reduxDispatch(dispatchSettings(settingsResponse.data));
        }

          const trainingCenterResponse =
            await TrainingCenterApiFetcher.getAllNotPaginated();

          if (responseIsOk(trainingCenterResponse)) {
            reduxDispatch(dispatchTrainingCenters(trainingCenterResponse.data));
          }

          if (ExternalPerson.isExternalPerson(response.data)) {
            const externalPersonFoldersResponse =
              await FolderApiFetcher.getExternalPersonFolders(response.data);

            if (responseIsOk(externalPersonFoldersResponse)) {
              reduxDispatch(
                dispatchFolders(externalPersonFoldersResponse.data)
              );
            } else {
              toast.displayApiResponse(externalPersonFoldersResponse);
            }
          }

          dispatch({
            type: JwtActionType.INITIALIZE,
            payload: {
              isInitialized: true,
              isAuthenticated: true,
              isImpersonated: !!impersonatorToken,
              user: response.data,
              impersonator
            }
          });

          toast.greeting(response.data);
        } else {
          dispatch({
            type: JwtActionType.INITIALIZE,
            payload: {
              ...initialAuthState,
              isInitialized: true
            }
          });
        }
      } catch (err) {
        if (devMode) console.error(err);
        dispatch({
          type: JwtActionType.INITIALIZE,
          payload: {
            ...initialAuthState,
            isInitialized: true
          }
        });
      }
    })();
  }, []);

  const login = async (
    email: string,
    password: string
  ): Promise<ApiResponse<LoginResponse>> => {
    const response = await AutheticationApiFetcher.login({ email, password });

    if (responseIsOk(response)) {
      let { token, user, mercuryToken } = response.data;

      setSession(token, mercuryToken);

      if (ExternalPerson.isExternalPerson(user)) {
        const externalPersonFoldersResponse =
          await FolderApiFetcher.getExternalPersonFolders(user);

        if (responseIsOk(externalPersonFoldersResponse)) {
          reduxDispatch(dispatchFolders(externalPersonFoldersResponse.data));
        } else {
          toast.displayApiResponse(externalPersonFoldersResponse);
        }
      }

      dispatch({
        type: JwtActionType.LOGIN,
        payload: {
          user
        }
      });

      const settingsResponse = await sendRequest(() =>
        SettingsApiFetcher.getSettings()
      );

      if (responseIsOk(settingsResponse)) {
        reduxDispatch(dispatchSettings(settingsResponse.data));
      }

      toast.greeting(user);

      const trainingCenterResponse =
        await TrainingCenterApiFetcher.getAllNotPaginated();

      if (responseIsOk(trainingCenterResponse)) {
        reduxDispatch(dispatchTrainingCenters(trainingCenterResponse.data));
      }
    }

    return response;
  };

  const logout = async (withGreeting: boolean = true): Promise<void> => {
    if (state.isImpersonated && state.impersonator) {
      exitImpersonation();
    } else {
      dispatch({
        type: JwtActionType.INITIALIZE,
        payload: {
          ...state,
          isInitialized: false
        }
      });
      setSession(null, null, null);
      await wait(500);
      if (withGreeting) toast.goodBye();
      dispatch({ type: JwtActionType.LOGOUT });
    }
  };

  const dispatchUser = async (user: User): Promise<void> => {
    dispatch({
      type: JwtActionType.UPDATE_USER,
      payload: {
        user
      }
    });
  };

  const impersonate = async (
    userToSwitchToId: number
  ): Promise<ApiResponse<ImpersonationResponse>> => {
    dispatch({
      type: JwtActionType.INITIALIZE,
      payload: {
        isInitialized: false,
        isAuthenticated: true,
        isImpersonated: state.isImpersonated,
        user: state.user,
        impersonator: state.impersonator
      }
    });
    try {
      const response = await sendRequest(() =>
        UsersApiFetcher.impersonate(userToSwitchToId)
      );
      let { token, user, mercuryToken } = response.data;
      const impersonatorToken = localStorage.getItem(LocalstorageKeys.TOKEN);

      setSession(token, mercuryToken, impersonatorToken);

      if (ExternalPerson.isExternalPerson(user)) {
        const externalPersonFoldersResponse =
          await FolderApiFetcher.getExternalPersonFolders(user);

        if (responseIsOk(externalPersonFoldersResponse)) {
          reduxDispatch(dispatchFolders(externalPersonFoldersResponse.data));
        } else {
          toast.displayApiResponse(externalPersonFoldersResponse);
        }
      }

      dispatch({
        type: JwtActionType.IMPERSONATE,
        payload: {
          isImpersonated: true,
          user,
          impersonator: state.user
        }
      });
      toast.greeting(user);
      navigate(RouteParts.ROOT);
      return response;
    } catch (error) {
      if (devMode) console.error(error);
      dispatch({
        type: JwtActionType.INITIALIZE,
        payload: {
          isInitialized: true,
          isAuthenticated: true,
          isImpersonated: state.isImpersonated,
          user: state.user,
          impersonator: state.impersonator
        }
      });
    }
  };

  const exitImpersonation = async () => {
    dispatch({
      type: JwtActionType.INITIALIZE,
      payload: {
        ...state,
        isInitialized: false,
        isAuthenticated: true
      }
    });
    try {
      const impersonatorToken = localStorage.getItem(
        LocalstorageKeys.IMPERSONATOR_TOKEN
      );
      if (!impersonatorToken) {
        setSession(null, null, null);
        toast.error(t(I18nKeys.TEXT_SERVER_ERROR));
        dispatch({ type: JwtActionType.LOGOUT });
      }

      const response = await sendRequest(() =>
        UsersApiFetcher.impersonate(state.impersonator.id, impersonatorToken)
      );
      let { token, user, mercuryToken } = response.data;

      setSession(token, mercuryToken, null);

      dispatch({
        type: JwtActionType.EXIT_IMPERSONATION,
        payload: {
          isInitialized: true,
          isAuthenticated: true,
          isImpersonated: false,
          user,
          impersonator: null
        }
      });
      toast.greeting(user);
      navigate(RouteParts.ROOT);

      return response;
    } catch (error) {
      console.error(error);
      dispatch({
        type: JwtActionType.INITIALIZE,
        payload: {
          isInitialized: true,
          isAuthenticated: false,
          isImpersonated: state.isImpersonated,
          user: state.user,
          impersonator: state.impersonator
        }
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        impersonate,
        exitImpersonation,
        dispatchUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
