import UserFactory from 'src/misc/factories/UserFactory';
import { ImpersonationResponse } from 'src/misc/types/AuthCredentials';
import { User } from 'src/model/model';
import { fetcher, responseIsOk } from 'src/utils/fetcher';

const PREFIX = '/api/users';

export default class UsersApiFetcher {
  static async getConnectedUser(token: string = null) {
    const response = await fetcher.get<User>(
      `${PREFIX}/connected-user`,
      token ? { token } : null
    );
    if (responseIsOk(response)) {
      response.data = UserFactory.create(response.data);
    }
    return response;
  }

  static async impersonate(
    userToSwitchToId: number,
    originalUserToken: string = null
  ) {
    const response = await fetcher.get<ImpersonationResponse>(
      `${PREFIX}/impersonate/${userToSwitchToId}`,
      originalUserToken
        ? {
            token: originalUserToken
          }
        : {}
    );
    response.data = {
      ...response.data,
      user: UserFactory.create(response.data.user)
    };
    return response;
  }

  static async activateAccount(userId: number) {
    return fetcher.post(`${PREFIX}/activate/${userId}`, {});
  }

  static async deactivateAccount(userId: number) {
    return fetcher.post(`${PREFIX}/deactivate/${userId}`, {});
  }

  static async getUserById(userId: number) {
    const response = await fetcher.get<User>(`${PREFIX}/${userId}`);

    if (responseIsOk(response)) {
      response.data = UserFactory.create(response.data);
    }

    return response;
  }

  static async signRgpd(signature: File) {
    const formData = new FormData();

    formData.append('file', signature);

    const response = await fetcher.post<User>(`${PREFIX}/rgpd/sign`, formData);

    response.data = UserFactory.create(response.data);

    return response;
  }
}
