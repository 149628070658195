import { Entity, Service } from 'src/model/model';

export   class Job extends Entity {
  label: string = '';

  service: Service = null;

  constructor(data: Partial<Job> = null, clone: boolean = false) {
    if (!clone && data instanceof Job) return data;
    super(data);

    this.label = data?.label ?? '';
    this.service = data?.service ? new Service(data.service) : null;
  }
}
