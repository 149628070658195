import { InputProps } from '../InputBuilder';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DateTimeFormat } from 'src/misc/enums/DateTimeFormat';
import { useFormContext } from 'react-hook-form';

export default function TimePickerInput({
  controlProps,
  formField
}: InputProps) {
  const { t } = useTranslation();
  const error = controlProps.fieldState.error;
  const methods = useFormContext();

  const triggerChange = (date: Dayjs) => {
    const value = date?.format(DateTimeFormat.DefaultTimeFormatToApi)
    controlProps.field.onChange(value);
    formField.onChange?.(value);
    formField.extras?.trigger?.map(async (observe) => {
      let data = await observe.getValue(value);
      methods.setValue(observe.name, data);
    });
  };

  return (
    <TimePicker
      ampm={false}
      ampmInClock={false}
      label={t(formField.label)}
      value={controlProps.field.value}
      onChange={triggerChange}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            name={formField.name}
            error={!!error}
            helperText={!!error ? error?.message : ''}
            fullWidth
          />
        );
      }}
    />
  );
}
