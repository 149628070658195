import {
  FormControl,
  FormHelperText,
  Switch,
  SwitchProps,
  styled,
  Typography,
  Stack
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputProps } from '../InputBuilder';

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: 'red',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.background.paper
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      backgroundColor: theme.palette.background.paper,
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

export default function SwitchInput(props: InputProps) {
  const error = props.controlProps.fieldState.error;
  const { t } = useTranslation();
  const methods = useFormContext();

  return (
    <FormControl
      error={!!error}
      style={{ display: 'flex', justifyContent: 'start' }}
    >
      <Stack direction="row" gap={2} display="flex" alignItems="center">
        {!props.formField.extras?.labelAfter && (
          <Typography variant="h6">{props.formField.label}</Typography>
        )}
        <StyledSwitch
          name={props.formField.name}
          checked={!!props.controlProps.field.value}
          onChange={(e, checked) => {
            props.controlProps.field.onChange(e);
            props.formField.onChange?.(checked);
            props.formField.extras?.trigger?.map(async (observe) => {
              let data = await observe.getValue(checked);
              methods.setValue(observe.name, data);
            });
          }}
        />
        {props.formField.extras?.labelAfter && (
          <Typography variant="h6">{t(props.formField.label)}</Typography>
        )}
      </Stack>
      <FormHelperText>{!!error ? error.message : ''}</FormHelperText>
    </FormControl>
  );
}
