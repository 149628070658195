import { useSnackbar, SnackbarKey, VariantType } from 'notistack';
import { useTranslation } from 'react-i18next';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { Button, IconButton, useTheme } from '@mui/material';
import { User } from 'src/model/model';
import { ApiErrorMessages } from 'src/misc/enums/Http/ApiErrorMessages';
import ApiResponse from 'src/misc/types/ApiResponse';
import { responseIsOk } from 'src/utils/fetcher';
import { HttpStatus } from 'src/misc/enums/Http/HttpStatus';

type ToastOptions = {
  duration?: number;
  onClick?: () => any;
  buttonText?: string;
};

const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();

  const action = (
    snackbarId: SnackbarKey,
    onClick: () => any = null,
    buttonText: string = I18nKeys.LABEL_VIEW
  ) => (
    <>
      {onClick && (
        <Button
          sx={{ color: theme.palette.background.paper }}
          onClick={() => {
            onClick();
            closeSnackbar(snackbarId);
          }}
        >
          {t(buttonText)}
        </Button>
      )}
      <IconButton
        sx={{ color: theme.palette.background.paper }}
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <CloseTwoToneIcon />
      </IconButton>
    </>
  );

  const addToast = (
    message: string,
    variant: VariantType,
    options: ToastOptions = null
  ) =>
    enqueueSnackbar(t(message), {
      variant,
      ...(options?.duration ? { autoHideDuration: options?.duration } : {}),
      action: (id: SnackbarKey) =>
        action(id, options?.onClick, options?.buttonText)
    });

  const success = (
    message: string = I18nKeys.LABEL_FETCH_SUCCESS,
    options: ToastOptions = null
  ) => addToast(message, 'success', options);

  const error = (
    message: string = I18nKeys.LABEL_FETCH_FAILURE,
    options: ToastOptions = null
  ) => addToast(message, 'error', options);

  const warning = (message: string, options: ToastOptions = null) =>
    addToast(message, 'warning', options);

  const info = (message: string, options: ToastOptions = null) =>
    addToast(message, 'info', options);

  const close = (key: SnackbarKey) => closeSnackbar(key);

  const greeting = (user: User) => {
    success(t(I18nKeys.LABEL_GREETING) + ' ' + user.getFullname(), {
      duration: 2000
    });
  };

  const goodBye = () => {
    success(t(I18nKeys.LABEL_GOODBYE), {
      duration: 2000
    });
  };

  const displayApiResponse = (response: ApiResponse<any>) => {
    if (response?.message === ApiErrorMessages.API_ACCOUNT_DEACTIVATED) return;

    if (responseIsOk(response)) {
      success(
        response.message?.length
          ? response.message
          : I18nKeys.LABEL_FETCH_SUCCESS
      );
    } else {
      if (response?.status == HttpStatus.NotFound) {
        response.message = response?.message?.length
          ? response.message
          : I18nKeys.API_RESOURCE_NOT_FOUND;
      }
      error(
        response?.message?.length
          ? response.message
          : I18nKeys.LABEL_FETCH_FAILURE
      );
    }
  };

  return {
    displayApiResponse,
    greeting,
    goodBye,
    success,
    error,
    warning,
    info,
    close
  };
};

export default useToast;
