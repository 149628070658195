import { Entity, Event, Group, Room } from 'src/model/model';

export class EventRoom extends Entity {
  event: Event;

  groups: Group[];

  room: Room;

  constructor(data: Partial<EventRoom> = null, clone: boolean = false) {
    if (!clone && data instanceof EventRoom) return data;
    super(data);

    this.event = data?.event ? new Event(data?.event) : null;
    this.room = data?.room ? new Room(data.room) : null;
    this.groups = data?.groups?.map((group: Group) => new Group(group)) ?? [];
  }
}
