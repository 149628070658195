import { Entity, Session, Upload, SessionLink } from 'src/model/model';

export   class SessionLinkCategory extends Entity {
    label: string;

    avatar: Upload | File;

    sessionLinks: SessionLink[];

    session: Session;

    constructor(data: Partial<SessionLinkCategory> = null, clone: boolean = false) {
        if (!clone && data instanceof SessionLinkCategory) return data;
        super(data);

        this.label = data?.label ?? '';
        this.avatar = data?.avatar ? new Upload(data?.avatar as Upload) : null;
        this.session = data?.session ? new Session(data?.session) : null;
        this.sessionLinks =
            data?.sessionLinks?.map((sessionLink) => new SessionLink(sessionLink)) ?? [];
    }
}