import { UserBoxMenuItem } from 'src/tools/menu/MenuItem';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import FeedbackTwoToneIcon from '@mui/icons-material/FeedbackTwoTone';
import FeedbackModalContent from 'src/pages/shared/FeedbackModalContent/FeedbackModalContent';

export const myAccountMenuItem: UserBoxMenuItem[] = [
  {
    to: 'my-account',
    label: 'Mon compte',
    icon: AccountBoxTwoToneIcon,
    disabled: true
  }
];
export const feedbackMenuItem: UserBoxMenuItem[] = [
  {
    label: "Retour d'experience",
    icon: FeedbackTwoToneIcon,
    modal: {
      title: "Retour d'expérience",
      body: closeModal => <FeedbackModalContent closeModal={closeModal}/>,
      size: 'md'
    }
  }
];
