import { QuestionType } from 'src/misc/enums/Quiz/QuestionType';
import { Level } from 'src/misc/enums/Shared/Level';
import { Entity, Quiz, Category, QuestionOption } from 'src/model/model';

export class Question extends Entity {
  label: string;

  order: number;

  score: number;

  isArchived: boolean;

  type: QuestionType;

  level: Level;

  quizzes: Quiz[];

  categories: Category[];

  questionOptions: QuestionOption[];

  constructor(data: Partial<Question> = null, clone: boolean = false) {
    if (!clone && data instanceof Question) return data;
    super(data);

    this.label = data?.label ?? '';
    this.order = data?.order ?? 0;
    this.score = data?.score ?? 0;
    this.isArchived = data?.isArchived ?? false;
    this.type = data?.type ?? null;
    this.level = data?.level ?? null;
    this.quizzes = data?.quizzes?.map((quiz: Quiz) => new Quiz(quiz)) ?? [];
    this.questionOptions =
      data?.questionOptions?.map(
        (questionOption: QuestionOption) => new QuestionOption(questionOption)
      ) ?? [];
    this.categories =
      data?.categories?.map((category: Category) => new Category(category)) ??
      [];
  }
}
