import dayjs from "dayjs";
import { Entity } from "../Entity";
import { Intervention, Trainer, TrainerInvoice, Upload } from "../model";


export class TrainerPurchaseOrder extends Entity {

    upload: Upload | File;

    trainer: Trainer | null;

    interventions: Intervention[];

    reference: string = null;

    trainerInvoice: TrainerInvoice | null;

    constructor(data: Partial<TrainerPurchaseOrder> = null, clone: boolean = false) {
        if (!clone && data instanceof TrainerPurchaseOrder) return data;
        super(data);

        this.trainer = data?.trainer ? new Trainer(data.trainer) : null;

        this.trainerInvoice = data?.trainerInvoice ? new TrainerInvoice(data.trainerInvoice) : null;

        this.interventions =
            data?.interventions?.map(
                (intervention: Intervention) => new Intervention(intervention)
            ) ?? [];

        this.upload = data?.upload ? new Upload(data.upload as Upload) : null;

        this.reference = data.reference ?? null;

    }
}