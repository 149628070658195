import { Button, Box, Typography, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useHttp } from 'src/hooks/useHttp';
import useToast from 'src/hooks/useToast';
import UsersApiFetcher from 'src/services/Users/Api/UsersApiFetcher';
import { responseIsOk } from 'src/utils/fetcher';
import Modal from '../Modal/Modal';
import SignatureCanvas from '../SignatureCanvas/SignatureCanvas';

export default function Rgpd() {
  const { user, dispatchUser } = useAuth();
  const [openRgpd, setOpenRgpd] = useState(!user.rgpdSignedAt);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { sendRequest, isLoading } = useHttp();
  const toast = useToast();

  useEffect(() => {
    setOpenRgpd(!user.rgpdSignedAt);
  }, [user]);

  if (user.rgpdSignedAt) {
    return <></>;
  }

  const confirm = async (file: Blob) => {
    const response = await sendRequest(() =>
      UsersApiFetcher.signRgpd(
        new File([file], 'rgpd-' + user.getFullname().replace(' ', '-'))
      )
    );

    toast.displayApiResponse(response);

    if (responseIsOk(response)) {
      dispatchUser(response.data);
      setOpenConfirm(false);
      setOpenRgpd(false);
    }
  };

  return (
    <>
      <Modal
        size="xl"
        open={openRgpd}
        handleClose={() => {}}
        noControls
        sx={{ fontFamily: 'Roboto' }}
        body={
          <Box sx={{ p: 2 }}>
            <Stack gap={2}>
              <Typography variant={'h1'}>
                Politique de confidentialité
              </Typography>
              <Stack gap={2}>
                <Typography variant={'h3'}>Préambule</Typography>
                <Typography variant={'h6'}>
                  La présente politique de confidentialité s'adresse à tout
                  utilisateur de nos services et a pour objectif de vous
                  informer sur la manière dont vos informations personnelles
                  peuvent être collectées et traitées. Le respect de la vie
                  privée et des données à caractère personnel est une priorité,
                  raison pour laquelle nous nous engageons à traiter celles-ci
                  dans le plus strict respect de loi n° 78-17 du 6 janvier 1978
                  relative à l'informatique, aux fichiers et aux libertés
                  modifiée (ci-après « loi IEL ») et du Règlement (UE) 2016/679
                  du Parlement européen et du Conseil du 27 avril 2016 relatif à
                  la protection des personnes physiques à l'égard du traitement
                  des données à caractère personnel et à la libre circulation de
                  ces données (ci-après « RGPD »). En tout état de cause, nous
                  nous engageons à respecter les trois (3) principes essentiels
                  suivants : • Vos données ne feront l'objet d'aucune
                  exploitation commerciale; • Vous restez maître de vos données
                  à caractère personnel ; • Vos données sont traitées de manière
                  transparente, confidentielle et sécurisée;
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>Article 1. Définitions</Typography>
                <Typography variant={'h6'}>
                  Les termes, mentionnés ci-dessous, ont dans la présente
                  Politique de confidentialité, la signification suivante : • «
                  Données Personnelles » ou « Données à caractère personnel » :
                  désigne les données à caractère personnel au sens de la loi
                  IEL et du RGPD et plus généralement toute information se
                  rapportant à une personne physique identifiée ou identifiable;
                  • « Site » : désigne la plateforme en ligne accessible à
                  partir de l'adresse https://www.okalee.fr/ et permettant de
                  bénéficier des Services. La Plateforme regroupe l'ensemble des
                  pages web et fonctionnalités proposées aux Utilisateurs. • «
                  Services » : désigne l'ensemble des services proposés par
                  OKALEE https://www.okalee.fr/ aux Utilisateurs par
                  l'intermédiaire de la Plateforme; • « Utilisateur » : désigne
                  toute personne qui accède et navigue sur le site web.
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>
                  Article 2. Identité du responsable de traitement
                </Typography>
                <Typography variant={'h6'}>
                  Le responsable du traitement est OKALEE https://www.okalee.fr/
                  et sa représentante légale est STOJANOVIC Silvya En vertu de
                  la réglementation en vigueur liée à la protection des données
                  personnelles, OKALEE https://www.okalee.fr/ se doit d'une
                  obligation de protection desdites données.{' '}
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>Rappel légal :</Typography>
                <Typography variant={'h6'}>
                  Le responsable du traitement est, au sens de la loi
                  Informatique et Libertés et du RGPD, la personne qui détermine
                  les moyens et les finalités du traitement. Lorsque deux
                  responsables du traitement ou plus déterminent conjointement
                  les finalités et les moyens du traitement, ils sont les
                  responsables conjoints du traitement (ou co-responsables). Le
                  sous-traitant est une personne traitant des données à
                  caractère personnel pour le compte du responsable du
                  traitement, il agit sous l'autorité du responsable du
                  traitement et sur instruction de celui-ci. Ceci précisé, la
                  présente politique de confidentialité concerne les traitements
                  de données personnelles réalisés par OKALEE
                  https://www.okalee.fr/ en sa qualité de responsable de
                  traitement.
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>
                  Article 3. Finalités et catégories de données personnelles
                  collectées
                </Typography>
                <Typography variant={'h6'}>
                  OKALEE https://www.okalee.fr/ collecte et traite des données
                  personnelles. Veuillez trouver ci-dessous la liste des
                  éléments que nous récupérons classés par catégories ainsi que
                  leurs finalités associées. • Données d'identification: nom,
                  prénom, photo de profil, diplômes, photo photocopie de la
                  carte vitale, photocopie de la carte d'identité • Données vie
                  professionnelle: Expériences professionnelles, niveau de
                  diplômes, avis de situation pôle emploi, extrait de casier
                  judiciaire (pour les formateurs) • Données de localisation:
                  adresse de domiciliation • Données de contact: adresse e-mail,
                  numéro de téléphone fixe ou portable • Données de connexion:
                  données de connexion et de navigation, adresse IP Ce type de
                  données sert à: - Créer un compte sur notre plateforme - Créer
                  de manière automatisée votre CV - Gérer l'organisation de vos
                  soutenances - Mener des campagnes d'information et de
                  communication - Réaliser des enquêtes de satisfaction et des
                  études à des fins statistiques - Vous recontacter lorsque vous
                  souhaitez entrer en contact avec nous par le biais du
                  formulaire dédié à cet effet. - Prévenir les risques de
                  fraudes et s'assurer de l'authenticité de l'utilisateur.
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>Bases légales :</Typography>
                <Typography variant={'h6'}>
                  - Exécution d'un contrat (art 6.b RGPD): Données servant à
                  l'identification du client et à la communication, fourniture
                  et fonctionnement des services, gestion des droits et des
                  demandes d'exercices des droits des personnes. - Consentement
                  (article 6.a RGPD) : l'utilisateur a librement consenti au
                  traitement de ses données à caractère personnel. - Obligation
                  légale (article 6.c RGPD): le traitement est nécessaire au
                  respect d'une obligation légale. - Intérêt légitime (article
                  6.f RGPD) : la collecte de certaines catégories de données
                  personnelles est indispensable et permet entre autres
                  d'assurer le bon fonctionnement de notre site ainsi que les
                  fonctionnalités associées.
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>
                  Article 4. Destinataires de vos données
                </Typography>
                <Typography variant={'h6'}>
                  OKALEE https://www.okalee.fr/ est le principal destinataire de
                  vos données personnelles. Par ailleurs, en raison de son
                  activité, ses partenaires institutionnels et commerciaux (Pôle
                  emploi, Mission locale, Conseils régionaux, ministère du
                  travail, centres de formation, entreprises ) peuvent être
                  aussi destinataires des données. En outre, aucune de vos
                  informations personnelles n'est publiée à votre insu,
                  échangée, transférée, cédée ou vendues sur un support
                  quelconque à des tiers. Dans le cas où la loi l'impose,
                  certains tiers autorisés (juridictions concernées, médiateurs,
                  avocats, huissiers et autres auxiliaires de justice) peuvent
                  avoir accès à vos données personnelles.
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>
                  Article 5. Transfert de données hors Union européenne{' '}
                </Typography>
                <Typography variant={'h6'}>
                  OKALEE https://www.okalee.fr/ s'engage à ne réaliser aucune
                  opération de transfert de vos données à caractère personnel en
                  dehors de l'Union Européenne.
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>
                  Article 6. Durée de conservation des données personnelles
                </Typography>
                <Typography variant={'h6'}>
                  En tout état de cause, la durée de conservation de vos données
                  personnelles n'excède pas un délai de 24 mois (2 ans) à
                  compter du dernier contact ou de la dernière connexion/
                  activité sur notre plateforme. A l'issue de ce délai, et sans
                  aucune activité de votre part, elles feront l'objet d'un
                  effacement.
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>Article 7. Vos droits</Typography>
                <Typography variant={'h6'}>
                  Conformément à la Loi Informatique et Libertés et au RGPD,
                  vous disposez des droits suivants sur le traitement de vos
                  données à caractère personnel: • droit d'accès (article 15
                  RGPD), de rectification (article 16 RGPD), de mise à jour, de
                  complétude de vos données, • droit à l'effacement (ou « droit
                  à l'oubli ») de vos données à caractère personnel (article 17
                  RGPD), lorsqu'elles sont inexactes, incomplètes, équivoques,
                  périmées, ou dont la collecte, l'utilisation, la communication
                  ou la conservation est interdite, • droit de retirer à tout
                  moment votre consentement dès lors qu'un traitement repose sur
                  cette base légale (article 7 RGPD), • droit à la limitation du
                  traitement de vos données (article 18 RGPD), • droit
                  d'opposition au traitement de vos données (article 21 RGPD), •
                  droit à la portabilité des données que vous nous avez
                  fournies, lorsque vos données font l'objet de traitements
                  automatisés fondés sur votre consentement ou sur un contrat
                  (article 20 RGPD), • droit de définir le sort de vos données
                  suite à votre décès et de choisir que nous communiquions (ou
                  non) vos données à un tiers que vous aurez préalablement
                  désigné. En cas de décès et à défaut d'instructions de votre
                  part, nous nous engageons à détruire vos données, sauf si leur
                  conservation s'avère nécessaire à des fins probatoires ou pour
                  répondre à une obligation légale. Vous pouvez exercer vos
                  droits : • par courrier à cette adresse postale: 15 rue des
                  Halles, 75001 Paris • par e-mail à cette adresse:
                  protection@okalee.fr Enfin, vous pouvez également introduire
                  une réclamation auprès des autorités de contrôle et notamment
                  de la CNIL (https://www.cnil.fr/fr/plaintes) ou de toute autre
                  autorité compétente.
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>Article 8. Cookies </Typography>
                <Typography variant={'h6'}>
                  Un cookie est un petit fichier texte émis par le serveur d'un
                  site web et qui est inscrit sur le disque dur de votre
                  ordinateur ou de votre appareil mobile. Le cookie contient un
                  code unique permettant de reconnaître votre navigateur lors de
                  votre visite sur notre site web ou lors de futures visites
                  répétées. Notre plateforme OKALEE https://www.okalee.fr/
                  utilise principalement deux types de cookies : - Cookies
                  strictement nécessaire à la navigation; Ces cookies sont
                  requis pour permettre la visite de notre site web et
                  l'utilisation de certaines parties de celui-ci. Ils permettent
                  de: naviguer entre les différentes rubriques du site,
                  compléter des formulaires, vérifier de manière sûre votre
                  identité avant d'accorder l'accès à vos informations
                  personnelles lorsqu'un compte personnel a été créé. - Cookies
                  fonctionnels; Ces cookies sont destinés à faciliter et
                  analyser le fonctionnement de notre site et à en rendre
                  l'utilisation plus agréable et plus personnalisée. Ils
                  permettent de: personnaliser les services en mémorisant vos
                  préférences, éviter que vous répétiez vos choix lors de chaque
                  visite sur le site (Tracking d'enregistrement de la session
                  (session PHP (PHPSESSID)) pour rester connecté, code
                  JavaScript & PHP pour enregistrer la navigation et les actions
                  utilisateurs, éventuelle utilisation du LocalStore et du
                  Sessionstorage pour stocker les préférences utilisateurs). -
                  Cookies Google Analytics. Ces cookies sont destinés à récolter
                  des informations sur les utilisateurs pour nous permettre par
                  la suite d'avoir une vision approfondie et en temps réel sur
                  la manière dont notre site est utilisé. Ils nous permettent de
                  réaliser des statistiques. Les informations récoltées par les
                  cookies sont anonymes et ne permettent pas votre
                  identification en tant que personne. En effet, les
                  informations liées aux cookies ne peuvent pas être associées à
                  un nom et/ou prénom parce qu'elles ne contiennent pas de
                  données à caractère personnel. Il est possible de vous opposer
                  à l'utilisation des cookies depuis les paramètres de votre
                  navigateur. La configuration de chaque navigateur est
                  différente. Il vous appartient de suivre les instructions de
                  l'éditeur de votre navigateur (liens disponibles par
                  navigateur différent) • Si vous utilisez Internet Explorer :
                  https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies
                  • Si vous utilisez Safari :
                  https://support.apple.com/fr-fr/guide/safari/sfri11471/mac •
                  Si vous utilisez Firefox :
                  https://support.mozilla.org/fr/kb/cookies-informations-sites
                  enregistrent#w_paramaetres-des-cookies • Si vous utilisez
                  Chrome : https://support.google.com/chrome/answer/95647 Nous
                  avertissons qu'en cas de refus de l'enregistrement des
                  cookies, l'expérience de navigation sur notre site peut être
                  limitée. Nous déclinons, de ce fait, toute responsabilité pour
                  les conséquences liées au fonctionnement dégradé du site,
                  résultant du refus de cookies de votre part.{' '}
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>Article 9. Mineurs</Typography>
                <Typography variant={'h6'}>
                  Notre site et ses services ne sont pas destinés aux mineurs.
                  En tout état de cause, OKALEE https://www.okalee.fr/ ne
                  collecte pas volontairement des données à caractère personnel
                  auprès de mineurs âgés de moins de 15 ans. Si vous êtes un
                  parent ou un tuteur et que vous pensez que votre enfant nous a
                  fourni des données à caractère personnel sans votre
                  consentement, veuillez nous contacter par courrier
                  électronique à l'adresse protection@okalee.fr ou par courrier
                  papier à l'adresse suivante: 15 rue des Halles, 75001 Paris en
                  indiquant vos noms, prénoms, adresse courrielle et postale.
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>Article 10. Sécurité</Typography>
                <Typography variant={'h6'}>
                  OKALEE https://www.okalee.fr/ respecte le RGPD et la loi
                  Informatique et Libertés en matière de sécurité et de
                  confidentialité de vos données. Nous mettons en œuvre toutes
                  les mesures techniques et organisationnelles nécessaires afin
                  d'assurer la sécurité de nos traitements de données à
                  caractère personnel et la confidentialité des données que nous
                  collectons (Gestion SSL (https) avec http/2, Protection
                  Anti-DDoS Arbor®, Firewall Web (WAF), Mot de passe hashé en
                  base de données). À ce titre, nous prenons toutes les
                  précautions utiles, au regard de la nature des données et des
                  risques présentés par les traitements pour en préserver la
                  sécurité et, notamment, empêcher que les données soient
                  déformées, endommagées, ou que des tiers non autorisés y aient
                  accès.
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>Article 11. Contact</Typography>
                <Typography variant={'h6'}>
                  Toutes questions, commentaires et demandes concernant cette
                  politique de confidentialité ou toutes autres demandes seront
                  bien reçues. Ils devront être adressés par voie postale ou par
                  e-mail. Adresse : 15 rue des Halles, 75001 Paris Adresse
                  e-mail : protection@okalee.fr
                </Typography>
              </Stack>

              <Stack gap={2}>
                <Typography variant={'h3'}>
                  Article 12. Révision et mise à jour de notre politique de
                  confidentialité
                </Typography>
                <Typography variant={'h6'}>
                  Nous nous engageons à traiter les données à caractère
                  personnel conformément aux dispositions légales en vigueur. La
                  présente politique sera revue en fonction des évolutions des
                  textes. Vous serez régulièrement informé de cette mise à jour.
                  (Mise à jour le: 03/09/2021)
                </Typography>
              </Stack>
            </Stack>
            <Box
              sx={{
                m: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Button
                variant={'contained'}
                onClick={() => setOpenConfirm(true)}
              >
                j'ai lu et j'accepte
              </Button>
            </Box>
          </Box>
        }
        title={'Règlement Général sur la Protection des Données'}
      />

      <Modal
        open={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        body={<SignatureCanvas onDone={confirm} isLoading={isLoading} />}
      />
    </>
  );
}
