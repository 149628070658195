import { useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router/Router';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';
import { CssBaseline } from '@mui/material';
import AppInit from './components/AppInit/AppInit';
import { ErrorBoundary } from 'react-error-boundary';
import InternalServelError from './pages/Status/InternalServerError';
import useSuspenseLoader from './hooks/useSuspenseLoader';
import dayjs from 'dayjs';
import NetworkStatus from './components/NetworkStatus/NetworkStatus';
import NotificationsReceiver from './components/NotificationsReceiver/NotificationsReceiver';
import 'src/i18n/i18n';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { UserRoles } from './misc/enums/Users/UserRoles';
import DevSettings from './components/DevSettings/DevSettings';
import { useTranslation } from 'react-i18next';
import localData from 'dayjs/plugin/localeData'
import Rgpd from './components/Rgpd/Rgpd';

export default function App() {
  const navigate = useNavigate();
  const { isLoaderOn } = useSuspenseLoader();
  const content = useRoutes(router);
  const auth = useAuth();
  const {i18n} = useTranslation();

  const canSeeDevSettigns = () => {
    return (
      (auth.isAuthenticated &&
        auth.user &&
        auth.user.hasRoles([UserRoles.ROLE_ADMIN], [UserRoles.ROLE_DEV])) ||
      (auth.impersonator &&
        auth.impersonator.hasRoles(
          [UserRoles.ROLE_ADMIN],
          [UserRoles.ROLE_DEV]
        ))
    );
  };
  
  dayjs.extend(localData)
  dayjs.locale(i18n.language);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr">
      <CssBaseline />
      <ErrorBoundary
        FallbackComponent={InternalServelError}
        onReset={() => navigate('/')}
      >
        <NetworkStatus />
        {/* {auth.isAuthenticated && <NotificationsReceiver />} */}
        {auth.isInitialized ? content : <AppInit />}
        {auth.isAuthenticated && <Rgpd />}
      </ErrorBoundary>
    </LocalizationProvider>
  );
}
