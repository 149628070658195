import {
  Entity,
  Session,
  Course,
  Task,
  Attendance,
  Folder,
  Event,
  TrainerFile,
  TrainerTask
} from 'src/model/model';

export class SessionItem extends Entity {
  isCustom: boolean;

  session: Session;

  course: Course;

  events: Event[];

  attendances: Attendance[];

  customOwner: Folder | null;

  tasks: Task[];

  hoursLeft: number;

  trainerFiles: TrainerFile[] = []

  trainerTasks: TrainerTask[] = []

  constructor(data: Partial<SessionItem> = null, clone: boolean = false) {
    if (!clone && data instanceof SessionItem) return data;
    super(data);

    this.isCustom = data?.isCustom ?? false;
    this.session = data?.session ? new Session(data?.session) : null;
    this.course = data?.course ? new Course(data?.course) : null;
    this.events = data?.events?.map((event: Event) => new Event(event)) ?? [];
    this.trainerTasks = data?.trainerTasks?.map((trainerTask: TrainerTask) => new TrainerTask(trainerTask)) ?? [];
    this.attendances =
      data?.attendances?.map(
        (attendance: Attendance) => new Attendance(attendance)
      ) ?? [];
    this.customOwner = data?.customOwner ? new Folder(data?.customOwner) : null;
    this.tasks = data?.tasks?.map((task: Task) => new Task(task)) ?? [];
    this.hoursLeft = data?.hoursLeft ?? null;
    this.trainerFiles = data?.trainerFiles?.map((trainerFile: TrainerFile) => new TrainerFile(trainerFile)) ?? []
  }
}
