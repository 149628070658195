import dayjs, { Dayjs } from 'dayjs';
import {
  Entity,
  Planning,
  SessionItem,
  ExternalPerson,
  Intervention,
  Group,
  TaskAnswer,
  Task,
  Session,
  StudentFile,
  TrainerFile,
  TrainingCenter,
  Employee,
  PositioningInterview
} from 'src/model/model';
import { LevelTest } from './Student/Positioning/PostioningTest';

export class Folder extends Entity {
  planning: Planning;

  sessionItems: SessionItem[];

  externalPerson: ExternalPerson;

  trainerInterventions: Intervention[];

  taskAnswers: TaskAnswer[];

  state: number;

  target: number;

  tasks: Task[];

  session: Session | null;

  comment: string;

  level: string;

  situation: string;

  employmentAgencyId: string;

  isArchived: boolean;

  studentFiles: StudentFile[] = [];

  trainerFiles: TrainerFile[] = [];

  trainingCenter: TrainingCenter;

  group: Group;

  decisionMaker: Employee;

  decisionTime: Dayjs;

  positioningInterview: PositioningInterview;

  abandonedAt: Dayjs | string;

  abandonReason: string;

  canceledAt: Dayjs | string;

  cancellationReason: string;

  positioningLevelTest: LevelTest;

  constructor(data: Partial<Folder> = null, clone: boolean = false) {
    if (!clone && data instanceof Folder) return data;
    super(data);

    this.planning = data?.planning ? new Planning(data?.planning) : null;
    this.group = data?.group ? new Group(data?.group) : null;
    this.sessionItems =
      data?.sessionItems?.map(
        (sessionItem: SessionItem) => new SessionItem(sessionItem)
      ) ?? [];
    this.externalPerson = data?.externalPerson
      ? new ExternalPerson(data?.externalPerson)
      : null;
    this.trainerInterventions =
      data?.trainerInterventions?.map(
        (intervention: Intervention) => new Intervention(intervention)
      ) ?? [];
    this.taskAnswers =
      data?.taskAnswers?.map(
        (taskAnswer: TaskAnswer) => new TaskAnswer(taskAnswer)
      ) ?? [];
    this.studentFiles =
      data?.studentFiles?.map(
        (studentFile: StudentFile) => new StudentFile(studentFile)
      ) ?? [];
    this.trainerFiles =
      data?.trainerFiles?.map(
        (trainerFile: TrainerFile) => new TrainerFile(trainerFile)
      ) ?? [];
    this.state = data?.state ?? null;
    this.target = data?.target ?? null;
    this.comment = data?.comment ?? '';
    this.session = data?.session ? new Session(data?.session) : null;
    this.tasks = data?.tasks?.map((task: Task) => new Task(task)) ?? [];
    this.level = data?.level ?? null;
    this.situation = data?.situation ?? '';
    this.employmentAgencyId = data?.employmentAgencyId ?? '';
    this.isArchived = data?.isArchived ?? false;
    this.trainingCenter = data?.trainingCenter
      ? new TrainingCenter(data?.trainingCenter)
      : null;
    this.decisionMaker = data?.decisionMaker
      ? new Employee(data?.decisionMaker)
      : null;
    this.decisionTime = data?.decisionTime ? dayjs(data?.decisionTime) : null;
    this.abandonedAt = data?.abandonedAt ? dayjs(data?.abandonedAt) : null;
    this.canceledAt = data?.canceledAt ? dayjs(data?.canceledAt) : null;
    this.positioningInterview = data?.positioningInterview
      ? new PositioningInterview(data?.positioningInterview)
      : null;
    this.positioningLevelTest = data?.positioningLevelTest
      ? new LevelTest(data?.positioningLevelTest)
      : null;
    this.abandonReason = data?.abandonReason ?? '';
    this.cancellationReason = data?.cancellationReason ?? '';
  }
}
