import { Entity } from 'src/model/model';

export   class HandicapPartner extends Entity {
  label: string;

  link: string;

  goals: string;

  constructor(data: Partial<HandicapPartner> = null, clone: boolean = false) {
    if (!clone && data instanceof HandicapPartner) return data;
    super(data);

    this.label = data?.label ?? '';
    this.link = data?.link ?? '';
    this.goals = data?.goals ?? ''
    ;
  }
}
