import dayjs, { Dayjs } from 'dayjs';
import { Entity } from 'src/model/model';

export   class DevelopmentPerspective extends Entity{
  year: Dayjs | string;

  expectedAt: Dayjs | string;

  completedAt: Dayjs | string;

  people: string;

  job: string;

  description: string;

  objectives: string;

  link: string;

  state: number;

  comment: string;

  constructor(data: Partial<DevelopmentPerspective> = null, clone: boolean = false) {
    if (!clone && data instanceof DevelopmentPerspective) return data;
    super(data);

    this.year = data?.year ? dayjs(data.year) : null;
    this.expectedAt = data?.expectedAt ? dayjs(data.expectedAt) : null;
    this.completedAt = data?.completedAt ? dayjs(data.completedAt) : null;
    this.people = data?.people ?? "";
    this.job = data?.job ?? "";
    this.description = data?.description ?? "";
    this.objectives = data?.objectives ?? "";
    this.link = data?.link ?? "";
    this.state = data?.state ?? null;
    this.comment = data?.comment ?? "";
  }
}
