import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import Authenticated from 'src/components/Auth/Authenticated/Authenticated';
import Granted from 'src/components/Auth/Granted/Granted';
import { Loader } from 'src/components/Loader/Loader';
import TopNavigationLayout from 'src/layouts/TopNavigationLayout';
import { RouteParts } from 'src/misc/enums/Router/RouteParts';
import { RoutePrefix } from 'src/misc/enums/Router/RoutePrefix';
import { UserRoles } from 'src/misc/enums/Users/UserRoles';

const Dashbaord = Loader(
  lazy(() => import('src/pages/Trainer/Dashboard/Dashbaord'))
);

const Documents = Loader(
  lazy(() => import('src/pages/Trainer/Documents/Documents'))
);

const Interventions = Loader(
  lazy(() => import('src/pages/Trainer/Interventions/Interventions'))
);

const QuizPage = Loader(
  lazy(() => import('src/pages/shared/QuizPage/QuizPage'))
);

const trainerRoutes: RouteObject[] = [
  {
    path: RoutePrefix.TRAINER,
    element: (
      <Authenticated>
        <Granted roles={[UserRoles.ROLE_TRAINER]}>
          <TopNavigationLayout />
        </Granted>
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={RouteParts.DASHBOARD} replace />
      },
      {
        path: RouteParts.DASHBOARD,
        element: <Dashbaord />
      },
      {
        path: `${RouteParts.TRAINING_INTERVENTIONS}/*`,
        element: <Interventions />
      },
      {
        path: `${RouteParts.FILES}/*`,
        element: <Documents />
      },
      {
        path: RouteParts.Quiz,
        element: <QuizPage />
      },
    ]
  }
];

export default trainerRoutes;
