import { Settings } from 'src/model/model';
import { fetcher } from 'src/utils/fetcher';

const PREFIX = `/api/settings`;

export default class SettingsApiFetcher {
  static async getSettings() {
    const response = await fetcher.get<Settings>(PREFIX);

    response.data = new Settings(response.data);

    return response;
  }

  static async update(settings: Settings) {
    const dataToSend = {
      ...settings,
      eventColors: JSON.stringify(settings.eventColors),
      generatedFilesStyle: JSON.stringify(settings.generatedFilesStyle)
    };
    const response = await fetcher.put<Settings>(
      PREFIX + `/${settings.id}`,
      dataToSend
    );

    response.data = new Settings(response.data);

    return response;
  }
}
