import { Employee, Enterprise, Student, Trainer, User } from 'src/model/model';

export default class UserFactory {
  public static create(object: any, clone: boolean = false) {
    if (!object) return null;
    if (Employee.isEmployee(object)) return new Employee(object, clone);
    if (Trainer.isTrainer(object)) return new Trainer(object, clone);
    if (Enterprise.isEnterprise(object)) return new Enterprise(object, clone);
    if (Student.isStudent(object)) return new Student(object, clone);
    if (User.isUser(object)) return new User(object, clone);
    return null;
  }
}
