import CSS from 'csstype';
import i18n from 'src/i18n/i18n';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { InputType } from '../types/InputType';
import { Rules, RulesMessages } from '../types/Rules';
import { GridBreakpoints } from 'src/misc/types/GridBreakpoints';
import { FormFieldExtras } from '../types/FormFieldExtras';
import { Option } from '../types/Option';
import Form from './Form';
import { ItemToRepeatProps } from '../components/InputBuilder/Inputs/RepeatedInput';
import { SxProps } from '@mui/material';

type FormFieldLike = { [alias in keyof FormField]: FormField[alias] };

export default class FormField {
  public readonly id?: string;

  public name: string;

  public type: InputType;

  private _label?: string | I18nKeys;

  public rules?: Rules | null;

  public errorMessages?: RulesMessages | null;

  public viewWidth?: GridBreakpoints | null;

  public options?: () => Promise<Option[]>;

  public extras?: FormFieldExtras | null;

  public beforeContent?: (beforeContentProps?: any) => JSX.Element;

  public afterContent?: (afterContentProps?: any) => JSX.Element;

  public style?: SxProps;

  public isSubForm?: boolean;

  public subForm?: Form<any>;

  public subFormNamePrefix?: string;

  public onChange?: (value: any) => any;

  public getItemToRepeat?: (props: ItemToRepeatProps) => JSX.Element;

  public value?: any;

  public repeatedItemValue?: any;

  public get label(): string {
    let label = this._label;
    if (this.rules?.required && label.length) {
      label = i18n.t(label) + '*';
    }
    return label;
  }

  public set label(label: string | I18nKeys) {
    this._label = label;
  }

  constructor(field: FormFieldLike) {
    if (field instanceof FormField) {
      return field;
    }

    this.id = field.name;
    this.name = field.name;
    this.label = field.label;
    this.type = field.type;
    this.rules = field.rules;
    this.errorMessages = field.errorMessages;
    this.viewWidth = field.viewWidth ?? { xs: 12 };
    this.options = field.options;
    this.extras = field.extras;
    this.style = field.style;
    this.beforeContent = field.beforeContent;
    this.afterContent = field.afterContent;
    this.isSubForm = field.isSubForm;
    this.subForm = field.subForm;
    this.subFormNamePrefix = field.subFormNamePrefix;
    this.onChange = field.onChange;
    this.getItemToRepeat = field.getItemToRepeat;
    this.repeatedItemValue = field.repeatedItemValue;
    this.value = field.value;
  }

  static isFormField(object: any): object is FormField {
    if (object instanceof FormField) return true;

    return (
      typeof object.name === 'string' &&
      typeof object.label === 'string' &&
      typeof object.type === 'string'
    );
  }

  public handleChange? = (controlChangeFunction: (...event: any[]) => void, value: any) => {
    controlChangeFunction(value);
    this.onChange?.(value);
    this.value = value;
  }
}
