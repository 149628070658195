import { Folder, Event, FolderAction, Entity } from 'src/model/model';

export class TodayFolderActions{
  folder: Folder;
 
  event: Event;

  actions: FolderAction[];

  constructor(data: Partial<TodayFolderActions> = null, clone: boolean = false) {
    if (!clone && data instanceof TodayFolderActions) return data;

    this.folder = data?.folder ? new Folder(data.folder) : null;
    this.event = data?.event ? new Event(data.event) : null;
    this.actions = data?.actions?.map((action: FolderAction) => new FolderAction(action)) ?? [];
  }
}

