import {
  Entity,
  UserQuizAnswer,
  QuestionOption,
  Question
} from 'src/model/model';

export class UserQuestionAnswer extends Entity {
  typedAnswer: string;

  question: Question;

  userQuizAnswer: UserQuizAnswer | null;

  questionOptions: QuestionOption[];

  constructor(
    data: Partial<UserQuestionAnswer> = null,
    clone: boolean = false
  ) {
    if (!clone && data instanceof UserQuestionAnswer) return data;
    super(data);

    this.userQuizAnswer = data?.userQuizAnswer
      ? new UserQuizAnswer(data.userQuizAnswer)
      : null;
    this.question = data?.question ? new Question(data.question) : null;
    this.typedAnswer = data?.typedAnswer ?? '';
    this.questionOptions =
      data?.questionOptions?.map(
        (questionOption) => new QuestionOption(questionOption)
      ) ?? [];
  }
}
