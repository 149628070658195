import { createRef, useState } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

type SignatureCanvasProps = {
  onDone: (blob: Blob) => void;
  isLoading?: boolean;
};

export default function SignatureCanvas(props: SignatureCanvasProps) {
  const canvasRef: any = createRef();
  const [canvasEmpty, setCanvasEmpty] = useState<boolean>(true);

  async function handleValidate() {
    const instance = canvasRef.current;

    const dataURL = instance.getTrimmedCanvas().toDataURL();

    const response = await fetch(dataURL);
    const blob = await response.blob();
    props.onDone(blob);
  }

  return (
    <Stack justifyContent={'center'} alignItems={'center'} gap={2} m={2}>
      <Box>
        <Typography
          mb={2}
          variant={'h5'}
          display="flex"
          justifyContent={'center'}
          alignItems="center"
        >
          Merci de dessiner votre signature dans le cadre.
        </Typography>
        <Paper
          sx={{
            width: '500px',
            height: '200px',
            border: '2px solid black'
          }}
        >
          <ReactSignatureCanvas
            canvasProps={{ width: '500px', height: '200px' }}
            ref={canvasRef}
            onEnd={() =>
              setCanvasEmpty(
                !canvasRef?.current ||
                  (canvasRef?.current && canvasRef.current.isEmpty())
              )
            }
          />
        </Paper>
      </Box>
      <Stack justifyContent={'center'} alignItems={'center'}>
        <LoadingButton
          loading={props.isLoading}
          variant={'contained'}
          disabled={canvasEmpty}
          onClick={() => handleValidate()}
        >
          Valider la signature
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
