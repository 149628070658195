import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { FormElement } from 'src/tools/form/core/Form';
import { InputType } from 'src/tools/form/types/InputType';
import { LoginPageFlags } from '../LoginPage';

const ForgotPasswordButton = (props: { setMode: any }) => {
  const {t} = useTranslation();
  return(
  <Button
    style={{ textDecoration: 'underline' }}
    onClick={() => props.setMode(LoginPageFlags.FORGOT_PASSWORD)}
  >
    {t(I18nKeys.LABEL_FORGOT_PASSWORD)}
  </Button>
)
}
export const loginFormFields: FormElement[] = [
  {
    name: 'email',
    label: I18nKeys.FORM_LABEL_EMAIL,
    type: InputType.Email,
    rules: { required: true, email: true },
  },
  {
    name: 'password',
    label: I18nKeys.FORM_LABEL_PASSWORD,
    type: InputType.Password,
    rules: { required: true },
    style: { marginTop: 0 },
    afterContent: (props) => <ForgotPasswordButton {...props} />
  }
];
