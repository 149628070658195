import { useState } from 'react';
import { Box, Typography, Button, styled, Stack } from '@mui/material';

import { useTranslation } from 'react-i18next';
import Logo from 'src/components/Logo/Logo';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import wait from 'src/utils/wait';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import Title from 'src/components/Title/Title';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

export default function InternalServerError({ error, resetErrorBoundary }) {
  const { t }: { t: any } = useTranslation();
  const [pending, setPending] = useState(false);
  async function handleClick() {
    setPending(true);
    await wait(1000);
    window.location.reload();
  }

  return (
    <>
      <Title title={t(I18nKeys.TITLE_SERVER_ERROR)} />

      <Box display={'flex'} justifyContent={'center'} marginTop={2}>
        <Logo />
      </Box>
      <MainContent>
        <Stack
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="-5rem"
        >
          <Box textAlign="center">
            <img
              alt={t(I18nKeys.TITLE_SERVER_ERROR)}
              height={260}
              src="/images/status/500.svg"
            />
            <Typography
              variant="h2"
              sx={{
                my: 2
              }}
            >
              {t(I18nKeys.TEXT_SERVER_ERROR)}
            </Typography>
          </Box>
          <LoadingButton
            onClick={handleClick}
            loading={pending}
            variant="outlined"
            color="primary"
            startIcon={<RefreshTwoToneIcon />}
          >
            {t(I18nKeys.LABEL_RELOAD)}
          </LoadingButton>
        </Stack>
      </MainContent>
    </>
  );
}
