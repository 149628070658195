import { FolderState } from 'src/misc/enums/Folder/FolderState';
import { Folder } from 'src/model/model';

export default class FolderPickerHelper {
  static getDefaultActiveFolder(folders: Folder[]) {
    const activeStateFolder = folders.find(
      (folder) => folder.state === FolderState.Active
    );

    if (!activeStateFolder) {
      const pendingStateFolder = folders.find(
        (folder) => folder.state === FolderState.Pending
      );
      if (!pendingStateFolder) {
        return folders.length ? folders[0] : null;
      } else {
        return pendingStateFolder;
      }
    } else {
      return activeStateFolder;
    }
  }

  static find(folders: Folder[], folderId: number) {
    return (
      folders.find((folder) => folder.id === folderId) ??
      FolderPickerHelper.getDefaultActiveFolder(folders)
    );
  }
}
