import { InputProps } from '../InputBuilder';
import { useDropzone } from 'react-dropzone';
import {
  Alert,
  Avatar,
  Box,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { CircleRounded } from '@mui/icons-material';
import wait from 'src/utils/wait';
import { useFormContext } from 'react-hook-form';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import FormField from 'src/tools/form/core/FormField';

const BoxUploadWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    background: ${theme.colors.alpha.black[5]};
    border: 1px dashed ${theme.colors.alpha.black[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.white[50]};
      border-color: ${theme.colors.primary.main};
    }
`
);

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${theme.spacing(3)};
    height: ${theme.spacing(3)};
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.success.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarDanger = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.error.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

export default function FileInput(props: InputProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const multiple = props.formField.extras?.multiple ?? false;
  const accept = props.formField.extras?.accept ?? {};
  const error = props.controlProps.fieldState.error;
  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    inputRef
  } = useDropzone({
    multiple,
    accept,
    onDropAccepted: async (files) => {
      props.controlProps.field.onChange(multiple ? files : files[0]);
      props.formField.onChange?.(multiple ? files : files[0]);
    }
  });

  const files = acceptedFiles.map((file, index) => (
    <ListItem component="li" key={index} color="primary">
      <CircleRounded style={{ fontSize: '8px', marginRight: '1rem' }} />
      <ListItemText primary={file.name} />
    </ListItem>
  ));

  return (
    <>
      <Typography>{props.formField.label}</Typography>
      <BoxUploadWrapper
        style={
          !!error
            ? {
                borderColor: theme.palette.error.main
              }
            : {}
        }
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragAccept && (
          <>
            <AvatarSuccess variant="rounded">
              <CheckTwoToneIcon />
            </AvatarSuccess>
            <Typography>{t('Glissez ici')}</Typography>
          </>
        )}
        {isDragReject && (
          <>
            <AvatarDanger variant="rounded">
              <CloseTwoToneIcon />
            </AvatarDanger>
            <Typography>{t('Fichiers refusées')}</Typography>
          </>
        )}
        {!isDragActive && (
          <>
            <AvatarWrapper
              variant="rounded"
              style={
                !!error
                  ? {
                      color: theme.palette.error.main
                    }
                  : {}
              }
            >
              <CloudUploadTwoToneIcon />
            </AvatarWrapper>
            <Typography
              sx={{
                mt: 2
              }}
            >
              {t(I18nKeys.TEXT_DROP_FILES_HERE)}
            </Typography>
          </>
        )}
      </BoxUploadWrapper>
      {!!error && (
        <FormHelperText error={!!error}>{error.message}</FormHelperText>
      )}
      {files.length > 0 && (
        <>
          <Box p={2}>
            <Alert sx={{ p: 0 }} severity="success">
              {t('Vous avez téléchargé')} <b>{files.length}</b>{' '}
              {t(multiple ? 'fichiers' : 'fichier')}
            </Alert>
            <List disablePadding component="ul">
              {files}
            </List>
          </Box>
        </>
      )}
    </>
  );
}
