import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { TrainingCenter } from 'src/model/model';
import { Option } from 'src/tools/form/types/Option';
import TrainingCenterHelper from 'src/services/TrainingCenter/Helpers/TrainingCenterHelper';

interface TrainingCetnerState {
  trainingCenters: TrainingCenter[];
  trainingCentersOptions: Option[];
}

const initialState: TrainingCetnerState = {
  trainingCenters: [],
  trainingCentersOptions: []
};

const slice = createSlice({
  name: 'trainingCenter',
  initialState,
  reducers: {
    getTrainingCenters(
      state: TrainingCetnerState,
      action: PayloadAction<{ trainingCenters: TrainingCenter[] }>
    ) {
      const { trainingCenters } = action.payload;
      state.trainingCenters = trainingCenters;
      state.trainingCentersOptions =
        TrainingCenterHelper.transformToOptions(trainingCenters);
    }
  }
});

export const reducer = slice.reducer;

export const dispatchTrainingCenters =
  (trainingCenters: TrainingCenter[]): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.getTrainingCenters({
        trainingCenters: trainingCenters.map(
          (trainingCenter: TrainingCenter) => new TrainingCenter(trainingCenter)
        )
      })
    );
  };

export default slice;
