import { RouteParts } from 'src/misc/enums/Router/RouteParts';
import MenuBlock from 'src/tools/menu/MenuBlock';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import MoveToInboxTwoToneIcon from '@mui/icons-material/MoveToInboxTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';


export const trainerMenuItems: MenuBlock[] = [
  {
    heading: '',
    items: [
      {
        name: 'Tableau de bord',
        icon: DashboardTwoToneIcon,
        link: RouteParts.DASHBOARD,
      },
      {
        name: 'Interventions',
        icon: MoveToInboxTwoToneIcon,
        link: RouteParts.TRAINING_INTERVENTIONS,
      },
      {
        name: 'Documents',
        icon: BackupTableTwoToneIcon,
        link: RouteParts.FILES,
      },
    ]
  }
];