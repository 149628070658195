import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { EducationalPath } from 'src/model/model';

interface EducationalPathState {
  educationalPath: EducationalPath;
}

const initialState: EducationalPathState = {
  educationalPath: null
};

const slice = createSlice({
  name: 'educationalPath',
  initialState,
  reducers: {
    getEducationalPath(
      state: EducationalPathState,
      action: PayloadAction<{ educationalPath: EducationalPath }>
    ) {
      const { educationalPath } = action.payload;
      state.educationalPath = educationalPath;
    }
  }
});

export const reducer = slice.reducer;

export const dispatchEducationalPath =
  (educationalPath: EducationalPath): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.getEducationalPath({
        educationalPath: educationalPath
          ? new EducationalPath(educationalPath)
          : null
      })
    );
  };

export default slice;
