import { Entity, UserQuestionAnswer } from 'src/model/model';

export class QuestionOption extends Entity {
  label: string;

  score: number;

  value: number;

  isCorrect: boolean = false;

  userQuestionAnswers: UserQuestionAnswer[];

  constructor(data: Partial<QuestionOption> = null, clone: boolean = false) {
    if (!clone && data instanceof QuestionOption) return data;
    super(data);

    this.label = data?.label ?? '';
    this.score = data?.score ?? 0;
    this.value = data?.value ?? 0;
    this.isCorrect = data?.isCorrect ?? false;
    this.userQuestionAnswers =
      data?.userQuestionAnswers?.map(
        (userQuestionAnswer: UserQuestionAnswer) =>
          new UserQuestionAnswer(userQuestionAnswer)
      ) ?? [];
  }

  static isEmptyQuestionOption(questionOption: QuestionOption): boolean {
    return !questionOption.label.length && !questionOption.score && !questionOption.isCorrect;
  }
}
