import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { Option } from 'src/tools/form/types/Option';

export enum FolderState {
  Active = 1,
  Pending,
  Refused,
  Alumnus,
  TrainerFolderClosed,
  Abandon,
  Canceled
}

export const folderStateOptions: Option[] = [
  {
    id: FolderState.Active,
    label: I18nKeys.FORM_LABEL_ACTIVE
  },
  {
    id: FolderState.Pending,
    label: I18nKeys.FORM_LABEL_PENDING
  },
  {
    id: FolderState.Refused,
    label: I18nKeys.FORM_LABEL_REFUSED
  },
  {
    id: FolderState.Alumnus,
    label: I18nKeys.FORM_LABEL_ALUMNUS
  },
  {
    id: FolderState.Abandon,
    label: I18nKeys.FORM_LABEL_ABANDON
  },
  {
    id: FolderState.Canceled,
    label: I18nKeys.FORM_LABEL_CANCELED
  }
];

export function getFolderStateLabel(state: number): string {
  return folderStateOptions.find((option) => option.id == state)?.label;
}

export const folderStatesRecord: Record<
  FolderState,
  {
    label: string;
    color:
      | 'default'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning';
  }
> = {
  [FolderState.Active]: {
    label: getFolderStateLabel(FolderState.Active),
    color: 'success'
  },
  [FolderState.Pending]: {
    label: getFolderStateLabel(FolderState.Pending),
    color: 'warning'
  },
  [FolderState.Refused]: {
    label: getFolderStateLabel(FolderState.Refused),
    color: 'error'
  },
  [FolderState.Alumnus]: {
    label: getFolderStateLabel(FolderState.Alumnus),
    color: 'secondary'
  },
  [FolderState.TrainerFolderClosed]: {
    label: getFolderStateLabel(FolderState.TrainerFolderClosed),
    color: 'warning'
  },
  [FolderState.Abandon]: {
    label: getFolderStateLabel(FolderState.Abandon),
    color: 'default'
  },
  [FolderState.Canceled]: {
    label: getFolderStateLabel(FolderState.Canceled),
    color: 'default'
  },
};
