import { InputProps } from '../InputBuilder';

export default function HiddenInput(props: InputProps) {

  const initalValue = props.controlProps.field.value;

  const defaultValue = props.formField?.extras?.defaultValue(initalValue) ??  initalValue;
  
  return (
    <input
      hidden
      defaultValue={defaultValue}
      name={props.formField.name}
    />
  );
}
