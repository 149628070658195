import { Entity, Skill } from 'src/model/model';

export class PerformanceCriteria extends Entity {
  label: string;

  skill: Skill;

  constructor(
    data: Partial<PerformanceCriteria> = null,
    clone: boolean = false
  ) {
    if (!clone && data instanceof PerformanceCriteria) return data;
    super(data);

    this.label = data?.label ?? '';
    this.skill = data?.skill ? new Skill(data.skill) : null;
  }
}
