
import { Entity, Task, TrainerTask, Upload } from 'src/model/model';

export class TaskFile extends Entity {
  trainerTask: TrainerTask;

  task: Task;

  upload: Upload | File;

  constructor(data: Partial<TaskFile> = null, clone: boolean = false) {
    if (!clone && data instanceof TaskFile) return data;
    super(data);

    this.trainerTask = data?.trainerTask
      ? new TrainerTask(data.trainerTask)
      : null;
    this.upload = data?.upload ? new Upload(data.upload) : null;
    this.task = data?.task ? new Task(data.task) : null;
  }
}
