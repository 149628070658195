import dayjs, { Dayjs } from 'dayjs';
import { Entity } from 'src/model/Entity';
import { Upload } from 'src/model/model';
import { Folder } from '../../Folder/Folder';

export class AbsenceReason extends Entity {
  folder: Folder;

  start: Dayjs | string;

  end: Dayjs | string;

  file: Upload | File;

  comment: string;

  isValid: boolean;

  constructor(data: Partial<AbsenceReason> = null, clone: boolean = false) {
    if (!clone && data instanceof AbsenceReason) return data;
    super(data);

    this.folder = data?.folder ? new Folder(data.folder) : null;
    this.start = data?.start ? dayjs(data?.start) : null;
    this.end = data?.end ? dayjs(data?.end) : null;
    this.comment = data?.comment ?? '';
    this.isValid = data?.isValid ?? false;
    this.file = data?.file ? new Upload(data.file) : null;
  }
}
