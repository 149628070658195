import dayjs, { Dayjs } from 'dayjs';
import { Entity } from 'src/model/model';
export   class Watch extends Entity {
  type: number;

  status: number;

  source: string;

  rss: string;

  title: string;

  comment: string;

  exploitation: string;

  startedAt: Dayjs | string;

  exploitationAt: Dayjs | string;

  isArchived: boolean;

  constructor(data: Partial<Watch> = null, clone: boolean = false) {
    if (!clone && data instanceof Watch) return data;
    super(data);

    this.type = data?.type ?? null;
    this.rss = data?.rss ?? '';
    this.status = data?.status ?? null;
    this.source = data?.source ?? '';
    this.title = data?.title ?? '';
    this.comment = data?.comment ?? '';
    this.exploitation = data?.exploitation ?? '';
    this.exploitationAt = data?.exploitationAt
      ? dayjs(data?.exploitationAt)
      : null;
    this.startedAt = data?.startedAt ? dayjs(data?.startedAt) : null;
    this.isArchived = data?.isArchived ?? false;
  }
}
