import { Entity, Module, Upload } from 'src/model/model';

export class ModuleProcedure extends Entity {
  label: string;

  module: Module;

  upload: Upload | File;

  constructor(data: Partial<ModuleProcedure> = null, clone: boolean = false) {
    if (!clone && data instanceof ModuleProcedure) return data;
    super(data);

    this.label = data?.label ?? '';
    this.module = data?.module ? new Module(data?.module) : null;
    this.upload = data?.upload ? new Upload(data?.upload) : null;
  }
}
