import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import Authenticated from 'src/components/Auth/Authenticated/Authenticated';
import Granted from 'src/components/Auth/Granted/Granted';
import { Loader } from 'src/components/Loader/Loader';
import TopNavigationLayout from 'src/layouts/TopNavigationLayout';
import { RouteParts } from 'src/misc/enums/Router/RouteParts';
import { RoutePrefix } from 'src/misc/enums/Router/RoutePrefix';
import { UserRoles } from 'src/misc/enums/Users/UserRoles';

const Dashbaord = Loader(
  lazy(() => import('src/pages/Student/Dashboard/Dashbaord'))
);
const Documents = Loader(
  lazy(() => import('src/pages/Student/Documents/Documents'))
);
const Planning = Loader(
  lazy(() => import('src/pages/Student/Planning/Planning'))
);

const StudentTraining = Loader(
  lazy(() => import('src/pages/Student/Training/StudentTraining'))
);

const ShowSelectedModule = Loader(
  lazy(() => import('src/pages/Student/Training/Content/ShowSelectedModule'))
);

const QuizPage = Loader(
  lazy(() => import('src/pages/shared/QuizPage/QuizPage'))
);

const studentRoutes: RouteObject[] = [
  {
    path: RoutePrefix.STUDENT,
    element: (
      <Authenticated>
        <Granted roles={[UserRoles.ROLE_STUDENT]}>
          <TopNavigationLayout />
        </Granted>
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={RouteParts.DASHBOARD} replace />
      },
      {
        path: RouteParts.DASHBOARD,
        element: <Dashbaord />
      },
      {
        path: RouteParts.TRAINING,
        children: [
          {
            path: '',
            element: <StudentTraining />
          },
          {
            path: ':id',
            element: <ShowSelectedModule />
          }
        ]
      },
      {
        path: RouteParts.PLANNING,
        element: <Planning />
      },
      {
        path: `${RouteParts.FILES}/*`,
        element: <Documents />
      },
      {
        path: RouteParts.Quiz,
        element: <QuizPage />
      },
    ]
  }
];

export default studentRoutes;
