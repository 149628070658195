import dayjs, { Dayjs } from 'dayjs';
import { TariffMode } from 'src/misc/enums/Tariff/TariffMode';
import { Entity, Discount, TVA, Product } from 'src/model/model';

export class Tariff extends Entity {
  label: string;

  discount: Discount;

  tva: TVA;

  price: number;

  fromDate: Dayjs | string;

  toDate: Dayjs | string;

  isActive: boolean;

  product: Product;

  educationalPathTariffMode: TariffMode;

  // for storing purpose not to API
  calculatedPrice: number;

  constructor(data: Partial<Tariff> = null, clone: boolean = false) {
    if (!clone && data instanceof Tariff) return data;
    super(data);

    this.label = data?.label ?? '';
    this.tva = data?.tva ? new TVA(data?.tva) : null;
    this.price = data?.price ?? 0;
    this.discount = data?.discount ? new Discount(data?.discount) : null;
    this.fromDate = data?.fromDate ? dayjs(data?.fromDate) : null;
    this.toDate = data?.toDate ? dayjs(data?.toDate) : null;
    this.isActive = data?.isActive ?? false;
    this.product = data?.product ? new Product(data?.product) : null;
    this.educationalPathTariffMode = data?.educationalPathTariffMode ?? null;
    this.calculatedPrice = data?.calculatedPrice ?? null;
  }
}
