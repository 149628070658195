import { Entity } from 'src/model/model';

export class TVA extends Entity {
  label: string;

  tax: number;

  constructor(data: Partial<TVA> = null, clone: boolean = false) {
    if (!clone && data instanceof TVA) return data;
    super(data);

    this.label = data?.label ?? '';
    this.tax = data?.tax ?? 0;
  }
}
