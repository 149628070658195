import { InputProps } from '../InputBuilder';
import { Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

export type ItemToRepeatProps = {
  data: any;
  index: number;
  onChange: (data: any, index: number) => void;
  onDelete: (index: number) => void;
};

export function RepeatedInput({ formField, controlProps }: InputProps) {
  const emtpyItem = formField.repeatedItemValue;
  const [values, setValues] = useState<any[]>(
    controlProps.field.value?.length ? controlProps.field.value : [emtpyItem]
  );

  const onChange = (data: any, index: number) => {
    let newValues = [...values];
    newValues.splice(index, 1, data);

    setValues(newValues);
    controlProps.field.onChange(newValues);
    formField.onChange?.(newValues);
  };

  const onDelete = (index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);

    setValues(newValues);
    controlProps.field.onChange(newValues);
    formField.onChange?.(newValues);
  };

  return (
    <>
      <Typography variant={'body1'} color={'primary'}>
        {formField.label}
      </Typography>
      <Stack direction={'column'} gap={2}>
        <Stack direction={'column'} gap={2}>
          {values.map((data, index) => {
            const Component = React.cloneElement(
              formField.getItemToRepeat({
                index,
                data,
                onChange,
                onDelete
              }),
              { key: index }
            );
            return Component;
          })}
        </Stack>
        <Button
          onClick={() => {
            const newValues = [...values];
            newValues.push(emtpyItem);
            setValues(newValues);
          }}
        >
          Ajouter
        </Button>
      </Stack>
    </>
  );
}
