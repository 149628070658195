const DEV_PORT = "8000"
const location = window.location.hostname;
const localDomains = ['[::1]', 'localhost', "127.0.0.1", "local.okalee.fr", /* ... */ ];

export const devMode = localDomains.includes(location);

let prodUrl = location;

// if the api is not with the same domain as the client app
// prodUrl = "dev.okaleev4-backend.lmosoft.com"; // dev server
 prodUrl = "dev.okaleev4-backend.lmosoft.com"; // dev server
 // prodUrl = "api.okalee.fr" // prod server

export const API_PATH = `https://${devMode ? `${location}:${DEV_PORT}` : prodUrl}`;

export const MERCURE_PATH = `http://${devMode ? `${location}:4000` : prodUrl}/.well-known/mercure`
