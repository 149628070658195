import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { FormElement } from 'src/tools/form/core/Form';
import { InputType } from 'src/tools/form/types/InputType';

export const forgotPasswordFormFields: FormElement[] = [
  {
    name: 'email',
    label: I18nKeys.FORM_LABEL_EMAIL,
    type: InputType.Email,
    rules: { required: true },
  },
];
