import dayjs, { Dayjs } from 'dayjs';
import { Entity, SessionItem, Event } from 'src/model/model';


export class Attendance extends Entity {

  day: string | Dayjs;

  attendance: any;

  sessionItem: SessionItem | null;

  event: Event | null;

  constructor(data: Partial<Attendance> = null, clone: boolean = false) {
    if (!clone && data instanceof Attendance) return data;
    super(data);

    this.day = data?.day ? dayjs(data?.day) : null;
    this.attendance = data?.attendance ?? {};
    this.sessionItem = data?.sessionItem ? new SessionItem(data.sessionItem) : null;
    this.event = data?.event ? new Event(data.event) : null;
  }
}
