import dayjs, { Dayjs } from 'dayjs';
import { Entity, Folder } from 'src/model/model';


export   class PriceQuote extends Entity {
  day: string | Dayjs;

  folder: Folder | null;

  constructor(data: Partial<PriceQuote> = null, clone: boolean = false) {
    if (!clone && data instanceof PriceQuote) return data;
    super(data);

    this.day = data.day ? dayjs(data.day) : null;
    this.folder = data.folder ? new Folder(data.folder) : null;
  }
}
