import FormField from './FormField';
import {
  object,
  string,
  array,
  number,
  mixed,
  boolean,
  ref,
  AnyObjectSchema
} from 'yup';
import { InputType } from '../types/InputType';

/**
 * class used for forms and data validation
 * @uses yup
 */
export default class Validator {
  /**
   * yup schema builder for yup resolver
   * @param fields
   * @returns {AnyObjectSchema} object with the shape of schema
   */
  static buildSchema(fields: FormField[]): AnyObjectSchema {
    /**
     * first we create and empty schema
     */
    let schema = {};

    /**
     * we create the validation rules for each field then we push
     * it to the schema object with the field name as a key
     */
    fields.forEach((field: FormField) => {
      /**
       * first we create a null rule
       */
      let yupRule = null;

      /**
       * then we create the rule base
       * in other words the type of data
       */
      switch (field.type) {
        case InputType.Text:
        case InputType.Textarea:
        case InputType.Password:
        case InputType.Date:
        case InputType.Time:
        case InputType.Email:
          yupRule = string().nullable();
          break;
        case InputType.Autocomplete:
          if (field.extras?.multiple) {
            yupRule = array().of(mixed());
          } else {
            yupRule = mixed().nullable();
          }
          break;
        case InputType.CheckboxGroup:
          yupRule = array().of(mixed());
          break;
        case InputType.RadioGroup:
          yupRule = mixed()/* .nullable() */;
          break;
        case InputType.Number:
          yupRule = number()
            .nullable()
            .typeError("Merci d'entrer un nombre valide");
          break;
        case InputType.Switch:
          yupRule = boolean().nullable();
          break;
        case InputType.File:
          if (field.extras?.multiple) {
            yupRule = array().of(mixed());
          } else {
            yupRule = mixed().nullable();
          }
          break;
        default:
          yupRule = mixed().nullable();
      }

      if (field.type == InputType.Email) {
        if (!field.rules) {
          field.rules = {};
        }
        field.rules.email = true;
      }

/*       if (field.type === InputType.Time) {
        yupRule = yupRule.matches(
          /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) ([01][0-9]|2[0-3]):([0-5][0-9])$/,
          'Please enter a valid time value'
        );
      } */

      const rules = field.rules;
      const errorMessages = field.errorMessages;

      /**
       * check if there is any rules to add to the schema
       */
      if (rules) {
        /**
         * then we check on rules based on rules object
         *  and for error messsages it either takes the custom
         * error message provided in errorMessages object
         * or takes the default error message
         */
        if (rules.required) {
          if (
            (field.type == InputType.Autocomplete && field.extras?.multiple) ||
            (field.type == InputType.File && field.extras?.multiple) ||
            field.type == InputType.CheckboxGroup
          ) {
            yupRule = yupRule.min(
              1,
              errorMessages?.required ?? 'Ce champ est requis'
            );
          } else {
            if (field.type == InputType.File) {
              yupRule = yupRule.not([null], 'Ce champ est requis');
            } else {
              yupRule = yupRule.required(
                errorMessages?.required ?? 'Ce champ est requis'
              );
            }
          }
        }
        if (rules.email) {
          yupRule = yupRule.email(
            errorMessages?.email ?? 'Veuillez entrer un email valide'
          );
        }
        if (rules.alphanumeric) {
          yupRule = yupRule.matches(
            /^[a-zA-Z0-9]+$/,
            errorMessages?.alphanumeric ??
              'Veuillez entrer une valeur alphanumérique valide'
          );
        }
        if (rules.numeric) {
          yupRule = yupRule
            .trim()
            .matches(
              /^\d+$|^$/,
              errorMessages?.numeric ?? 'Veuillez entrer une valeur numérique valide'
            );
        }
        if (rules.password) {
          yupRule = yupRule.matches(
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
            errorMessages?.password ?? 'Veuillez entrer un mot de passe valide'
          );
        }
        if (typeof rules.min == 'number') {
          yupRule = yupRule.min(
            rules.min,
            errorMessages?.min ??
              `Veuillez saisir au moins ${rules.min} `
          );
        }
        if (typeof rules.max == 'number') {
          yupRule = yupRule.max(
            rules.max,
            errorMessages?.max ??
              `Veuillez saisir au plus ${rules.min}`
          );
        }
        if (rules.pattern instanceof RegExp) {
          yupRule = yupRule.matches(
            rules.pattern,
            errorMessages?.max ??
              `Veuillez entrer une valeur qui correspond à ${rules.pattern}`
          );
        }
        if (Array.isArray(rules.sameAs)) {
          yupRule = yupRule.oneOf(
            [...rules.sameAs.map((field) => ref(field))],
            `ce champ doit correspondre à l'un des champs : (${rules.sameAs.map(
              (field) => `${field} `
            )})`
          );
        }
      }

      /**
       * and finally we push the rule to the schema object
       * with the field name as a key
       */
      schema[field.name] = yupRule;
    });
    return object().shape(schema);
  }
}
