import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';
import ThemeProvider from './theme/ThemeProvider';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { SuspenseLoaderProvider } from './contexts/SuspenseLoaderContext';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SnackbarProvider } from 'notistack';

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <ThemeProvider>
        <SidebarProvider>
          <SuspenseLoaderProvider>
            <SnackbarProvider
              maxSnack={6}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <BrowserRouter>
                <ScrollTop />
                <AuthProvider>
                  <App />
                </AuthProvider>
              </BrowserRouter>
            </SnackbarProvider>
          </SuspenseLoaderProvider>
        </SidebarProvider>
      </ThemeProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
