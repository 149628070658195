import { Avatar, Box, IconButton, styled } from '@mui/material';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import getInitials from 'src/utils/getInitials';
import { useState } from 'react';
import { InputProps } from '../InputBuilder';
import { useWatch } from 'react-hook-form';

const AvatarWrapper = styled(Box)(
  ({ theme }) => `
      position: relative;
  
      .MuiAvatar-root {
        width: ${theme.spacing(16)};
        height: ${theme.spacing(16)};
      }
  `
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
      position: absolute;
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      bottom: -${theme.spacing(2)};
      right: -${theme.spacing(2)};
  
      .MuiIconButton-root {
        border-radius: 100%;
        background: ${theme.colors.primary.main};
        color: ${theme.palette.primary.contrastText};
        box-shadow: ${theme.colors.shadows.primary};
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        padding: 0;
    
        &:hover {
          background: ${theme.colors.primary.dark};
        }
      }
  `
);

export default function AvatarImageInput({
  controlProps,
  control,
  formField
}: InputProps) {
  const [imageToUpload, setImageToUpload] = useState<string | null>('');
  let fieldToWatch: any;

  const firstName = useWatch({
    control,
    name: 'firstname'
  });

  const lastName = useWatch({
    control,
    name: 'lastname'
  });

  if (formField.extras?.watchField) {
    fieldToWatch = useWatch({
      control,
      name: formField.extras?.watchField
    });
  }

  let fullName =
    firstName || lastName ? `${firstName} ${lastName}` : fieldToWatch ?? '';

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <AvatarWrapper>
        <Avatar
          imgProps={{ draggable: false, style: { objectFit: 'contain' } }}
          variant="rounded"
          src={imageToUpload || controlProps.field.value?.path}
          style={{
            fontSize: '60px'
          }}
          {...(fullName?.length ? { children: getInitials(fullName) } : {})}
        />
        <ButtonUploadWrapper>
          <input
            style={{ display: 'none' }}
            accept="image/*"
            id={controlProps.field.name}
            name={controlProps.field.name}
            onChange={(e) => {
              controlProps.field.onChange(e.target.files[0]);
              formField.onChange?.(e.target.files[0]);
              setImageToUpload(URL.createObjectURL(e.target.files[0]));
            }}
            type="file"
          />
          <label htmlFor={controlProps.field.name}>
            <IconButton component="span" color="primary">
              <CloudUploadTwoToneIcon />
            </IconButton>
          </label>
        </ButtonUploadWrapper>
      </AvatarWrapper>
    </Box>
  );
}
