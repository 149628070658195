import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { Folder } from 'src/model/model';

interface FolderState {
  folder: Folder;
}

const initialState: FolderState = {
  folder: null
};

const slice = createSlice({
  name: 'folderDetails',
  initialState,
  reducers: {
    getFolder(state: FolderState, action: PayloadAction<{ folder: Folder }>) {
      state.folder = action.payload.folder;
    }
  }
});

export const reducer = slice.reducer;

export const dispatchFolderDetails =
  (folder: Folder): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.getFolder({ folder: folder ? new Folder(folder) : null })
    );
  };

export default slice;
