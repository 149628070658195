import { ExternalPerson, TrainerFile, TrainerInvoice, Module, Category } from 'src/model/model';

export class Trainer extends ExternalPerson {
  averageDailyRate: number;

  trainerFiles: TrainerFile[];

  trainerInvoices: TrainerInvoice[];

  expertises: Category[];

  constructor(data: Partial<Trainer> = null, clone: boolean = false) {
    if (!clone && data instanceof Trainer) return data;
    super(data);

    this.trainerFiles =
      data?.trainerFiles?.map(
        (trainerFile: TrainerFile) => new TrainerFile(trainerFile)
      ) ?? [];
    this.trainerInvoices =
      data?.trainerInvoices?.map(
        (trainerInvoice: TrainerInvoice) => new TrainerInvoice(trainerInvoice)
      ) ?? [];
    this.averageDailyRate = data?.averageDailyRate ?? null;
    this.expertises =
      data?.expertises?.map((expertise: Category) => new Category(expertise)) ?? [];
  }

  public static isTrainer(object: any): object is Trainer {
    if (object instanceof Trainer) return true;

    return (
      typeof object.averageDailyRate === 'number' &&
      ExternalPerson.isExternalPerson(object)
    );
  }
}
