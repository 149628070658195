import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';
//import en from './translations/en';
import fr from './translations/fr';

const resources = {
  //en: { translation: en },
  fr: { translation: fr }
};

i18n
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    resources,
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'lang'
    },
    keySeparator: false,
    fallbackLng: 'fr',
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
