import { ExternalPerson, StudentFile } from 'src/model/model';

export class Student extends ExternalPerson {
  studentFiles: StudentFile[];

  public constructor(data: Partial<Student> = null, clone: boolean = false) {
    if (!clone && data instanceof Student) return data;
    super(data);

    this.studentFiles =
      data?.studentFiles?.map(
        (studentFile: StudentFile) => new StudentFile(studentFile)
      ) ?? [];
  }

  public static isStudent(object: any): object is Student {
    if (object instanceof Student) return true;
    return (
      Array.isArray(object.studentFiles) &&
      ExternalPerson.isExternalPerson(object)
    );
  }
}
