export class GeneratedFileStyle {
  mb: number = 10;

  ml: number = 10;

  mr: number = 10;

  mt: number = 10;

  constructor(
    data: Partial<GeneratedFileStyle> = null,
    clone: boolean = false
  ) {
    if (!clone && data instanceof GeneratedFileStyle) return data;

    this.mb = data?.mb ?? 10;
    this.ml = data?.ml ?? 10;
    this.mr = data?.mr ?? 10;
    this.mt = data?.mt ?? 10;
  }
}
