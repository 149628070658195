import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InputType } from 'src/tools/form/types/InputType';
import { InputProps } from '../InputBuilder';

export default function TextInput({ formField, controlProps }: InputProps) {
  const { t } = useTranslation();
  const error = controlProps.fieldState.error;
  let type;
  switch (formField.type) {
    case InputType.Number:
      type = 'number';
      break;
    case InputType.Email:
      type = 'email';
      break;
    default:
      type = 'text';
  }
  return (
    <FormControl
      error={!!error}
      fullWidth
      disabled={formField.extras?.disabled}
    >
      <InputLabel htmlFor={formField.name}>{t(formField.label)}</InputLabel>
      <OutlinedInput
        readOnly={formField.extras?.readonly}
        fullWidth
        id={formField.name}
        name={formField.name}
        label={t(formField.label)}
        type={type}
        value={controlProps.field.value ?? ''}
        onChange={(e) => {
          controlProps.field.onChange(e);
          formField.onChange?.(e.target.value);
        }}
        inputProps={{ step: formField.extras?.step ?? 1 }}
        {...(formField.extras?.startAdornment
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  {formField.extras.startAdornment()}
                </InputAdornment>
              )
            }
          : {})}
        {...(formField.extras?.endAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  {formField.extras.endAdornment()}
                </InputAdornment>
              )
            }
          : {})}
      />
      <FormHelperText>{!!error ? error.message : ''}</FormHelperText>
    </FormControl>
  );
}
