import { Option } from 'src/tools/form/types/Option';

export enum FeedbackStatus {
  ToDo = 1,
  Doing,
  Done,
  NotDone
}

export const feedbackStatuses: Option[] = [
  { id: FeedbackStatus.ToDo, label: 'À traiter' },
  { id: FeedbackStatus.Doing, label: 'En cours de résolution' },
  { id: FeedbackStatus.Done, label: 'Traité' },
  { id: FeedbackStatus.NotDone, label: 'Non traité' }
];

export function getFeedbackStatus(feedbackStatusId: number): string {
  return feedbackStatuses.find(
    (feedbackStatus) => feedbackStatus.id === feedbackStatusId
  ).label;
}
