import dayjs, { Dayjs } from 'dayjs';
import { SessionActionType } from 'src/misc/enums/Session/SessionActionType';
import { SessionWorkMode } from 'src/misc/enums/Session/SessionWorkMode';
import {
  Entity,
  Category,
  EducationalPath,
  TrainingCenter,
  SessionLink,
  Folder,
  Planning,
  Group,
  Room,
  SessionItem,
  SessionReferent
} from 'src/model/model';

type SessionRates = {
  satisfactionRate?: number;
  recommendationRate?: number;
  accomplishmentRate?: number;
};
export class Session extends Entity {
  reference: string;

  name: string;

  workMode: SessionWorkMode;

  actionType: SessionActionType;

  beginsAt: Dayjs | string;

  prerequisites: string;

  isConvocationSendingToTrainersActivated: boolean;

  isConvocationSendingToStudentsActivated: boolean;

  isAttendanceActivated: boolean;

  isPositioningInterviewActivated: boolean;

  isMailSendingToTrainersAfterCourseActivated: boolean;

  isMailSendingToStudentsAfterCourseActivated: boolean;

  categories: Category[];

  educationalPath: EducationalPath;

  trainingCenter: TrainingCenter;

  comment: string;

  sessionLinks: SessionLink[];

  folders: Folder[];

  isArchived: boolean;

  planning: Planning;

  groups: Group[];

  defaultVirtualRoom: Room;

  defaultRoom: Room;

  isVirtual: boolean;

  endsAt: Dayjs;

  sessionItems: SessionItem[];

  sessionReferents: SessionReferent[];

  rates: SessionRates;

  constructor(data: Partial<Session> = null, clone: boolean = false) {
    if (!clone && data instanceof Session) return data;
    super(data);

    this.reference = data?.reference ?? '';
    this.name = data?.name ?? '';
    this.workMode = data?.workMode ?? null;
    this.beginsAt = data?.beginsAt ? dayjs(data?.beginsAt) : null;
    this.endsAt = data?.endsAt ? dayjs(data?.endsAt) : null;
    this.isConvocationSendingToStudentsActivated =
      data?.isConvocationSendingToStudentsActivated ?? false;
    this.isConvocationSendingToTrainersActivated =
      data?.isConvocationSendingToTrainersActivated ?? false;
    this.isAttendanceActivated = data?.isAttendanceActivated ?? false;
    this.isPositioningInterviewActivated =
      data?.isPositioningInterviewActivated ?? false;
    this.isMailSendingToStudentsAfterCourseActivated =
      data?.isMailSendingToStudentsAfterCourseActivated ?? false;
    this.isMailSendingToTrainersAfterCourseActivated =
      data?.isMailSendingToTrainersAfterCourseActivated ?? false;
    this.prerequisites = data?.prerequisites ?? '';
    this.comment = data?.comment ?? '';
    this.actionType = data?.actionType ?? null;
    this.folders =
      data?.folders?.map((folder: Folder) => new Folder(folder)) ?? [];
    this.categories =
      data?.categories?.map((category: Category) => new Category(category)) ??
      [];
    this.sessionItems =
      data?.sessionItems?.map(
        (sessionItem: SessionItem) => new SessionItem(sessionItem)
      ) ?? [];
    this.educationalPath = data?.educationalPath
      ? new EducationalPath(data?.educationalPath)
      : null;
    this.trainingCenter = data?.trainingCenter
      ? new TrainingCenter(data?.trainingCenter)
      : null;

    this.sessionLinks =
      data?.sessionLinks?.map((sessionLink) => new SessionLink(sessionLink)) ??
      [];
    this.isArchived = data?.isArchived ?? false;
    this.planning = data?.planning ? new Planning(data.planning) : null;
    this.defaultRoom = data?.defaultRoom ? new Room(data.defaultRoom) : null;
    this.defaultVirtualRoom = data?.defaultVirtualRoom
      ? new Room(data.defaultVirtualRoom)
      : null;
    this.groups = data?.groups?.map((group) => new Group(group)) ?? [];
    this.isVirtual = data?.isVirtual ?? false;
    this.sessionReferents =
      data?.sessionReferents?.map(
        (sessionReferent: SessionReferent) =>
          new SessionReferent(sessionReferent)
      ) ?? [];
    this.rates = data?.rates ?? null;
  }
}
