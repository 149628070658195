import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputProps } from '../InputBuilder';

export default function PasswordInput(props: InputProps) {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const error = props.controlProps.fieldState.error;

  return (
    <FormControl variant="outlined" error={!!error} fullWidth>
      <InputLabel htmlFor={props.formField.name}>
        {t(props.formField.label)}
      </InputLabel>
      <OutlinedInput
        fullWidth
        id={props.formField.name}
        name={props.formField.name}
        label={props.formField.label}
        type={showPassword ? 'text' : 'password'}
        value={props.controlProps.field.value}
        onChange={(e) => {
          props.controlProps.field.onChange(e);
          props.formField.onChange?.(e.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword((prev) => !prev)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText>{!!error ? error.message : ''}</FormHelperText>
    </FormControl>
  );
}
