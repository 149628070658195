import { Entity, TaskAnswer, Upload } from 'src/model/model';


export class TaskAnswerFile extends Entity {
  TaskAnswer: TaskAnswer | null;

  upload: Upload | File;

  constructor(data: Partial<TaskAnswerFile> = null, clone: boolean = false) {
    if (!clone && data instanceof TaskAnswerFile) return data;
    super(data);

    this.TaskAnswer = data?.TaskAnswer ? new TaskAnswer(data.TaskAnswer) : null;
    this.upload = data?.upload ? new Upload(data.upload) : null;
  }
}
