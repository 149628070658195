import { Avatar as MuiAvatar, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import getInitials from 'src/utils/getInitials';

type AvatarProps = {
  sx?: SxProps<Theme>;
  src?: string;
  alt?: string;
  children?: ReactNode;
};

export default function Avatar({ src, alt, sx, children }: AvatarProps) {
  return (
    <MuiAvatar
      sx={sx ?? {}}
      imgProps={{
        draggable: false,
        style: { objectFit: 'contain' }
      }}
      variant="rounded"
      src={src}
      {...(alt ? { children: getInitials(alt) } : {})}
      {...(children ? { children } : {})}
    />
  );
}
