import { Employee, Enterprise, Student, Trainer, User } from 'src/model/model';

export class AuthCredentials {
  email: string;

  password: string;

  constructor(email: string = '', password: string = '') {
    this.email = email;
    this.password = password;
  }
}

export class LoginResponse {
  token: string;

  mercuryToken: string;

  user: Employee | Student | Trainer | Enterprise | User;
}

export class ImpersonationResponse extends LoginResponse {
  impersonator: Employee | Student | Trainer | Enterprise | User;
}
