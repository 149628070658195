import dayjs, { Dayjs } from 'dayjs';
import { EventType } from 'src/misc/enums/Events/EventType';
import { Attendance, Entity, Group, Intervention, SessionItem } from 'src/model/model';
import EventHelper from 'src/tools/calendar/helpers/EventHelper';
import { EventRoom } from './EventRoom';

export class Event extends Entity {
  start: string | Dayjs;

  end: string | Dayjs;

  comment: string;

  type: EventType;

  color: string;

  title: string;

  groups: Group[];

  sessionItem: SessionItem;

  interventions: Intervention[];

  allDay: boolean;

  hours: number;

  editable: boolean;

  attendances: Attendance[];

  eventRooms: EventRoom[];

  constructor(data: Partial<Event> = null, clone: boolean = false) {
    if (!clone && data instanceof Event) return data;
    super(data);

    this.start = data?.start ? dayjs(data?.start) : null;
    this.end = data?.end ? dayjs(data?.end) : null;
    this.comment = data?.comment ?? '';
    this.groups = data?.groups?.map((group) => new Group(group)) ?? [];
    this.type = data?.type ?? EventType.FaceToFace;
    this.sessionItem = data?.sessionItem
      ? new SessionItem(data.sessionItem)
      : null;
    this.interventions =
      data?.interventions?.map(
        (intervention) => new Intervention(intervention)
      ) ?? [];
    this.eventRooms =
      data?.eventRooms?.map(
        (eventRoom: EventRoom) => new EventRoom(eventRoom)
      ) ?? [];
    this.title = data?.title ?? '';
    this.color = EventHelper.getEventColor(this);
    this.allDay = data?.allDay ?? false;
    this.hours = data?.hours ?? 0;
    this.editable = data?.editable ?? false;
    this.attendances =
      data?.attendances?.map(
        (attendance) => new Attendance(attendance)
      ) ?? [];
  }
}
