import { FC, useState, createContext } from 'react';

type SuspenseLoaderContextType = {
  isLoaderOn: boolean
  loaderOn: () => void;
  loaderOff: () => void;
};

export const SuspenseLoaderContext = createContext<SuspenseLoaderContextType>(
  {isLoaderOn: false} as SuspenseLoaderContextType
);

export const SuspenseLoaderProvider: FC = ({ children }) => {
  const [isLoaderOn, setIsLoaderOn] = useState(false);
  let requestCounter = 0;

  const loaderOn = () =>{ 
    requestCounter++;
    setIsLoaderOn(true)
  };
  
  const loaderOff = () =>{ 
    requestCounter--;
    setIsLoaderOn(requestCounter !== 0);
  }

  return (
    <SuspenseLoaderContext.Provider
      value={{ isLoaderOn, loaderOn, loaderOff }}
    >
      {children}
    </SuspenseLoaderContext.Provider>
  );
};
