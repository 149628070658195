import { API_PATH } from 'src/api-path';
import { Entity } from 'src/model/model';

export class Upload extends Entity {
  originalName: string;

  path: string;

  name: string;

  directory: string;

  base64: string;

  public constructor(data: Partial<Upload> = null, clone: boolean = false) {
    if (!clone && data instanceof Upload) return data;
    super(data);
    this.name = data?.name ?? '';
    this.directory = data?.directory ?? '';
    this.originalName = data?.originalName ?? '';
    this.path = data?.path ? `${API_PATH}${data.path}` : '';
    this.base64 = data?.base64 ?? null;
  }
}
