import dayjs, { Dayjs } from 'dayjs';
import { Entity, Folder, Quiz } from 'src/model/model';

export class LevelTest extends Entity {
  quizzes: Quiz[];

  folder: Folder;

  sentAt: Dayjs | string;

  constructor(data: Partial<LevelTest> = null, clone: boolean = false) {
    if (!clone && data instanceof LevelTest) return data;
    super(data);

    this.quizzes = data?.quizzes?.map((quiz) => new Quiz(quiz)) ?? [];
    this.folder = data?.folder ? new Folder(data?.folder) : null;
    this.sentAt = data?.sentAt ? dayjs(data?.sentAt) : null;
  }
}
