import { Descendant } from 'slate';
import { GeneratedFileType } from 'src/misc/enums/File/GeneratedFileType';
import { Entity, StudentFile, TrainingCenter, GeneratedFileImage, TrainerFile } from 'src/model/model';

export class GeneratedFile extends Entity {
  label: string = '';

  type: GeneratedFileType;

  data: Descendant[];

  style: any;

  mainFile: GeneratedFile;

  compositeFiles: GeneratedFile[];

  trainingCenter: TrainingCenter | null;

  studentFiles: StudentFile[] | null = [];

  generatedFileImages: GeneratedFileImage[] = [];

  trainerFiles: TrainerFile[] | null = [];

  constructor(data: Partial<GeneratedFile> = null, clone: boolean = false) {
    if (!clone && data instanceof GeneratedFile) return data;
    super(data);

    this.label = data?.label ?? "";
    this.type = data?.type ?? null;
    this.style = data?.style ?? [];
    this.data = data?.data ?? [];
    this.mainFile = data?.mainFile ? new GeneratedFile(data.mainFile) : null;
    this.compositeFiles =
      data?.compositeFiles?.map(
        (generatedFile: GeneratedFile) => new GeneratedFile(generatedFile)
      ) ?? [];
    this.trainingCenter = data?.trainingCenter
      ? new TrainingCenter(data.trainingCenter)
      : null;
    this.studentFiles = data?.studentFiles?.map(
      (studentFile: StudentFile) => new StudentFile(studentFile)
    ) ?? [];
    this.generatedFileImages =
      data?.generatedFileImages?.map(
        (generatedFileImage: GeneratedFileImage) =>
          new GeneratedFileImage(generatedFileImage)
      ) ?? [];
    this.trainerFiles = data?.trainerFiles?.map(
      (trainerFIle: TrainerFile) => new TrainerFile(trainerFIle)
    ) ?? [];
  }
}
