import { RouteParts } from 'src/misc/enums/Router/RouteParts';
import MenuBlock from 'src/tools/menu/MenuBlock';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';

export const studentMenuItems: MenuBlock[] = [
  {
    heading: '',
    items: [
      {
        name: 'Tableau de bord',
        icon: DashboardTwoToneIcon,
        link: RouteParts.DASHBOARD
      },
      {
        name: 'Mon parcours',
        icon: SchoolTwoToneIcon,
        link: RouteParts.TRAINING
      },
      {
        name: 'Planning',
        icon: CalendarMonthTwoToneIcon,
        link: RouteParts.PLANNING
      },
      {
        name: 'Documents',
        icon: BackupTableTwoToneIcon,
        link: RouteParts.FILES
      }
    ]
  }
];
