import { Entity, PriceQuote, Product } from 'src/model/model';


export   class PriceQuoteDetails extends Entity {
  details: any = {};

  priceQuote: PriceQuote | null;

  product: Product | null;

  constructor(data: Partial<PriceQuoteDetails> = null, clone: boolean = false) {
    if (!clone && data instanceof PriceQuoteDetails) return data;
    super(data);

    this.details = data?.details ?? {};
    this.priceQuote = data?.priceQuote
      ? new PriceQuote(data?.priceQuote)
      : null;
    this.product = data?.product ? new Product(data.product) : null;
  }
}
