import { Entity, Product } from 'src/model/model';

export class ProductSheet extends Entity {
  globalObjective: string;

  pedagogicalObjectives: string;

  beforeTrainingText: string;

  duringTrainingText: string;

  afterTrainingText: string;

  audience: string;

  pedagogicalMethods: string;

  numericResources: string;

  formaCodeReference: string;

  accessibility: string;

  place: string;

  technicalPrerequisites: string;

  materialPrerequisites: string;

  contact: string;

  sheetProgress: number;

  product: Product;

  constructor(data: Partial<ProductSheet> = null, clone: boolean = false) {
    if (!clone && data instanceof ProductSheet) return data;
    super(data);

    this.globalObjective = data?.globalObjective ?? '';
    this.pedagogicalObjectives = data?.pedagogicalObjectives ?? '';
    this.accessibility = data?.accessibility ?? '';
    this.beforeTrainingText = data?.beforeTrainingText ?? '';
    this.duringTrainingText = data?.duringTrainingText ?? '';
    this.afterTrainingText = data?.afterTrainingText ?? '';
    this.audience = data?.audience ?? '';
    this.formaCodeReference = data?.formaCodeReference ?? '';
    this.technicalPrerequisites = data?.technicalPrerequisites ?? '';
    this.materialPrerequisites = data?.materialPrerequisites ?? '';
    this.pedagogicalMethods = data?.pedagogicalMethods ?? '';
    this.numericResources = data?.numericResources ?? '';
    this.place = data?.place ?? '';
    this.contact = data?.contact ?? '';
    this.sheetProgress = data?.sheetProgress ?? 0;
    this.product = data?.product ? new Product(data?.product) : null;
  }
}
