import { RouteObject } from 'react-router';
import NotFound from 'src/pages/Status/NotFound';
import authRoutes from './routes/authRoutes';
import DefaultRoute from './DefaultRoute';
import tcmRoutes from './routes/tcmRoutes';
import trainerRoutes from './routes/trainerRoutes';
import studentRoutes from './routes/studentRoutes';

/**
 * The application's routing table
 * @remark the order of routes is important
 */
const router: RouteObject[] = [
  /**
   * This is the first element in the routing table (the "/")
   * It will redirect the user to the default route based on the user's role
   */
  {
    path: '',
    element: <DefaultRoute />
  },
  
  /**
   * Here you put your routes by spreading the array of routes you make
   */
  ...authRoutes,
  ...tcmRoutes,
  ...trainerRoutes,
  ...studentRoutes,

  /**
   * This is the NOT_FOUND page 
   * It should always be the last element in the routing table
   */
  {
    path: '*',
    element: <NotFound />
  }
];

export default router;
