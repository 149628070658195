import { I18nKeys } from './I18nKeys';

const fr: { [ket in I18nKeys]: string } = {
  // api messages
  [I18nKeys.API_ACCOUNT_DEACTIVATED]: 'Compte désactivé.\nMerci de contacter un administrateur',
  [I18nKeys.API_LOGIN_FAILURE]: "L'adresse e-mail ou le mot de passe que vous avez saisi est incorrect",
  [I18nKeys.API_MAIL_SENT]: 'Un email de réinitialisation a été envoyé.\nMerci de vérifier dans votre boite de reception',
  [I18nKeys.API_INVALID_LINK]: 'Lien non valide',
  [I18nKeys.API_ACCOUNT_NOT_FOUND]: "On n'a pas trouvé un compte avec cet e-mail.\nVeuillez contacter votre administrateur",
  [I18nKeys.API_TWO_ACTIVE_TARIFFS]: 'Collision de periodes des tarifs',
  [I18nKeys.API_UNAUTHORIZED]: 'Non autorisé',
  [I18nKeys.API_RESOURCE_NOT_FOUND]: 'Resource introuvable',
  [I18nKeys.API_INTERNAL_ERROR]: 'Une erreur est survenue',

  // labels and small texts
  [I18nKeys.LABEL_HOME]: 'Accueil',
  [I18nKeys.LABEL_FETCH_SUCCESS]: 'Données mises à jour avec succès',
  [I18nKeys.LABEL_FETCH_FAILURE]: 'Une erreur est survenue',
  [I18nKeys.LABEL_LOGIN]: 'Se connecter',
  [I18nKeys.LABEL_RECOVER]: 'Récupérer mon compte',
  [I18nKeys.LABEL_CHANGE_PASSWORD]: 'Changer le mot de passe',
  [I18nKeys.LABEL_FORGOT_PASSWORD]: 'Mot de passe oublié ?',
  [I18nKeys.LABEL_BACK_TO_LOGIN]: 'Retour à la connexion',
  [I18nKeys.LABEL_SETTINGS]: 'Paramétres',
  [I18nKeys.LABEL_CHANGE_ACCOUNT]: 'Changer le compte',
  [I18nKeys.LABEL_ADMIN_SPACE]: 'Espace super admin',
  [I18nKeys.LABEL_TCM_SPACE]: 'Espace admin centre',
  [I18nKeys.LABEL_TRAINER_SPACE]: 'Espace formateur',
  [I18nKeys.LABEL_STUDENT_SPACE]: 'Espace stagiaire',
  [I18nKeys.LABEL_CHANGE_LANGUAGE]: 'Changer la langue',
  [I18nKeys.LABEL_LANGUAGE_ENGLISH]: 'Anglais',
  [I18nKeys.LABEL_LANGUAGE_FRENCH]: 'Français',
  [I18nKeys.LABEL_BRAND_NAME]: 'Okalee',
  [I18nKeys.LABEL_BRAND_DESCRIPTION]: 'Platforme pédagogique',
  [I18nKeys.LABEL_GO_BACK]: 'Retour',
  [I18nKeys.LABEL_RELOAD]: 'Recharger la page',
  [I18nKeys.LABEL_LOGOUT]: 'Déconnexion',
  [I18nKeys.LABEL_SAVE]: 'Enregistrer',
  [I18nKeys.LABEL_ACTIVATE_ACCOUNT]: 'Activer mon compte',
  [I18nKeys.LABEL_GREETING]: 'Bienvenue',
  [I18nKeys.LABEL_GOODBYE]: 'Au revoir!',
  [I18nKeys.LABEL_ADMIN]: 'Admin',
  [I18nKeys.LABEL_STUDENT]: 'Stagiaire',
  [I18nKeys.LABEL_TCM]: 'Admin centre',
  [I18nKeys.LABEL_TRAINER]: 'Formateur',
  [I18nKeys.LABEL_ENTERPRISE]: 'Entreprise',
  [I18nKeys.LABEL_NOTIFICATIONS]: 'Notifications',
  [I18nKeys.LABEL_TABLE_VIEW]: 'Vue Table',
  [I18nKeys.LABEL_GRID_VIEW]: 'Vue Grille',
  [I18nKeys.LABEL_FILTER]: 'Filtre',
  [I18nKeys.LABEL_ADD]: 'Ajouter',
  [I18nKeys.LABEL_NO_DATA_FOUND]: 'Aucune donnée trouvée',
  [I18nKeys.LABEL_APPLY]: 'Appliquer',
  [I18nKeys.LABEL_PER_PAGE]: 'Lignes/page',
  [I18nKeys.LABEL_NO_OPTION_AVAILABLE]: "Pas d'options disponibles",
  [I18nKeys.LABEL_CLOSE]: 'Fermer',
  [I18nKeys.LABEL_VIEW]: 'Afficher',
  [I18nKeys.LABEL_EDIT]: 'Modifier',
  [I18nKeys.LABEL_DELETE]: 'Supprimer',
  [I18nKeys.LABEL_ARCHIVE]: 'Archiver',
  [I18nKeys.LABEL_UNARCHIVE]: 'Désarchiver',
  [I18nKeys.LABEL_LINK]: 'Lien',
  [I18nKeys.LABEL_MALE]: 'Masculin',
  [I18nKeys.LABEL_FEMALE]: 'Féminin',
  [I18nKeys.LABEL_OTHER]: 'Non précisié/Autre',
  [I18nKeys.LABEL_ACTIONS]: 'Actions',
  [I18nKeys.LABEL_MANAGE]: 'Gérer',
  [I18nKeys.LABEL_ALL]: 'Tous',
  [I18nKeys.LABEL_ROW]: 'Ligne',
  [I18nKeys.LABEL_ROWS]: 'Lignes',
  [I18nKeys.LABEL_OF_TOTAL]: 'de total',
  [I18nKeys.LABEL_INTERNAL_TEAM_STAFF]: 'Personnel',
  [I18nKeys.LABEL_SKILLS]: 'Compétences',
  [I18nKeys.LABEL_SKILLS_BLOCKS]: 'Blocs de compétences',
  [I18nKeys.LABEL_DISCOUNTS]: 'Remises',
  [I18nKeys.LABEL_TVA]: 'TVA',
  [I18nKeys.LABEL_MODULES_BLOCKS]: 'Blocs de modules',
  [I18nKeys.LABEL_INTERNAL_TEAM_JOBS]: 'Postes',
  [I18nKeys.LABEL_INTERNAL_TEAM_SERVICES]: 'Services',
  [I18nKeys.LABEL_HANDICAPS_CONTACTS]: 'Ressources',
  [I18nKeys.LABEL_HANDICAPS_PARTNERS]: 'Partenaires',
  [I18nKeys.LABEL_HANDICAPS_INTERLOCUTORS]: 'Interlocuteurs',
  [I18nKeys.LABEL_MATERIALS_ROOMS]: 'Salles physiques',
  [I18nKeys.LABEL_MATERIALS_VIRTUAL_ROOMS]: 'Salles virtuelles',
  [I18nKeys.LABEL_MATERIALS_EQUIPMENT]: 'Équipement',
  [I18nKeys.LABEL_LOADING]: 'Chargement...',
  [I18nKeys.LABEL_HOLIDAYS]: 'Vacances',
  [I18nKeys.LABEL_WEEKEND]: 'Week-end',
  [I18nKeys.LABEL_EXCEPTIONS]: 'Exceptions',
  [I18nKeys.LABEL_SEND]: 'Envoyer',
  [I18nKeys.LABEL_SENT]: 'Envoyé',
  [I18nKeys.LABEL_UPLOAD]: 'Charger',
  [I18nKeys.LABEL_SWITCH]: 'Basculé',
  [I18nKeys.LABEL_INVOICES]: 'Factures',
  [I18nKeys.LABEL_PAST]: 'Passées',
  [I18nKeys.LABEL_UPCOMING]: 'À venir',
  [I18nKeys.FORM_LABEL_STANDARD]: 'Standard',
  [I18nKeys.FROM_LABEL_JOB_CENTER]: 'Pôle emploi',

  // titles
  [I18nKeys.TITLE_PREFIX_OKALEE]: 'Okalee',
  [I18nKeys.TITLE_ACCESS_DENIED]: 'Accès refusé',
  [I18nKeys.TITLE_NOT_FOUND]: 'Page introuvable',
  [I18nKeys.TITLE_SERVER_ERROR]: 'Internal server error',
  [I18nKeys.TITLE_LOGIN]: 'Connexion',
  [I18nKeys.TITLE_DASHBOARD]: 'Tableau de bord',
  [I18nKeys.TITLE_TRAINING_CENTERS]: 'Centres de formation',
  [I18nKeys.TITLE_COMPANIES]: 'Sociétés',
  [I18nKeys.TITLE_TCM]: 'Gestionnaires de centre',
  [I18nKeys.TITLE_STUDENTS]: 'Stagiaires',
  [I18nKeys.TITLE_TRAINERS]: 'Formateurs',
  [I18nKeys.TITLE_ENTERPRISES]: 'Entreprises',
  [I18nKeys.TITLE_MODULES]: 'Modules',
  [I18nKeys.TITLE_ED_PATHS]: 'Parcours',
  [I18nKeys.TITLE_SESSIONS]: 'Sessions',
  [I18nKeys.TITLE_INTERNAL_TEAM]: 'Equipe internal',
  [I18nKeys.TITLE_EXTERNAL_TEAM]: 'Equipre externe',
  [I18nKeys.TITLE_SETTINGS]: 'Paramètres généraux',
  [I18nKeys.TITLE_INTERNS]: 'Stagiaires',
  [I18nKeys.TITLE_UNDER_CONSTRUCTION]: 'Under Construction',
  [I18nKeys.TITLE_PERFORMANCE_CRITERIA]: 'Critère de Performances',
  [I18nKeys.TITLE_SKILLS]: 'Compétences',

  // form labels
  [I18nKeys.FORM_LABEL_EMAIL]: 'E-mail',
  [I18nKeys.FORM_LABEL_LABEL]: 'Libellé',
  [I18nKeys.FORM_LABEL_PASSWORD]: 'Mot de passe',
  [I18nKeys.FORM_LABEL_CONFIRM_PASSWORD]: 'Confirmer le mot de passe',
  [I18nKeys.FORM_LABEL_AGREE_TERMS]: 'En cochant cette case, vous reconnaissez avoir lu et vous acceptez',
  [I18nKeys.FORM_TERMS_CONDITIONS]: "La Poltique de Protection des Données Personelles d'Okalee",
  [I18nKeys.FORM_LABEL_NAME]: 'Nom',
  [I18nKeys.FORM_LABEL_COMPANY]: 'Société',
  [I18nKeys.FORM_LABEL_MANAGER]: 'Gérant',
  [I18nKeys.FORM_LABEL_SIRET]: 'Siret',
  [I18nKeys.FORM_LABEL_CAPITAL]: 'Capital',
  [I18nKeys.FORM_LABEL_WEBSITE]: 'Site web',
  [I18nKeys.FORM_LABEL_PHONE]: 'N° téléphone',
  [I18nKeys.FORM_LABEL_ADDRESS]: 'Adresse',
  [I18nKeys.FORM_LABEL_FIRSTNAME]: 'Prénom',
  [I18nKeys.FORM_LABEL_LASTNAME]: 'Nom',
  [I18nKeys.FORM_LABEL_BIRTH_DATE]: 'Date de naissance',
  [I18nKeys.FORM_LABEL_GENDER]: 'Genre',
  [I18nKeys.FORM_LABEL_TRAINING_CENTERS]: 'Centres de formation',
  [I18nKeys.FORM_LABEL_COUNTRY]: 'Pays',
  [I18nKeys.FORM_LABEL_CITY]: 'Ville',
  [I18nKeys.FORM_LABEL_STREET]: 'Rue',
  [I18nKeys.FORM_LABEL_ZIPCODE]: 'Code postal',
  [I18nKeys.FORM_LABEL_SEARCH]: 'Recherche',
  [I18nKeys.FORM_LABEL_REFERENCE]: 'Réf.',
  [I18nKeys.FORM_LABEL_LEVEL]: 'Niveau',
  [I18nKeys.FORM_LABEL_DURATION]: "Heures",
  [I18nKeys.FORM_LABEL_ATTENDANCE_SEQUANCE_CODE]: 'Code séquence',
  [I18nKeys.FORM_LABEL_ADVANCED]: 'Avancé',
  [I18nKeys.FORM_LABEL_INITIATION]: 'Initiation',
  [I18nKeys.FORM_LABEL_INTERMEDIATE]: 'Intermédiaire',
  [I18nKeys.FORM_LABEL_FACE_TO_FACE]: 'Présentiel',
  [I18nKeys.FORM_LABEL_ELEARNING]: 'Elearning',
  [I18nKeys.FORM_LABEL_BLENDED]: 'Téléprésentiel',
  [I18nKeys.FORM_LABEL_TRAINING_ACTION]: 'Action de formation',
  [I18nKeys.FORM_LABEL_SKILLS_ASSESSMENT]: 'Bilan de compétences',
  [I18nKeys.FORM_LABEL_VAE_ACTION]: 'Action de VAE',
  [I18nKeys.FORM_LABEL_APPRENTICESHIP_TRAINING]: 'Action de formation par apprentissage',
  [I18nKeys.FORM_LABEL_PRICE]: 'Prix',
  [I18nKeys.FORM_LABEL_DISCOUNT]: 'Remise',
  [I18nKeys.FORM_LABEL_MULTIPLE_CHOICE]: 'Choix multiples',
  [I18nKeys.FORM_LABEL_FREE_ANSWER]: 'Réponse libre',
  [I18nKeys.FORM_LABEL_SATISFACTION]: 'Satisfaction',
  [I18nKeys.FORM_LABEL_STUDENT]: 'Apprenant',
  [I18nKeys.FORM_LABEL_EMPLOYEE]: 'Employé',
  [I18nKeys.FORM_LABEL_ENTERPRISE]: 'Entreprise',
  [I18nKeys.FORM_LABEL_TRAINER]: 'Formateur',
  [I18nKeys.FORM_LABEL_BEFORE]: 'Avant',
  [I18nKeys.FORM_LABEL_AFTER]: 'Après',
  [I18nKeys.FORM_LABEL_TECHNICAL]: 'Technique',
  [I18nKeys.FORM_LABEL_INTERVIEW]: 'Entretien',
  [I18nKeys.FORM_LABEL_SESSION]: 'Session',
  [I18nKeys.FORM_LABEL_TRAINING_CENTER]: 'Centre de formation',
  [I18nKeys.FORM_LABEL_RECOMMENDATION]: 'Recommandation',
  [I18nKeys.FORM_LABEL_ACTIVE]: 'Active',
  [I18nKeys.FORM_LABEL_PENDING]: 'Candidat',
  [I18nKeys.FORM_LABEL_REFUSED]: 'Refusé',
  [I18nKeys.FORM_LABEL_ALUMNUS]: 'Alumni',
  [I18nKeys.FORM_LABEL_ABANDON]: 'Abandon',
  [I18nKeys.FORM_LABEL_CANCELED]: 'Annulé',
  [I18nKeys.FORM_LABEL_ADMINISTRATIVE]: 'Administratif',
  [I18nKeys.FORM_LABEL_PEDAGOGIC]: 'Pédagogique',
  [I18nKeys.FORM_LABEL_POSITIONING]: 'Positionnement',
  [I18nKeys.FORM_LABEL_TRACK]: 'Suivi',
  [I18nKeys.FORM_LABEL_HANDICAP]: 'Handicap',
  [I18nKeys.FORM_LABEL_EXCHANGE]: 'Echange',
  [I18nKeys.FORM_LABEL_FORMATION]: 'Formation',
  [I18nKeys.FORM_LABEL_CERTIFICATION]: 'Certification',
  [I18nKeys.FORM_LABEL_LEFT]: 'A gauche',
  [I18nKeys.FORM_LABEL_CENTER]: 'Au centre',
  [I18nKeys.FORM_LABEL_RIGHT]: 'A droite',
  [I18nKeys.FORM_LABEL_ACCEPTED]: 'Accepté',
  [I18nKeys.FORM_LABEL_PAID]: 'Payée',
  [I18nKeys.FORM_LABEL_WAITING]: 'En attente',

  // texts
  [I18nKeys.TEXT_RECOVER_HELPER_TEXT]: "Veuillez entrer votre e-mail.\nUn mail de recuperation sera envoyé s'il existe un compte avec cet e-mail.",
  [I18nKeys.TEXT_ACCESS_DENIED]: "Il semble que vous n'ayez pas le droit d'avoir accès à cette page.",
  [I18nKeys.TEXT_NOT_FOUND]: "Êtes-vous perdu?\nLa page que vous recherchez n'existe pas",
  [I18nKeys.TEXT_SERVER_ERROR]: 'Une erreur est survenue. Veuillez réessayer',
  [I18nKeys.TEXT_ACTIVATE_ACCOUNT]: 'Choisissez un mot de passe afin de finaliser la création de votre compte.',
  [I18nKeys.TEXT_POST_ACTIVATION]: 'Votre compte est activé.\nMerci de vous connecter',
  [I18nKeys.TEXT_POST_RESET_PASSWORD]: 'Votre mot de passe est changé.\nMerci de vous connecter.',
  [I18nKeys.TEXT_CONFIRM_DELETE]: 'Êtes vous sûr.es de vouloir supprimer ',
  [I18nKeys.TEXT_CONFIRM_ARCHIVE]: 'Êtes vous sûr.es de vouloir archiver ',
  [I18nKeys.TEXT_CONFIRM_UNARCHIVE]: 'Êtes vous sûr.es de vouloir désarchiver ',
  [I18nKeys.TEXT_THIS_USER]: 'cet utilisateur',
  [I18nKeys.TEXT_UNDER_CONSTRUCTION]: 'En construction.\nDésolé pour le dérangement. Nous effectuons une maintenance en ce moment.',
  [I18nKeys.TEXT_DROP_FILES_HERE]: 'Ajouter un fichier en le glissant ou en cliquant',

  [I18nKeys.MENU_HEADER_GENERAL]: 'Général',
  [I18nKeys.MENU_HEADER_USERS_MANAGEMENT]: 'Gestion des utilisateurs',
  [I18nKeys.MENU_HEADER_CENTERS_MANAGEMENT]: 'Societés & Centres',
  [I18nKeys.MENU_HEADER_TEAMS]: 'Équipes',
  [I18nKeys.MENU_HEADER_QUALITY]: 'Qualité',
  [I18nKeys.MENU_HEADER_TRAINING]: 'Formations',
  [I18nKeys.MENU_HEADER_SETTINGS]: 'Paramétrage',

  [I18nKeys.MENU_ITEM_TRAINERS]: 'Formateurs',
  [I18nKeys.MENU_ITEM_STUDENTS]: 'Stagiaires',
  [I18nKeys.MENU_ITEM_TCM]: 'Gestionnaires de centre',
  [I18nKeys.MENU_ITEM_ENTERPRISE]: 'Entreprises',
  [I18nKeys.MENU_ITEM_DASHBOARD]: 'Tableau de bord',
  [I18nKeys.MENU_ITEM_COMPANIES]: 'Societés',
  [I18nKeys.MENU_ITEM_TRAINING_CENTERS]: 'Centres de formation',
  [I18nKeys.MENU_ITEM_MODULES]: 'Modules',
  [I18nKeys.MENU_ITEM_SESSIONS]: 'Sessions',
  [I18nKeys.MENU_ITEM_LEARNERS]: 'Apprenants',
  [I18nKeys.MENU_ITEM_EXTERNAL_TEAM]: 'Equipe externe',
  [I18nKeys.MENU_ITEM_INTERNAL_TEAM]: 'Equipe interne',
  [I18nKeys.MENU_ITEM_JOB_SHEET]: 'Fiches de poste',
  [I18nKeys.MENU_ITEM_FEEDBACK]: 'Observations',
  [I18nKeys.MENU_ITEM_DEV_PROSPECTS]: 'Perspectives de développement',
  [I18nKeys.MENU_ITEM_WATCH]: 'Veilles',
  [I18nKeys.MENU_ITEM_HANDICAP]: 'Handicap',
  [I18nKeys.MENU_ITEM_MATERIALS]: 'Salles et Matériels',
  [I18nKeys.MENU_ITEM_ED_PATHS]: 'Parcours',
  [I18nKeys.MENU_ITEM_GENERAL_SETTINGS]: 'Paramètres généraux',
  [I18nKeys.MENU_ITEM_PRODUCTS]: 'Formations',
  [I18nKeys.MENU_ITEM_MODULE_BLOCK]: 'Blocs de modules',
  [I18nKeys.MENU_ITEM_DISCOUNTS]: 'Remises',
  [I18nKeys.MENU_ITEM_TVA]: 'Tva',
  [I18nKeys.MENU_ITEM_MODULES_SKILLS]: 'Compétences',
  [I18nKeys.MENU_ITEM_MODULES_SKILL_BLOCKS]: 'Blocs de compétences',
  [I18nKeys.MENU_ITEM_SIMPLE_PRODUCTS]: 'Catalogue',
  [I18nKeys.MENU_ITEM_COMPOSED_PRODUCTS]: 'Articles composés',
  [I18nKeys.MENU_ITEM_QUIZZES]: 'Questionnaires',
  [I18nKeys.MENU_ITEM_DOCUMENTS]: 'Documents',
  [I18nKeys.MENU_ITEM_TRAINING_CENTER]: 'Centre de formation',
  [I18nKeys.MENU_ITEM_SESSION_FILES_TYPES]: 'Types de documents session',
  [I18nKeys.MENU_ITEM_PLANNING_AND_HOLIDAYS]: 'Planning et jours fériés',
  [I18nKeys.MENU_ITEM_QUESTIONS_BANK]: 'Banque de questions',
  [I18nKeys.MENU_ITEM_SATISFACTION]: 'Satisfaction',
  [I18nKeys.MENU_ITEM_INTERVIEW]: 'Entretien',
  [I18nKeys.MENU_ITEM_GENERAL_INFORMATION]: 'Informations générales',
  [I18nKeys.MENU_ITEM_MODULE_SHEET]: 'Fiche module',
  [I18nKeys.MENU_ITEM_PEDAGOGICAL_SENARIO]: 'Scénario pédagogique',
  [I18nKeys.MENU_ITEM_PEDAGOGICAL_SUPPORT]: 'Support pédagogique',
  [I18nKeys.MENU_ITEM_PROCEDURES]: 'Procédures',
  [I18nKeys.MENU_ITEM_POSITIONING]: 'Positionnnement',
  [I18nKeys.MENU_ITEM_TRAINING]: 'Formation',
  [I18nKeys.MENU_ITEM_TRACK]: 'Suivi',
  [I18nKeys.MENU_ITEM_FOLDERS]: 'Dossiers',
  [I18nKeys.MENU_ITEM_PEOPLE]: 'Personnes',
  [I18nKeys.MENU_ITEM_REQUIRED_FILES]: 'Documents Requis',
  [I18nKeys.MENU_ITEM_TASKS]: 'Livrables',
  [I18nKeys.MENU_ITEM_GENERATED_FILES]: 'Documents générés',
  [I18nKeys.MENU_ITEM_INTERVENTIONS]: 'Interventions',
};

export default fr;
