import { Entity, Module, Upload } from 'src/model/model';


export class ModuleFile extends Entity {
  module: Module | null;

  upload: Upload | File;

  label: string;

  constructor(data: Partial<ModuleFile> = null, clone: boolean = false) {
    if (!clone && data instanceof ModuleFile) return data;
    super(data);

    this.module = data?.module ? new Module(data.module) : null;

    this.upload = data?.upload ? new Upload(data.upload as Upload) : null;

    this.label = data?.label ?? '';
  }
}
