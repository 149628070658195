import { InputProps } from '../InputBuilder';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from '@mui/material';
import { Dayjs } from 'dayjs';
import { DateTimeFormat } from 'src/misc/enums/DateTimeFormat';
import { useTranslation } from 'react-i18next';

export default function DatePickerInput({
  formField,
  controlProps
}: InputProps) {
  const error = controlProps.fieldState.error;
  const { t } = useTranslation();

  const handleChange = (date: Dayjs) => {
    controlProps.field.onChange(
      date?.format(DateTimeFormat.DefaultDateFormatToAPI)
    );
    formField.onChange?.(date?.format(DateTimeFormat.DefaultDateFormatToAPI));
  };

  return (
    <DesktopDatePicker
      label={t(formField.label)}
      value={controlProps.field.value}
      onChange={handleChange}
      views={formField.extras?.views ?? ['day']}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          name={formField.name}
          error={!!error}
          helperText={!!error ? error.message : ''}
        />
      )}
    />
  );
}
