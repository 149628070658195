import { ExternalPerson, Trainer } from 'src/model/model';

export class Enterprise extends ExternalPerson {
  job: string;

  companyName: string;

  constructor(data: Partial<Enterprise> = null, clone: boolean = false) {
    if (!clone && data instanceof Enterprise) return data;
    super(data);

    this.job = data?.job ?? '';
    this.companyName = data?.companyName ?? '';
  }

  public static isEnterprise(object: any): object is Enterprise {
    if (object instanceof Enterprise) return true;
    return (
      !Trainer.isTrainer(object) &&
      typeof object.companyName === 'string' &&
      ExternalPerson.isExternalPerson(object)
    );
  }
}
