import { Box } from '@mui/material';
import {
  Control,
  ControllerFieldState,
  ControllerRenderProps,
  UseFormStateReturn
} from 'react-hook-form';
import FormField from 'src/tools/form/core/FormField';
import { InputType } from 'src/tools/form/types/InputType';
import AddressInput from './Inputs/AddressInput';
import AutocompleteInput from './Inputs/AutocompleteInput';
import AvatarImageInput from './Inputs/AvatarImageInput';
import CheckboxGroupInput from './Inputs/CheckboxGroupInput';
import ColorPickerInput from './Inputs/ColorPickerInput';
import DatePickerInput from './Inputs/DatePickerInput';
import DateTimePickerInput from './Inputs/DateTimeInput';
import FileInput from './Inputs/FileInput';
import HiddenInput from './Inputs/HiddenInput';
import PasswordInput from './Inputs/PasswordInput';
import RadioGroupInput from './Inputs/RadioGroupInput';
import { RepeatedInput } from './Inputs/RepeatedInput';
import SliderInput from './Inputs/SliderInput';
import SwitchInput from './Inputs/SwitchInput';
import TextareaInput from './Inputs/TextareaInput';
import TextInput from './Inputs/TextInput';
import TimePickerInput from './Inputs/TimePickerInput';

export type ExtraContentProps = {
  beforeContentProps?: { [fieldName: string]: any };
  afterContentProps?: { [fieldName: string]: any };
};

export type InputProps = {
  formField: FormField;
  controlProps: {
    field: ControllerRenderProps<any, string>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<any>;
  };
  control?: Control<any, object>;
  extraContentProps?: ExtraContentProps;
};

const inputs: any = {
  [InputType.Text]: (props: InputProps) => <TextInput {...props} />,
  [InputType.Email]: (props: InputProps) => <TextInput {...props} />,
  [InputType.Password]: (props: InputProps) => <PasswordInput {...props} />,
  [InputType.Number]: (props: InputProps) => <TextInput {...props} />,
  [InputType.Slider]: (props: InputProps) => <SliderInput {...props} />,
  [InputType.Date]: (props: InputProps) => <DatePickerInput {...props} />,
  [InputType.Time]: (props: InputProps) => <TimePickerInput {...props} />,
  [InputType.Textarea]: (props: InputProps) => <TextareaInput {...props} />,
  [InputType.Autocomplete]: (props: InputProps) => (
    <AutocompleteInput {...props} />
  ),
  [InputType.Switch]: (props: InputProps) => <SwitchInput {...props} />,
  [InputType.RadioGroup]: (props: InputProps) => <RadioGroupInput {...props} />,
  [InputType.CheckboxGroup]: (props: InputProps) => (
    <CheckboxGroupInput {...props} />
  ),
  [InputType.File]: (props: InputProps) => <FileInput {...props} />,
  [InputType.Hidden]: (props: InputProps) => <HiddenInput {...props} />,
  [InputType.AvatarImage]: (props: InputProps) => (
    <AvatarImageInput {...props} />
  ),
  [InputType.Address]: (props: InputProps) => <AddressInput {...props} />,
  [InputType.Repeated]: (props: InputProps) => <RepeatedInput {...props} />,
  [InputType.DateTime]: (props: InputProps) => (
    <DateTimePickerInput {...props} />
  ),
  [InputType.Color]: (props: InputProps) => <ColorPickerInput {...props} />
};

export default function InputBuilder(props: InputProps) {
  const { formField, extraContentProps } = props;
  const key: string = formField.type;
  const input: (props: InputProps) => JSX.Element = inputs[key] ?? null;

  if (!input) throw new Error('Unknown input type');

  return (
    <Box
      {...(formField.isSubForm || formField.type === InputType.Hidden
        ? {}
        : { m: 2, mb: 1 })}
      sx={formField.style}
    >
      {formField?.beforeContent &&
        formField.beforeContent(
          extraContentProps?.beforeContentProps[formField.name]
        )}
      {input(props)}
      {formField?.afterContent &&
        formField.afterContent(
          extraContentProps?.afterContentProps[formField.name]
        )}
    </Box>
  );
}
