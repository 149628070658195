import { Entity } from 'src/model/model';

export   class Service extends Entity {
  label: string = '';

  constructor(data: Partial<Service> = null, clone: boolean = false) {
    if (!clone && data instanceof Service) return data;
    super(data);

    this.label = data?.label ?? '';
  }
}
