import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ExternalPerson, User } from 'src/model/model';
import { AppThunk } from 'src/store';
import UserFactory from 'src/misc/factories/UserFactory';

interface ExternalPersonState {
  externalPerson: ExternalPerson;
}

const initialState: ExternalPersonState = {
  externalPerson: null
};

const slice = createSlice({
  name: 'externalPerson',
  initialState,
  reducers: {
    getExternalPerson(
      state: ExternalPersonState,
      action: PayloadAction<{ externalPerson: ExternalPerson }>
    ) {
      const { externalPerson } = action.payload;
      state.externalPerson = externalPerson;
    }
  }
});

export const reducer = slice.reducer;

export const dispatchExternalPerson =
  (externalPerson: User | ExternalPerson): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.getExternalPerson({
        externalPerson: externalPerson
          ? (UserFactory.create(externalPerson) as ExternalPerson)
          : null
      })
    );
  };

export default slice;
