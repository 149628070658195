import { RoutePrefix } from "src/misc/enums/Router/RoutePrefix";

export function inTcmSpace(): boolean {
  return isIn(RoutePrefix.TCM);
}

export function inStudentSpace(): boolean {
  return isIn(RoutePrefix.STUDENT);
}

export function inTrainerSpace(): boolean {
  return isIn(RoutePrefix.TRAINER);
}

function isIn(space: RoutePrefix): boolean {
  let route = window.location.pathname;

  if (route[0] === '/') {
    route = route.slice(1);
  }
  const parts = route.split('/');

  if (!parts.length) return false;

  return parts[0].toLowerCase() === space.toLowerCase();
}
