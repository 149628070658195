import { Entity, SessionFileType, Upload, Session } from 'src/model/model';

export class SessionFile extends Entity {
  label: string;

  type: SessionFileType | null;

  upload: Upload | File;

  session: Session | null;

  constructor(data: Partial<SessionFile> = null, clone: boolean = false) {
    if (!clone && data instanceof SessionFile) return data;
    super(data);

    this.type = data?.type ? new SessionFileType(data.type) : null;

    this.upload = data?.upload ? new Upload(data.upload as Upload) : null;

    this.session = data?.session ? new Session(data.session) : null;

    this.label = data?.label ?? '';
  }
}
