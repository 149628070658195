import { Box, styled } from '@mui/material';
import { FC } from 'react';

type AuthLayoutProps = {
  imageOnSide?: boolean;
};

const AuthLayout: FC<AuthLayoutProps> = (props) => {
  const AuthImageBackground = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundSize: 'cover',
    color: 'white',
    ...(props.imageOnSide
      ? {
          backgroundImage: 'url("/images/auth/login-page-background.jpg")',
          flex: 9,
          [theme.breakpoints.down('lg')]: {
            display: 'none'
          }
        }
      : { backgroundImage: 'url("/images/auth/okalee-background-full.jpg")' })
  }));

  return <AuthImageBackground>{props.children}</AuthImageBackground>;
}

export default AuthLayout;
