import Fetcher from 'src/tools/fetcher/Fetcher';
import { API_PATH } from 'src/api-path';
import ApiResponse from 'src/misc/types/ApiResponse';

// DEFAULT API FETCHER 
export const fetcher = new Fetcher({
  baseUrl: API_PATH
});

// YOU CAN CREATE OTHER GLOBAL FETCHER OBJECT FOR OTHER EXTERNAL APIS

export function responseIsOk(response: ApiResponse<any>): boolean {
  return response?.status && response.status > 199 && response.status < 300;
}
