import { Target } from "src/misc/enums/Shared/Target";
import { Entity, StudentFile, TrainerFile } from "../model";


export class RequiredFile extends Entity {

    label: string = null;

    studentFiles: StudentFile[] = [];

    trainerFiles: TrainerFile[] = [];

    target: Target;

    constructor(data: Partial<RequiredFile> = null, clone: boolean = false) {
        if (!clone && data instanceof RequiredFile) return data;

        super(data);

        this.label = data?.label ?? '';

        this.target = data?.target;

        this.studentFiles = data?.studentFiles?.map((studentFile: StudentFile) => new StudentFile(studentFile)) ?? [];

        this.trainerFiles = data?.trainerFiles?.map((trainerFile: TrainerFile) => new TrainerFile(trainerFile)) ?? [];
    }
}