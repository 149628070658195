import dayjs, { Dayjs } from 'dayjs';
import { Entity } from 'src/model/model';


export   class HandicapInterlocutor extends Entity {
  firstname: string | null;

  lastname: string | null;

  startedAt: string | Dayjs;

  comment: string | null;

  phone: string | null;

  email: string | null;

  constructor(data: Partial<HandicapInterlocutor> = null, clone: boolean = false) {
    if (!clone && data instanceof HandicapInterlocutor) return data;
    super(data);

    this.firstname = data?.firstname ?? "";
    this.lastname = data?.lastname ?? "";
    this.comment = data?.comment ?? "";
    this.phone = data?.phone ?? "";
    this.email = data?.email ?? "";
    this.startedAt = data?.startedAt ? dayjs(data.startedAt) : null;
  }
}
