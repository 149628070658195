import FormItemsHelper from 'src/tools/form/helpers/FormItemsHelper';
import FormField from './FormField';
import Row from './Row';
export interface FormParams<T> {
  initialValues: T;
  handleSubmit: (values: any) => Promise<any> | any;
  fields: (Row | FormField)[];
}

export type FormElement = FormField | Row;

export default class Form<T> {
  public id: string;

  public initialValues: T;

  private _initialValuesState: T;

  public readonly handleSubmit: (values: any) => void;

  public readonly rows: Row[];

  //public readonly fields: FormField[];

  constructor(params: FormParams<T>) {
    this.initialValues = params.initialValues;
    this._initialValuesState = { ...params.initialValues };
    this.handleSubmit = params.handleSubmit;
    this.rows = FormItemsHelper.createRows(params.fields);
    //this.fields = FormItemsHelper.createFields(params.fields);
    this.id = Math.random().toString(36).substr(2, 5);
  }

  public reset() {
    this.initialValues = { ...this._initialValuesState };
  }
}
