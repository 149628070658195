import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { Session } from 'src/model/model';

interface SessionState {
  session: Session;
}

const initialState: SessionState = {
  session: null
};

const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    getSession(
      state: SessionState,
      action: PayloadAction<{ session: Session }>
    ) {
      const { session } = action.payload;
      state.session = session;
    }
  }
});

export const reducer = slice.reducer;

export const dispatchSession =
  (session: Session): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.getSession({
        session: session ? new Session(session) : null
      })
    );
  };

export default slice;
