import { Entity, Session, SessionLinkCategory } from 'src/model/model';

export class SessionLink extends Entity {
  session: Session | null;

  category: SessionLinkCategory | null;

  studentContentComment: string;

  trainerContentComment: string;

  studentContentLink: string;

  trainerContentLink: string;

  constructor(data: Partial<SessionLink> = null, clone: boolean = false) {
    if (!clone && data instanceof SessionLink) return data;
    super(data);

    this.studentContentComment = data?.studentContentComment ?? "";
    this.trainerContentComment = data?.trainerContentComment ?? "";
    this.studentContentLink = data?.studentContentLink ?? "";
    this.trainerContentLink = data?.trainerContentLink ?? "";
    this.category = data?.category
      ? new SessionLinkCategory(data?.category)
      : null;
    this.session = data?.session ? new Session(data?.session) : null;
  }
}
