import {
  Entity,
  Product,
  EducationalPath,
  Module,
  Course,
  Category
} from 'src/model/model';

export class ModuleBlock extends Entity {
  label: string;

  product: Product;

  educationalPaths: EducationalPath[];

  courses: Course[];

  // for autocomplete select options
  modules: Module[];

  categories: Category[];

  constructor(data: Partial<ModuleBlock> = null, clone: boolean = false) {
    if (!clone && data instanceof ModuleBlock) return data;
    super(data);

    this.label = data?.label ?? '';
    this.product = data?.product ? new Product(data?.product) : null;
    this.modules =
      data?.courses?.map((course: Course) => new Module(course.module)) ?? [];
    this.educationalPaths =
      data?.educationalPaths?.map(
        (educationalPath: EducationalPath) =>
          new EducationalPath(educationalPath)
      ) ?? [];
    this.courses =
      data?.courses?.map((course: Course) => new Course(course)) ?? [];
    this.categories =
      data?.categories?.map((category: Category) => new Category(category)) ??
      [];
  }
}
