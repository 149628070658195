import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

export default function NetworkStatus() {
  const [, setOnline] = useState(navigator.onLine);
  let offlineSnackbarKey = null;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleOnline = useCallback(() => {
    closeSnackbar(offlineSnackbarKey);
    enqueueSnackbar('you are back online', {
      variant: 'success',
      autoHideDuration: 1000
    });
    setOnline(true);
  }, []);

  const handleOffline = useCallback(() => {
    offlineSnackbarKey = enqueueSnackbar('you are offline', {
      variant: 'error',
      persist: true
    });

    setOnline(false);
  }, []);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return <></>;
}
