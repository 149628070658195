import { Job, SessionReferent, User } from 'src/model/model';

export class Employee extends User {
  jobs: Job[];

  sessionsAsReferent: SessionReferent[]

  constructor(data: Partial<Employee> = null, clone: boolean = false) {
    if (!clone && data instanceof Employee) return data;
    super(data);

    this.jobs = data?.jobs?.map((job) => new Job(job)) ?? [];
    this.sessionsAsReferent = data?.sessionsAsReferent?.map((session) => new SessionReferent(session)) ?? [];
  }

  public static isEmployee(object: any): object is Employee {
    if (object instanceof Employee) return true;
    return Array.isArray(object.jobs) && User.isUser(object);
  }
}
