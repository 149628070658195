export enum RouteParts {
  ROOT = '/',
  LOGIN = 'login',
  ACTIVATE_ACCOUNT = 'activate-account/:hash',
  RESET_PASSWORD = 'reset-password/:hash',
  DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
  TRAINING_CENTER = 'training-center',
  DOCUMENTS = 'documents',
  PLANNING = 'planning',
  QUIZZES = 'quizzes',
  QUESTIONS_BANK = 'quizzes',
  SATISFACTION = 'quizzes',
  TRAINING_CENTERS = 'training-centers',
  COMPANIES = 'companies',
  USERS = 'users',
  SETTINGS_GENERAL_SETTINGS = 'general-settings',
  TRAINING = 'training',
  TRAINING_MODULES = 'modules',
  TRAINING_MODULE_BLOCKS = 'module-blocks',
  TRAINING_PRODUCTS = 'products',
  TRAINING_SESSIONS = 'sessions',
  TRAINING_LEARNERS = 'learners',
  TRAINING_ENTERPRISES = 'enterprises',
  TRAINING_FOLDERS = 'folders',
  TRAINING_EDUCATIONAL_PATH = 'ed-paths',
  TRAINING_PERFORMANCE_CRITERIAS = 'performance-criterias',
  TRAINING_SKILLS = 'skills',
  TRAINING_DISCOUNTS = 'discounts',
  TRAINING_TVA = 'tva',
  TRAINING_SIMPLE_PRODUCT = 'simple',
  FILES = 'files',
  TRAINING_INTERVENTIONS = 'interventions',


  TEAMS = 'teams',
  TEAMS_INTERNAL = 'internal',
  TEAMS_EXTERNAL = 'external',
  TEAMS_POST_SHEET = 'post-sheet',
  TEAMS_INTERNAL_JOBS = 'jobs',
  TEAMS_INTERNAL_SERVICES = 'services',
  TEAMS_JOB_SHEET = "job-sheet",

  QUALITY = 'quality',
  QUALITY_FEEDBACK = 'feedback',
  QUALITY_WATCHES = 'watches',
  QUALITY_HANDICAP = 'handicap',
  QUALITY_HANDICAP_PARTNER = 'partners',
  QUALITY_HANDICAP_INTERLOCUTOR = 'interlocutors',
  QUALITY_MATERIALS = 'materials',
  QUALITY_MATERIALS_ROOMS = 'rooms',
  QUALITY_MATERIALS_VIRTUAL_ROOMS = 'virtual-room',
  QUALITY_MATERIALS_EQUIPMENTS = 'equipments',
  QUALITY_DEV_PROSPECTS = 'dev-prospects',

  Quiz = "quiz"

}
