import { ChapterDataType } from 'src/misc/enums/ChapterData/ChapterDataType';
import { Entity, Chapter, Upload } from 'src/model/model';

export class ChapterData extends Entity {
  text: string;

  description: string;

  link: string;

  type: ChapterDataType;

  chapter: Chapter | null = null;

  upload: Upload |  File;

  constructor(data: Partial<ChapterData> = null, clone: boolean = false) {
    if (!clone && data instanceof ChapterData) return data;
    super(data);

    this.text = data?.text ?? "";
    this.description = data?.description ?? "";
    this.link = data?.link ?? "";
    this.type = data?.type ?? null;
    this.chapter = data?.chapter ? new Chapter(data?.chapter) : null;
    this.upload = data?.upload ? new Upload(data?.upload) : null;
  }
}
