export enum InputType {
  Text = 'text',
  Email = 'email',
  Number = 'number',
  Textarea = 'textarea',
  Password = 'password',
  Autocomplete = 'autocomplete',
  RadioGroup = 'radio-group',
  CheckboxGroup = 'checkbox-group',
  Switch = 'switch',
  Date = 'date',
  Time = 'time',
  DateTime = 'date-time',
  Color = 'color',
  Slider = 'slider',
  Hidden = 'hidden',
  File = 'file',
  AvatarImage = 'avatar-image',
  Address = 'address',
  Repeated = 'repeated',
}
