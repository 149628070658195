import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import Authenticated from 'src/components/Auth/Authenticated/Authenticated';
import Granted from 'src/components/Auth/Granted/Granted';
import { Loader } from 'src/components/Loader/Loader';
import BoxedSidebarLayout from 'src/layouts/BoxedSidebarLayout';
import { RouteParts } from 'src/misc/enums/Router/RouteParts';
import { RoutePrefix } from 'src/misc/enums/Router/RoutePrefix';
import { UserRoles } from 'src/misc/enums/Users/UserRoles';

const Dashboard = Loader(
  lazy(() => import('src/pages/TCM/Dashboard/Dashboard'))
);

const GeneralSettings = Loader(
  lazy(() => import('src/pages/TCM/Settings/GeneralSettings/GeneralSettings'))
);

const DocumentSettings = Loader(
  lazy(() => import('src/pages/TCM/Settings/Documents/DocumentSettings'))
);

const Quizzes = Loader(
  lazy(() => import('src/pages/TCM/Settings/Quizzes/Quizzes'))
);

const Modules = Loader(
  lazy(() => import('src/pages/TCM/Training/Modules/List/Modules'))
);

const ModuleDetails = Loader(
  lazy(() => import('src/pages/TCM/Training/Modules/Show/ModuleDetails'))
);

const PerformanceCriterias = Loader(
  lazy(
    () =>
      import(
        'src/pages/TCM/Training/Skills/PerformanceCriterias/PerformanceCriterias'
      )
  )
);

const Skills = Loader(
  lazy(() => import('src/pages/TCM/Training/Skills/Skills/Skills'))
);

const SkillDetails = Loader(
  lazy(() => import('src/pages/TCM/Training/Skills/Skills/Show/SkillDetails'))
);

const SimpleProducts = Loader(
  lazy(
    () =>
      import('src/pages/TCM/Training/Products/SimpleProducts/SimpleProducts')
  )
);

const SimpleProductDetails = Loader(
  lazy(
    () =>
      import(
        'src/pages/TCM/Training/Products/SimpleProducts/Show/SimpleProductDetails'
      )
  )
);

const Discounts = Loader(
  lazy(() => import('src/pages/TCM/Training/Products/Discount/Discounts'))
);

const Tva = Loader(
  lazy(() => import('src/pages/TCM/Training/Products/TVA/TVAs'))
);

const EdPaths = Loader(
  lazy(() => import('src/pages/TCM/Training/EdPaths/EdPaths'))
);

const EdPathDetails = Loader(
  lazy(() => import('src/pages/TCM/Training/EdPaths/Show/EdPathDetails'))
);

const Sessions = Loader(
  lazy(() => import('src/pages/TCM/Training/Sessions/Sessions'))
);

const SessionDetails = Loader(
  lazy(() => import('src/pages/TCM/Training/Sessions/Show/SessionDetails'))
);

const InternalTeam = Loader(
  lazy(() => import('src/pages/TCM/Teams/InternalTeam/InternalTeam'))
);

const Materials = Loader(
  lazy(() => import('src/pages/TCM/Quality/Materials/Materials'))
);

const Feedback = Loader(
  lazy(() => import('src/pages/TCM/Quality/Feedbacks/Feedbacks'))
);

const Watches = Loader(
  lazy(() => import('src/pages/TCM/Quality/Watches/Watches'))
);

const Handicap = Loader(
  lazy(() => import('src/pages/TCM/Quality/Handicap/Handicap'))
);

const DevelopmentPrespective = Loader(
  lazy(
    () =>
      import('src/pages/TCM/Quality/DevelopmentProspects/DevelopmentProspects')
  )
);

const ExternalTeam = Loader(
  lazy(() => import('src/pages/TCM/Teams/ExternalTeam/ExternalTeam'))
);

const StudentList = Loader(
  lazy(() => import('src/pages/TCM/Training/Learners/Students/StudentsList'))
);

const JobSheetList = Loader(
  lazy(() => import('src/pages/TCM/Teams/JobSheet/JobSheetList'))
);

const FoldersList = Loader(
  lazy(() => import('src/pages/TCM/Training/Learners/Folders/FoldersList'))
);

const TrainerDetails = Loader(
  lazy(() => import('src/pages/TCM/Teams/ExternalTeam/Show/TrainerDetails'))
);

const QuizPage = Loader(
  lazy(() => import('src/pages/shared/QuizPage/QuizPage'))
);

const Enterprises = Loader(
  lazy(() => import('src/pages/TCM/Enterprises/EnterprisesList'))
);

const CompaniesListPage = Loader(
  lazy(() => import('src/pages/TCM/Companies&Centers/Companies/CompaniesList'))
);

const TrainingCenters = Loader(
  lazy(
    () =>
      import(
        'src/pages/TCM/Companies&Centers/TrainingCenters/TrainingCentersList'
      )
  )
);

const tcmRoutes: RouteObject[] = [
  {
    path: RoutePrefix.TCM,
    element: (
      <Authenticated>
        <Granted roles={[UserRoles.ROLE_EMPLOYEE]}>
          <BoxedSidebarLayout />
        </Granted>
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={RouteParts.DASHBOARD} replace />
      },
      {
        path: RouteParts.DASHBOARD,
        element: <Dashboard />
      },
      {
        path: RouteParts.COMPANIES,
        element: (
          <Granted roles={[UserRoles.ROLE_ADMIN, UserRoles.ROLE_DEV]}>
            <CompaniesListPage />
          </Granted>
        )
      },
      {
        path: RouteParts.TRAINING_CENTERS,
        element: (
          <Granted roles={[UserRoles.ROLE_ADMIN, UserRoles.ROLE_DEV]}>
            <TrainingCenters />
          </Granted>
        )
      },
      {
        path: `${RouteParts.SETTINGS}`,
        children: [
          {
            path: `${RouteParts.SETTINGS_GENERAL_SETTINGS}/*`,
            element: <GeneralSettings />
          },
          {
            path: `${RouteParts.QUIZZES}/*`,
            element: <Quizzes />
          },
          {
            path: `${RouteParts.DOCUMENTS}/*`,
            element: <DocumentSettings />
          }
        ]
      },
      {
        path: RouteParts.TRAINING,
        children: [
          {
            path: RouteParts.TRAINING_MODULES,
            children: [
              {
                path: '',
                element: <Modules />
              },
              {
                path: ':id/*',
                element: <ModuleDetails />
              }
            ]
          },
          {
            path: `${RouteParts.TRAINING_PERFORMANCE_CRITERIAS}`,
            element: <PerformanceCriterias />
          },
          {
            path: `${RouteParts.TRAINING_SKILLS}`,
            children: [
              {
                path: '',
                element: <Skills />
              },
              {
                path: ':id/*',
                element: <SkillDetails />
              }
            ]
          },
          {
            path: RouteParts.TRAINING_EDUCATIONAL_PATH,
            children: [
              {
                path: '',
                element: <EdPaths />
              },
              {
                path: ':id/*',
                element: <EdPathDetails />
              }
            ]
          },
          {
            path: `${RouteParts.TRAINING_PRODUCTS}`,
            children: [
              {
                path: `${RouteParts.TRAINING_SIMPLE_PRODUCT}`,
                children: [
                  {
                    path: '',
                    element: <SimpleProducts />
                  },
                  {
                    path: ':id/*',
                    element: <SimpleProductDetails />
                  }
                ]
              },
              {
                path: `${RouteParts.TRAINING_DISCOUNTS}`,
                children: [
                  {
                    path: '',
                    element: <Discounts />
                  },
                  {
                    path: ':id',
                    element: <>details, à developper</>
                  }
                ]
              },
              {
                path: `${RouteParts.TRAINING_TVA}`,
                children: [
                  {
                    path: '',
                    element: <Tva />
                  },
                  {
                    path: ':id',
                    element: <>details, à developper</>
                  }
                ]
              }
            ]
          },
          {
            path: RouteParts.TRAINING_SESSIONS,
            children: [
              {
                path: '',
                element: <Sessions />
              },
              {
                path: ':id/*',
                element: <SessionDetails />
              }
            ]
          },
          {
            path: RouteParts.TRAINING_LEARNERS,
            element: <StudentList />
          },
          {
            path: RouteParts.TRAINING_FOLDERS,
            element: <FoldersList />
          },
          {
            path: RouteParts.TRAINING_ENTERPRISES,
            element: <Enterprises />
          }
        ]
      },
      {
        path: RouteParts.TEAMS,
        children: [
          {
            path: RouteParts.TEAMS_EXTERNAL,
            children: [
              {
                path: '',
                element: <ExternalTeam />
              },
              {
                path: `:id/*`,
                element: <TrainerDetails />
              }
            ]
          },

          {
            path: `${RouteParts.TEAMS_INTERNAL}/*`,
            element: <InternalTeam />
          },
          {
            path: RouteParts.TEAMS_JOB_SHEET,
            element: <JobSheetList />
          }
        ]
      },
      {
        path: RouteParts.QUALITY,
        children: [
          {
            path: RouteParts.QUALITY_FEEDBACK,
            element: <Feedback />
          },
          {
            path: RouteParts.QUALITY_DEV_PROSPECTS,
            element: <DevelopmentPrespective />
          },
          {
            path: RouteParts.QUALITY_WATCHES,
            element: <Watches />
          },
          {
            path: `${RouteParts.QUALITY_HANDICAP}/*`,
            element: <Handicap />
          },
          {
            path: `${RouteParts.QUALITY_MATERIALS}/*`,
            element: <Materials />
          }
        ]
      },
      {
        path: RouteParts.Quiz,
        element: <QuizPage />
      }
    ]
  }
];

export default tcmRoutes;
