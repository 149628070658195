import {ReactNode, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import LoginPage from 'src/pages/Auth/Login/LoginPage';

type AuthenticatedProps = {
  children: ReactNode;
}

export default function Authenticated(props: AuthenticatedProps) {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <LoginPage />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
