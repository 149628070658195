import Column from '../../tools/form/core/Column';
import FormField from '../../tools/form/core/FormField';
import Row from '../../tools/form/core/Row';

export default class FormItemFactory {
  public static create(object: any): Row | Column | FormField {
    if (FormField.isFormField(object)) {
      if (object instanceof FormField) return object;
      return new FormField(object);
    }

    if (Column.isColumn(object)) {
      const content = FormItemFactory.create(object.content) as Row | FormField;
      let viewWidth = object.viewWidth;
      if (FormField.isFormField(content)) {
        viewWidth = viewWidth || content.viewWidth;
      }
      if (object instanceof Column) return object;
      return new Column(viewWidth, content);
    }

    if (Row.isRow(object)) {
      const columns = object.columns.map((column) => {
        let col: Column;
        if (FormField.isFormField(column)) {
          const formField = FormItemFactory.create(column) as FormField;
          col = FormItemFactory.create({
            viewWidth: formField.viewWidth,
            content: formField
          }) as Column;
        } else if (Column.isColumn(column)) {
          col = FormItemFactory.create(column) as Column;
        }
        return col;
      });
      if (object instanceof Column) return object;

      return new Row(object.label, columns);
    }
  }
}
