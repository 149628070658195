import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { Module } from 'src/model/model';

interface ModuleState {
  module: Module;
}

const initialState: ModuleState = {
  module: null
};

const slice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    getModule(state: ModuleState, action: PayloadAction<{ module: Module }>) {
      const { module } = action.payload;
      state.module = module;
    }
  }
});

export const reducer = slice.reducer;

export const dispatchModule =
  (module: Module): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.getModule({ module: module ? new Module(module) : null })
    );
  };

export default slice;
