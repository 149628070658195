import UserFactory from 'src/misc/factories/UserFactory';
import { AuthCredentials, LoginResponse } from 'src/misc/types/AuthCredentials';
import { User } from 'src/model/model';
import { fetcher, responseIsOk } from 'src/utils/fetcher';

const PREFIX = '/api/auth';

export default class AutheticationApiFetcher {
  static async login(credentials: AuthCredentials) {
    try {
      const response = await fetcher.post<LoginResponse>(
        `${PREFIX}/login`,
        credentials,
        {
          no401Blocking: true
        }
      );

      if (responseIsOk(response)) {
        response.data = {
          ...response.data,
          user: UserFactory.create(response.data.user)
        };
      }
      return response;
    } catch (error) {}
  }

  static forgotPassword(email: string) {
    return fetcher.post<{}>(`${PREFIX}/forgot-password`, { email });
  }

  static async CheckResetPasswordAbility(hash: string) {
    const response = await fetcher.get<User>(
      `${PREFIX}/check/reset-password-hash/${hash}`
    );
    response.data = UserFactory.create(response.data);
    return response;
  }

  static async checkActivationAbilitiy(hash: string) {
    const response = await fetcher.get<User>(
      `${PREFIX}/check/activation-hash/${hash}`
    );
    response.data = UserFactory.create(response.data);
    return response;
  }

  static activateAccountFromActivationLink(userId: number, password: string) {
    return fetcher.post(`${PREFIX}/activate/${userId}`, { password });
  }

  static resetPassword(userId: number, password: string) {
    return fetcher.post<{}>(`${PREFIX}/reset-password/${userId}`, { password });
  }

  static forceReinitPassword(userId: number) {
    return fetcher.get<{}>(`${PREFIX}/reinit-password/${userId}`);
  }
}
