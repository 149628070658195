import {
  Entity,
  Tariff,
  ModuleBlock,
  PriceQuoteDetails,
  ProductSheet
} from 'src/model/model';

export class Product extends Entity {
  moduleBlock: ModuleBlock;

  tariffs: Tariff[];

  activeTariff: Tariff;

  priceQuoteDetails: PriceQuoteDetails[];

  calculatedPrice: number;

  productSheet: ProductSheet;

  constructor(data: Partial<Product> = null, clone: boolean = false) {
    if (!clone && data instanceof Product) return data;
    super(data);

    this.moduleBlock = data?.moduleBlock
      ? new ModuleBlock(data?.moduleBlock)
      : null;
    this.activeTariff = data?.activeTariff
      ? new Tariff(data?.activeTariff)
      : null;
    this.tariffs =
      data?.tariffs?.map((tariff: Tariff) => new Tariff(tariff)) ?? [];
    this.priceQuoteDetails =
      data?.priceQuoteDetails?.map(
        (priceDetailsQuote: PriceQuoteDetails) =>
          new PriceQuoteDetails(priceDetailsQuote)
      ) ?? [];
    this.calculatedPrice = data?.calculatedPrice ?? null;
    this.productSheet = data?.productSheet
      ? new ProductSheet(data.productSheet)
      : null;
  }
}
