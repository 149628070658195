import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { Settings } from 'src/model/model';

interface SettingsState {
  settings: Settings;
}

const initialState: SettingsState = {
  settings: null
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings(
      state: SettingsState,
      action: PayloadAction<{ settings: Settings }>
    ) {
      state.settings = action.payload.settings;
    }
  }
});

export const reducer = slice.reducer;

export const dispatchSettings =
  (settings: Settings): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setSettings({
        settings: settings ? new Settings(settings) : null
      })
    );
  };

export default slice;
