import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { Folder } from 'src/model/model';
import FolderPickerHelper from 'src/services/Folder/Helpers/FolderPickerHelper';

interface ExternalPersonFoldersState {
  connectedFolder: Folder;
  folders: Folder[];
}

const initialState: ExternalPersonFoldersState = {
  connectedFolder: null,
  folders: []
};

const slice = createSlice({
  name: 'externalPersonFolders',
  initialState,
  reducers: {
    setFolders(
      state: ExternalPersonFoldersState,
      action: PayloadAction<{ folders: Folder[] }>
    ) {
      const { folders } = action.payload;
      state.folders = folders;
      state.connectedFolder =
        FolderPickerHelper.getDefaultActiveFolder(folders);
    },

    setConnectedFolder(
      state: ExternalPersonFoldersState,
      action: PayloadAction<{ folderId: number }>
    ) {
      state.connectedFolder = FolderPickerHelper.find(
        state.folders,
        action.payload.folderId
      );
    }
  }
});

export const reducer = slice.reducer;

export const dispatchFolders =
  (folders: Folder[]): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setFolders({ folders }));
  };

export const dispatchConnectedFolder =
  (folderId: number): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setConnectedFolder({ folderId }));
  };

export default slice;
