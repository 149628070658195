import dayjs, { Dayjs } from 'dayjs';
import { PositioningInterviewType } from 'src/misc/enums/Folder/PositioningInterviewType';
import {
  Employee,
  Entity,
  Quiz,
  Upload,
  UserQuizAnswer
} from 'src/model/model';

export class PositioningInterview extends Entity {
  type: PositioningInterviewType;

  interviewer: Employee;

  date: Dayjs | string;

  isOnline: boolean;

  content: string;

  file: Upload | File;

  interviewerQuizAnswer: UserQuizAnswer;

  constructor(
    data: Partial<PositioningInterview> = null,
    clone: boolean = false
  ) {
    if (!clone && data instanceof PositioningInterview) return data;
    super(data);

    this.interviewer = data?.interviewer
      ? new Employee(data.interviewer)
      : null;
    this.type = data?.type ?? 0;
    this.date = data?.date ? dayjs(data.date) : null;
    this.content = data?.content ?? '';
    this.interviewerQuizAnswer = data?.interviewerQuizAnswer
      ? new UserQuizAnswer(data?.interviewerQuizAnswer)
      : null;
    this.file = data?.file ? new Upload(data?.file) : null;
  }

  public isDone() {
    return this.file || this.content?.length || this.interviewerQuizAnswer;
  }
}
