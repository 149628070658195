import { Folder, Employee, User } from 'src/model/model';

export class ExternalPerson extends User {
  folders: Folder[] = [];

  public constructor(data: Partial<ExternalPerson>, clone: boolean = false) {
    if (!clone && data instanceof ExternalPerson) return data;
    super(data);

    this.folders =
      data?.folders?.map((fodler: Folder) => new Folder(fodler)) ?? [];
  }

  public static isExternalPerson(object: any): object is ExternalPerson {
    if (object instanceof ExternalPerson) return true;
    return !Employee.isEmployee(object) && User.isUser(object);
  }
}
