import { LazyExoticComponent, Suspense } from 'react';
import SuspenseLoader from '../SuspenseLoader/SuspenseLoader';

export const Loader =
  (
    Component: LazyExoticComponent<() => JSX.Element>,
    LoadingComponent?: JSX.Element
  ) =>
  (props: JSX.IntrinsicAttributes) =>
    (
      <Suspense fallback={LoadingComponent ?? <SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    );
