import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { Trainer } from 'src/model/model';

interface TrainerState {
    trainer: Trainer;
}

const initialState: TrainerState = {
    trainer: null
};

const slice = createSlice({
    name: 'trainer',
    initialState,
    reducers: {
        getTrainer(
            state: TrainerState,
            action: PayloadAction<{ trainer: Trainer }>
        ) {
            const { trainer } = action.payload;
            state.trainer = trainer;
        }
    }
});

export const reducer = slice.reducer;

export const dispatchTrainer =
    (trainer: Trainer): AppThunk =>
        async (dispatch) => {
            dispatch(
                slice.actions.getTrainer({
                    trainer: trainer ? new Trainer(trainer) : null
                })
            );
        };

export default slice;