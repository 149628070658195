import { Option } from 'src/tools/form/types/Option';

export enum FeedbackType {
  Reclamation = 1,
  Alea
}

export const feedbackTypes: Option[] = [
  { id: FeedbackType.Reclamation, label: 'Réclamation' },
  { id: FeedbackType.Alea, label: 'Aléa' }
];

export function getFeedbackType(feedbackTypeId: number): string {
  return feedbackTypes.find(
    (feedbackType) => feedbackType.id === feedbackTypeId
  ).label;
}
