import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Slider
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InputProps } from '../InputBuilder';

export default function SliderInput(props: InputProps) {
  const error = props.controlProps.fieldState.error;
  const { t } = useTranslation();

  return (
    <FormControl error={!!error}>
      <FormControlLabel
        name={props.formField.name}
        label={t(props.formField.label)}
        control={
          <Slider
            value={props.controlProps.field.value}
            onChange={(e, v) => {
              props.controlProps.field.onChange(e, v);
              props.formField.onChange?.(v);
            }}
            valueLabelDisplay="auto"
            min={props.formField.extras?.sliderMin ?? 0}
            max={props.formField.extras?.sliderMax ?? 10}
            marks={props.formField.extras?.sliderMarks ?? false}
            step={props.formField.extras?.sliderStep}
          />
        }
      />

      <FormHelperText>{!!error ? error.message : ''}</FormHelperText>
    </FormControl>
  );
}
