import {
  Control,
  Controller,
  DeepPartial,
  FormProvider,
  useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'src/tools/form/core/Form';
import CSS from 'csstype';
import Validator from 'src/tools/form/core/Validator';
import InputBuilder, { ExtraContentProps } from '../InputBuilder/InputBuilder';
import { Grid, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box } from '@mui/system';
import { ForwardedRef, forwardRef, RefObject, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import FormItemsHelper from 'src/tools/form/helpers/FormItemsHelper';
import Row from 'src/tools/form/core/Row';
import Column from 'src/tools/form/core/Column';
import FormField from 'src/tools/form/core/FormField';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';

export type FormBuilderProps<T> = Omit<SubFormBuilderProps<T>, 'control'> & {
  style?: CSS.Properties;
  isLoading?: boolean;
  submitButton?: {
    text?: string | TFunction | JSX.Element;
    position?: 'center' | 'end' | 'start';
    style?: CSS.Properties;
    icon?: JSX.Element;
    hide?: boolean;
    ref?: RefObject<HTMLButtonElement>;
  };
  rerender?: boolean;
};

type SubFormBuilderProps<T> = Omit<SubFormElementBuilderProps, 'formItem'> & {
  form: Form<T>;
};

type SubFormElementBuilderProps = {
  formItem: Row | FormField | Column;
  control: Control<any, object>;
  extraContentProps?: ExtraContentProps;
};

export const SubFormBuilder = <T extends {}>(props: SubFormBuilderProps<T>) => {
  return (
    <Grid container spacing={1} flexDirection="column">
      {props.form.rows.map((row) => {
        return (
          <Grid item key={row.id} xs={12}>
            <SubFormElementBuilder
              key={row.id}
              formItem={row}
              control={props.control}
              extraContentProps={props.extraContentProps}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const SubFormElementBuilder = (props: SubFormElementBuilderProps) => {
  const { formItem, extraContentProps, control } = props;
  const { t } = useTranslation();

  if (FormField.isFormField(formItem)) {
    return (
      <Controller
        key={formItem.name}
        name={formItem.name}
        control={control}
        render={(controlProps) => (
          <InputBuilder
            control={control}
            formField={formItem}
            controlProps={controlProps}
            extraContentProps={extraContentProps}
          />
        )}
      />
    );
  }

  if (Column.isColumn(formItem)) {
    return (
      <Grid item key={formItem.id ?? Math.random()} {...formItem.viewWidth}>
        <SubFormElementBuilder
          formItem={formItem.content}
          control={control}
          extraContentProps={extraContentProps}
        />
      </Grid>
    );
  }

  if (Row.isRow(formItem)) {
    const Label =
      typeof formItem.label === 'string' && formItem.label.length > 0 ? (
        <Typography variant="h5">{t(formItem.label)}</Typography>
      ) : (
        formItem.label
      );

    return (
      <Stack>
        <Box
          sx={{
            mt: 1,
            mx: 2.5
          }}
        >
          {Label}
        </Box>
        <Grid container>
          {formItem.columns.map((column) => (
            <SubFormElementBuilder
              key={column.id}
              formItem={column}
              control={control}
              extraContentProps={extraContentProps}
            />
          ))}
        </Grid>
      </Stack>
    );
  }
};

const cleanValues = (values: any): any => {
  const output = {};

  Object.keys(values).map((key: string) => {
    if (typeof values[key] === 'object') {
      if (key === 'address' || key === 'questionOptions') {
        output[key] = values[key];
      } else if (Array.isArray(values[key])) {
        output[key] = values[key]?.map((value: any) => value?.id ?? value);
      } else if (values[key] instanceof File) {
        output[key] = values[key];
      } else {
        output[key] = values[key]?.id ?? values[key];
      }
    } else {
      output[key] = values[key];
    }
  });

  return output;
};

/**
 * build an html form with validation and optimized rerendering
 * @uses react-hook-form
 * @uses Validator
 * @param props
 * @returns {JSX.Element}
 */
const FormBuilder = forwardRef(
  <T extends {}>(
    props: FormBuilderProps<T>,
    ref: ForwardedRef<HTMLFormElement>
  ) => {
    const { t } = useTranslation();
    const valuesType = () => props.form.initialValues;
    const methods = useForm<ReturnType<typeof valuesType>>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: props.form.initialValues as DeepPartial<T>,
      resolver: yupResolver(
        Validator.buildSchema(
          FormItemsHelper.getFieldsFromRows(props.form.rows)
        )
      )
    });

    const { control, handleSubmit, formState, getValues } = methods;

    const buttonsStyles = props.submitButton?.hide ? { display: 'none' } : {};

    // warning - if the form is not showing values in update or it"s not updating
    // check this Memo wrapper
    const SubForm = useMemo(
      () => (
        <SubFormBuilder
          control={control}
          form={props.form}
          extraContentProps={props.extraContentProps}
        />
      ),
      props.rerender ? [props.form] : []
    );

    return (
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit((values) => {
            props.form.handleSubmit(cleanValues(values));
          })}
          style={props.style}
          ref={ref}
        >
          {SubForm}
          <Box
            display={'flex'}
            justifyContent={props.submitButton?.position ?? 'center'}
            alignItems="center"
            margin={2}
            marginTop={1}
          >
            <LoadingButton
              loading={props.isLoading}
              loadingPosition="center"
              startIcon={props.submitButton?.icon}
              variant="contained"
              type="submit"
              style={
                props.submitButton?.style
                  ? { ...buttonsStyles, ...props.submitButton?.style }
                  : buttonsStyles
              }
              {...(props.submitButton?.ref
                ? { ref: props.submitButton?.ref }
                : {})}
            >
              {props.submitButton?.text ?? t(I18nKeys.LABEL_SAVE)}
            </LoadingButton>
          </Box>
        </form>
      </FormProvider>
    );
  }
);
export default FormBuilder;
