import { Entity, Equipment, TrainingCenter } from 'src/model/model';
import { EventRoom } from '../Training/Session/Planning/EventRoom';

export class Room extends Entity {
  label: string;

  comment: string;

  equipments: Equipment[];

  trainingCenter: TrainingCenter;

  isVirtualRoom: boolean;

  link: string;

  password: string;

  capacity: number;

  eventRooms: EventRoom[];

  constructor(data: Partial<Room> = null, clone: boolean = false) {
    if (!clone && data instanceof Room) return data;
    super(data);

    this.label = data?.label ?? '';
    this.link = data?.link ?? '';
    this.password = data?.password ?? '';
    this.comment = data?.comment ?? '';
    this.equipments =
      data?.equipments?.map(
        (equipment: Equipment) => new Equipment(equipment)
      ) ?? [];
    this.eventRooms =
      data?.eventRooms?.map(
        (eventRoom: EventRoom) => new EventRoom(eventRoom)
      ) ?? [];
    this.trainingCenter = data?.trainingCenter
      ? new TrainingCenter(data?.trainingCenter)
      : null;
    this.isVirtualRoom = data?.isVirtualRoom ?? false;
    this.capacity = data?.capacity ?? null;
  }
}
