import dayjs, { Dayjs } from 'dayjs';
import { Entity, Planning } from 'src/model/model';


export   class PlanningException extends Entity {
  type: number;

  beginsAt: string | Dayjs = null;

  endsAt: string | Dayjs = null;

  planning: Planning = null;

  constructor(data: Partial<PlanningException> = null, clone: boolean = false) {
    if (!clone && data instanceof PlanningException) return data;
    super(data);

    this.type = data?.type ?? null;
    this.beginsAt = data?.beginsAt ? dayjs(data?.beginsAt) : null;
    this.endsAt = data?.endsAt ? dayjs(data?.endsAt) : null;
    this.planning = data?.planning ? new Planning(data?.planning) : null;
  }
}
