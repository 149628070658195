import { LogicalOperator } from 'src/misc/enums/Paginator/LogicalOperator';
import { PaginatorDefaultValues } from 'src/misc/enums/Paginator/PaginatorDefaultValues';
import { PaginatorKeys } from 'src/misc/enums/Paginator/PaginatorKeys';
import { PaginatorFilterParams } from 'src/misc/types/Paginator/GetAllParams';

export function generateQueryParams(params: PaginatorFilterParams): string {
  if (!params) return '';

  let page = params.page ?? PaginatorDefaultValues.Page;
  let rowsPerPage = params.rowsPerPage ?? PaginatorDefaultValues.RowsPerPage;
  let logicalOperator = params.logicalOperator ?? LogicalOperator.AND;
  let exactSearch = params.exactSearch ?? [];
  let searchFields = params.searchFields ?? [];
  let archive = params.archive;
  let searchKey = params.searchKey ?? PaginatorDefaultValues.searchKey;
  let sort = params.sort ?? null;
  let filter = params.filter ?? null;
  let trainingCenter = params.trainingCenter ?? null;

  let query = '?';

  query += `${PaginatorKeys.Page}=${page}&`;
  query += `${PaginatorKeys.Limit}=${rowsPerPage}&`;

  if (trainingCenter && trainingCenter.id) {
    query += `${PaginatorKeys.TRAINING_CENTER}=${trainingCenter.id}&`;
  }

  if (sort) {
    query += `${PaginatorKeys.Sort}[${sort.field}]=${sort.direction}&`;
  }

  if (archive !== null) {
    query += `${PaginatorKeys.Archived}=${archive ? 1 : 0}&`;
  }

  if (exactSearch.length) {
    exactSearch.map((key: string, index: number) => {
      query += `${PaginatorKeys.ExactSearch}[${index}]=${key}&`;
    });
  }

  if (filter) {
    if (searchFields.length && filter[searchKey]?.length) {
      searchFields.map((key) => {
        query += `${key}=${filter[searchKey]}&`;
      });
      logicalOperator = LogicalOperator.OR;
    }
    Object.keys(filter).map((key) => {
      if (!searchFields.includes(key) && key !== searchKey && filter[key] !== null && filter[key] !== undefined) {
        let value = filter[key];
        if (typeof filter[key] === "boolean") {
          value = filter[key] ? 1 : 0;
        }
        query += `${key}=${value}&`;
        logicalOperator = LogicalOperator.AND;
      }
    });
  }

  query += `${PaginatorKeys.LogicalOp}=${logicalOperator}&`;

  if (query[query.length - 1] === '&') {
    query = query.slice(0, query.length - 1);
  }

  return query;
}
