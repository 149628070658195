export enum I18nKeys {
  API_ACCOUNT_DEACTIVATED = "API_ACCOUNT_DEACTIVATED",
  API_LOGIN_FAILURE = "API_LOGIN_FAILURE",
  API_INVALID_LINK = "API_INVALID_LINK",
  API_ACCOUNT_NOT_FOUND = "API_ACCOUNT_NOT_FOUND",
  API_MAIL_SENT = "API_MAIL_SENT",
  API_TWO_ACTIVE_TARIFFS = "API_TWO_ACTIVE_TARIFFS",
  API_RESOURCE_NOT_FOUND = "API_RESOURCE_NOT_FOUND",
  API_INTERNAL_ERROR = "API_INTERNAL_ERROR",
  API_UNAUTHORIZED = "API_UNAUTHORIZED",

  FORM_LABEL_EMAIL = "FORM_LABEL_EMAIL",
  FORM_LABEL_LABEL = "FORM_LABEL_LABEL",
  FORM_LABEL_PASSWORD = "FORM_LABEL_PASSWORD",
  FORM_LABEL_CONFIRM_PASSWORD = "FORM_LABEL_CONFIRM_PASSWORD",
  FORM_LABEL_AGREE_TERMS = "FORM_LABEL_AGREE_TERMS",
  FORM_TERMS_CONDITIONS = "FORM_TERMS_CONDITIONS",
  FORM_LABEL_FIRSTNAME = "FORM_LABEL_FIRSTNAME",
  FORM_LABEL_LASTNAME = "FORM_LABEL_LASTNAME",
  FORM_LABEL_BIRTH_DATE = "FORM_LABEL_BIRTH_DATE",
  FORM_LABEL_GENDER = "FORM_LABEL_GENDER",
  FORM_LABEL_NAME = "FORM_LABEL_NAME",
  FORM_LABEL_COMPANY = "FORM_LABEL_COMPANY",
  FORM_LABEL_MANAGER = "FORM_LABEL_MANAGER",
  FORM_LABEL_SIRET = "FORM_LABEL_SIRET",
  FORM_LABEL_CAPITAL = "FORM_LABEL_CAPITAL",
  FORM_LABEL_WEBSITE = "FORM_LABEL_WEBSITE",
  FORM_LABEL_PHONE = "FORM_LABEL_PHONE",
  FORM_LABEL_ADDRESS = "FORM_LABEL_ADDRESS",
  FORM_LABEL_TRAINING_CENTERS = "FORM_LABEL_TRAINING_CENTERS",
  FORM_LABEL_COUNTRY = "FORM_LABEL_COUNTRY",
  FORM_LABEL_CITY = "FORM_LABEL_CITY",
  FORM_LABEL_STREET = "FORM_LABEL_STREET",
  FORM_LABEL_ZIPCODE = "FORM_LABEL_ZIPCODE",
  FORM_LABEL_SEARCH = "FORM_LABEL_SEARCH",
  FORM_LABEL_REFERENCE = "FORM_LABEL_REFERENCE",
  FORM_LABEL_LEVEL = "FORM_LABEL_LEVEL",
  FORM_LABEL_DURATION = "FORM_LABEL_DURATION",
  FORM_LABEL_ATTENDANCE_SEQUANCE_CODE = "FORM_LABEL_ATTENDANCE_SEQUANCE_CODE",
  FORM_LABEL_ADVANCED = "FORM_LABEL_ADVANCED",
  FORM_LABEL_INITIATION = "FORM_LABEL_INITIATION",
  FORM_LABEL_INTERMEDIATE = "FORM_LABEL_INTERMEDIATE",
  FORM_LABEL_FACE_TO_FACE = "FORM_LABEL_FACE_TO_FACE",
  FORM_LABEL_ELEARNING = "FORM_LABEL_ELEARNING",
  FORM_LABEL_BLENDED = "FORM_LABEL_BLENDED",
  FORM_LABEL_TRAINING_ACTION = "FORM_LABEL_TRAINING_ACTION",
  FORM_LABEL_SKILLS_ASSESSMENT = "FORM_LABEL_SKILLS_ASSESSMENT",
  FORM_LABEL_VAE_ACTION = "FORM_LABEL_VAE_ACTION",
  FORM_LABEL_APPRENTICESHIP_TRAINING = "FORM_LABEL_APPRENTICESHIP_TRAINING",
  FORM_LABEL_PRICE = "FORM_LABEL_PRICE",
  FORM_LABEL_DISCOUNT = "FORM_LABEL_DISCOUNT",
  FORM_LABEL_MULTIPLE_CHOICE = "FORM_LABEL_MULTIPLE_CHOICE",
  FORM_LABEL_FREE_ANSWER = "FORM_LABEL_FREE_ANSWER",
  FORM_LABEL_SATISFACTION = "FORM_LABEL_SATISFACTION",
  FORM_LABEL_STUDENT = "FORM_LABEL_STUDENT",
  FORM_LABEL_EMPLOYEE = "FORM_LABEL_EMPLOYEE",
  FORM_LABEL_ENTERPRISE = "FORM_LABEL_ENTERPRISE",
  FORM_LABEL_TRAINER = "FORM_LABEL_TRAINER",
  FORM_LABEL_BEFORE = "FORM_LABEL_BEFORE",
  FORM_LABEL_AFTER = "FORM_LABEL_AFTER",
  FORM_LABEL_TECHNICAL = "FORM_LABEL_TECHNICAL",
  FORM_LABEL_INTERVIEW = "FORM_LABEL_INTERVIEW",
  FORM_LABEL_SESSION = "FORM_LABEL_SESSION",
  FORM_LABEL_TRAINING_CENTER = "FORM_LABEL_TRAINING_CENTER",
  FORM_LABEL_RECOMMENDATION = "FORM_LABEL_RECOMMENDATION",
  FORM_LABEL_ACTIVE = "FORM_LABEL_ACTIVE",
  FORM_LABEL_PENDING = "FORM_LABEL_PENDING",
  FORM_LABEL_REFUSED = "FORM_LABEL_REFUSED",
  FORM_LABEL_ALUMNUS = "FORM_LABEL_ALUMNUS",
  FORM_LABEL_ABANDON = "FORM_LABEL_ABANDON",
  FORM_LABEL_CANCELED = "FORM_LABEL_CANCELED",
  FORM_LABEL_ADMINISTRATIVE = "FORM_LABEL_ADMINISTRATIVE",
  FORM_LABEL_PEDAGOGIC = "FORM_LABEL_PEDAGOGIC",
  FORM_LABEL_POSITIONING = "FORM_LABEL_POSITIONING",
  FORM_LABEL_TRACK = "FORM_LABEL_TRACK",
  FORM_LABEL_HANDICAP = "FORM_LABEL_HANDICAP",
  FORM_LABEL_EXCHANGE = "FORM_LABEL_EXCHANGE",
  FORM_LABEL_FORMATION = "FORM_LABEL_FORMATION",
  FORM_LABEL_CERTIFICATION = "FORM_LABEL_CERTIFICATION",
  FORM_LABEL_LEFT = "FORM_LABEL_LEFT",
  FORM_LABEL_RIGHT = "FORM_LABEL_RIGHT",
  FORM_LABEL_CENTER = "FORM_LABEL_CENTER",
  FORM_LABEL_ACCEPTED = "FORM_LABEL_ACCEPTED",
  FORM_LABEL_PAID = "FORM_LABEL_PAID",
  FORM_LABEL_WAITING = "FORM_LABEL_WAITING",
  FORM_LABEL_STANDARD = "FORM_LABEL_STANDARD",
  FROM_LABEL_JOB_CENTER = "FROM_LABEL_JOB_CENTER",

  LABEL_HOME = "LABEL_HOME",
  LABEL_LOGIN = "LABEL_LOGIN",
  LABEL_FORGOT_PASSWORD = "LABEL_FORGOT_PASSWORD",
  LABEL_RECOVER = "LABEL_RECOVER",
  LABEL_CHANGE_PASSWORD = "LABEL_CHANGE_PASSWORD",
  LABEL_BACK_TO_LOGIN = "LABEL_BACK_TO_LOGIN",
  LABEL_SETTINGS = "LABEL_SETTINGS",
  LABEL_CHANGE_ACCOUNT = "LABEL_CHANGE_ACCOUNT",
  LABEL_ADMIN_SPACE = "LABEL_ADMIN_SPACE",
  LABEL_TCM_SPACE = "LABEL_TCM_SPACE",
  LABEL_TRAINER_SPACE = "LABEL_TRAINER_SPACE",
  LABEL_STUDENT_SPACE = "LABEL_STUDENT_SPACE",
  LABEL_CHANGE_LANGUAGE = "LABEL_CHANGE_LANGUAGE",
  LABEL_LANGUAGE_ENGLISH = "LABEL_LANGUAGE_ENGLISH",
  LABEL_LANGUAGE_FRENCH = "LABEL_LANGUAGE_FRENCH",
  LABEL_BRAND_DESCRIPTION = "LABEL_BRAND_DESCRIPTION",
  LABEL_BRAND_NAME = "LABEL_BRAND_NAME",
  LABEL_GO_BACK = "LABEL_GO_BACK",
  LABEL_RELOAD = "LABEL_RELOAD",
  LABEL_LOGOUT = "LABEL_LOGOUT",
  LABEL_SAVE = "LABEL_SAVE",
  LABEL_ACTIVATE_ACCOUNT = "LABEL_ACTIVATE_ACCOUNT",
  LABEL_GREETING = "LABEL_GREETING",
  LABEL_GOODBYE = "LABEL_GOODBYE",
  LABEL_FETCH_SUCCESS = "LABEL_FETCH_SUCCESS",
  LABEL_FETCH_FAILURE = "LABEL_FETCH_FAILURE",
  LABEL_ADMIN = "LABEL_ADMIN",
  LABEL_STUDENT = "LABEL_STUDENT",
  LABEL_TCM = "LABEL_TCM",
  LABEL_TRAINER = "LABEL_TRAINER",
  LABEL_ENTERPRISE = "LABEL_ENTERPRISE",
  LABEL_NOTIFICATIONS = "LABEL_NOTIFICATIONS",
  LABEL_TABLE_VIEW = "LABEL_TABLE_VIEW",
  LABEL_GRID_VIEW = "LABEL_GRID_VIEW",
  LABEL_FILTER = "LABEL_FILTER",
  LABEL_ADD = "LABEL_ADD",
  LABEL_NO_DATA_FOUND = "LABEL_NO_DATA_FOUND",
  LABEL_APPLY = "LABEL_APPLY",
  LABEL_PER_PAGE = "LABEL_PER_PAGE",
  LABEL_NO_OPTION_AVAILABLE = "LABEL_NO_OPTION_AVAILABLE",
  LABEL_CLOSE = "LABEL_CLOSE",
  LABEL_VIEW = "LABEL_VIEW",
  LABEL_EDIT = "LABEL_EDIT",
  LABEL_DELETE = "LABEL_DELETE",
  LABEL_ARCHIVE = "LABEL_ARCHIVE",
  LABEL_UNARCHIVE = "LABEL_UNARCHIVE",
  LABEL_ACTIONS = "LABEL_ACTIONS",
  LABEL_LINK = "LABEL_LINK",
  LABEL_MALE = "LABEL_MALE",
  LABEL_FEMALE = "LABEL_FEMALE",
  LABEL_OTHER = "LABEL_OTHER",
  LABEL_MANAGE = "LABEL_MANAGE",
  LABEL_ALL = "LABEL_ALL",
  LABEL_ROW = "LABEL_ROW",
  LABEL_ROWS = "LABEL_ROWS",
  LABEL_OF_TOTAL = "LABEL_OF_TOTAL",
  LABEL_SKILLS = "LABEL_SKILLS",
  LABEL_SKILLS_BLOCKS = "LABEL_SKILLS_BLOCKS",
  LABEL_DISCOUNTS = "LABEL_DISCOUNTS",
  LABEL_TVA = "LABEL_TVA",
  LABEL_MODULES_BLOCKS = "LABEL_MODULES_BLOCKS",
  LABEL_INTERNAL_TEAM_STAFF = "LABEL_INTERNAL_TEAM_STAFF",
  LABEL_INTERNAL_TEAM_JOBS = "LABEL_INTERNAL_TEAM_JOBS",
  LABEL_INTERNAL_TEAM_SERVICES = "LABEL_INTERNAL_TEAM_SERVICES",
  LABEL_HANDICAPS_CONTACTS = "LABEL_HANDICAPS_CONTACTS",
  LABEL_HANDICAPS_PARTNERS = "LABEL_HANDICAPS_PARTNERS",
  LABEL_HANDICAPS_INTERLOCUTORS = "LABEL_HANDICAPS_INTERLOCUTORS",
  LABEL_MATERIALS_ROOMS = "LABEL_MATERIALS_ROOMS",
  LABEL_MATERIALS_EQUIPMENT = "LABEL_MATERIALS_EQUIPMENT",
  LABEL_MATERIALS_VIRTUAL_ROOMS = "LABEL_MATERIALS_VIRTUAL_ROOMS",
  LABEL_LOADING = "LABEL_LOADING",
  LABEL_HOLIDAYS = "LABEL_HOLIDAYS",
  LABEL_WEEKEND = "LABEL_WEEKEND",
  LABEL_EXCEPTIONS = "LABEL_EXCEPTIONS",
  LABEL_SEND = "LABEL_SEND",
  LABEL_SENT = "LABEL_SENT",
  LABEL_UPLOAD = "LABEL_UPLOAD",
  LABEL_SWITCH = "LABEL_SWITCH",
  LABEL_INVOICES = "LABEL_INVOICES",
  LABEL_PAST = "LABEL_PAST",
  LABEL_UPCOMING = "LABEL_UPCOMING",

  TITLE_PREFIX_OKALEE = "TITLE_PREFIX_OKALEE",
  TITLE_ACCESS_DENIED = "TITLE_ACCESS_DENIED",
  TITLE_NOT_FOUND = "TITLE_NOT_FOUND",
  TITLE_SERVER_ERROR = "TITLE_SERVER_ERROR",
  TITLE_LOGIN = "TITLE_LOGIN",
  TITLE_DASHBOARD = "TITLE_DASHBOARD",
  TITLE_TRAINING_CENTERS = "TITLE_TRAINING_CENTERS",
  TITLE_COMPANIES = "TITLE_COMPANIES",
  TITLE_TCM = "TITLE_TCM",
  TITLE_STUDENTS = "TITLE_STUDENTS",
  TITLE_TRAINERS = "TITLE_TRAINERS",
  TITLE_ENTERPRISES = "TITLE_ENTERPRISES",
  TITLE_MODULES = "TITLE_MODULES",
  TITLE_ED_PATHS = "TITLE_ED_PATHS",
  TITLE_SESSIONS = "TITLE_SESSIONS",
  TITLE_INTERNAL_TEAM = "TITLE_INTERNAL_TEAM",
  TITLE_EXTERNAL_TEAM = "TITLE_EXTERNAL_TEAM",
  TITLE_SETTINGS = "TITLE_SETTINGS",
  TITLE_PERFORMANCE_CRITERIA = "TITLE_PERFORMANCE_CRITERIA",
  TITLE_SKILLS = "TITLE_SKILLS",
  TITLE_INTERNS = "TITLE_INTERNS",
  TITLE_UNDER_CONSTRUCTION = "TITLE_UNDER_CONSTRUCTION",

  TEXT_UNDER_CONSTRUCTION = "TEXT_UNDER_CONSTRUCTION",
  TEXT_ACCESS_DENIED = "TEXT_ACCESS_DENIED",
  TEXT_NOT_FOUND = "TEXT_NOT_FOUND",
  TEXT_SERVER_ERROR = "TEXT_SERVER_ERROR",
  TEXT_RECOVER_HELPER_TEXT = "AUTH_RECOVER_HELPER_TEXT",
  TEXT_ACTIVATE_ACCOUNT = "TEXT_ACTIVATE_ACCOUNT",
  TEXT_POST_ACTIVATION = "TEXT_POST_ACTIVATION",
  TEXT_POST_RESET_PASSWORD = "TEXT_POST_RESET_PASSWORD",
  TEXT_CONFIRM_DELETE = "TEXT_CONFIRM_DELETE",
  TEXT_CONFIRM_ARCHIVE = "TEXT_CONFIRM_ARCHIVE",
  TEXT_CONFIRM_UNARCHIVE = "TEXT_CONFIRM_UNARCHIVE",
  TEXT_THIS_USER = "TEXT_THIS_USER",
  TEXT_DROP_FILES_HERE = "TEXT_DROP_FILES_HERE",

  MENU_HEADER_USERS_MANAGEMENT = "MENU_HEADER_USERS_MANAGEMENT",
  MENU_HEADER_GENERAL = "MENU_HEADER_PRIMARY",
  MENU_HEADER_TEAMS = "MENU_HEADER_TEAMS",
  MENU_HEADER_QUALITY = "MENU_HEADER_QUALITY",
  MENU_HEADER_TRAINING = "MENU_HEADER_TRAINING",
  MENU_HEADER_SETTINGS = "MENU_HEADER_SETTINGS",


  MENU_ITEM_TRAINERS = "MENU_ITEM_TRAINERS",
  MENU_ITEM_STUDENTS = "MENU_ITEM_STUDENTS",
  MENU_ITEM_TCM = "MENU_ITEM_TCM",
  MENU_ITEM_ENTERPRISE = "MENU_ITEM_ENTERPRISE",
  MENU_ITEM_DASHBOARD = "MENU_ITEM_DASHBOARD",
  MENU_HEADER_CENTERS_MANAGEMENT = "MENU_HEADER_CENTERS_MANAGEMENT",
  MENU_ITEM_COMPANIES = "MENU_ITEM_COMPANIES",
  MENU_ITEM_TRAINING_CENTERS = "MENU_ITEM_TRAINING_CENTERS",
  MENU_ITEM_MODULES = "MENU_ITEM_MODULES",
  MENU_ITEM_SESSIONS = "MENU_ITEM_SESSIONS",
  MENU_ITEM_LEARNERS = "MENU_ITEM_LEARNERS",
  MENU_ITEM_PEOPLE = "MENU_ITEM_PEOPLE",
  MENU_ITEM_EXTERNAL_TEAM = "MENU_ITEM_EXTERNAL_TEAM",
  MENU_ITEM_INTERNAL_TEAM = "MENU_ITEM_INTERNAL_TEAM",
  MENU_ITEM_JOB_SHEET = "MENU_ITEM_JOB_SHEET",
  MENU_ITEM_FEEDBACK = "MENU_ITEM_FEEDBACK",
  MENU_ITEM_DEV_PROSPECTS = "MENU_ITEM_DEV_PROSPECTS",
  MENU_ITEM_WATCH = "MENU_ITEM_WATCH",
  MENU_ITEM_HANDICAP = "MENU_ITEM_HANDICAP",
  MENU_ITEM_MATERIALS = "MENU_ITEM_MATERIALS",
  MENU_ITEM_ED_PATHS = "MENU_ITEM_ED_PATHS",
  MENU_ITEM_GENERAL_SETTINGS = "MENU_ITEM_GENERAL_SETTINGS",
  MENU_ITEM_PRODUCTS = "MENU_ITEM_PRODUCTS",
  MENU_ITEM_MODULE_BLOCK = "MENU_ITEM_MODULE_BLOCK",
  MENU_ITEM_DISCOUNTS = "MENU_ITEM_DISCOUNTS",
  MENU_ITEM_TVA = "MENU_ITEM_TVA",
  MENU_ITEM_MODULES_SKILLS = "MENU_ITEM_MODULES_SKILLS",
  MENU_ITEM_MODULES_SKILL_BLOCKS = "MENU_ITEM_MODULES_SKILL_BLOCKS",
  MENU_ITEM_SIMPLE_PRODUCTS = "MENU_ITEM_SIMPLE_PRODUCTS",
  MENU_ITEM_COMPOSED_PRODUCTS = "MENU_ITEM_COMPOSED_PRODUCTS",
  MENU_ITEM_QUIZZES = "MENU_ITEM_QUIZZES",
  MENU_ITEM_DOCUMENTS = "MENU_ITEM_DOCUMENTS",
  MENU_ITEM_TRAINING_CENTER = "MENU_ITEM_TRAINING_CENTER",
  MENU_ITEM_SESSION_FILES_TYPES = "MENU_ITEM_SESSION_FILES_TYPES",
  MENU_ITEM_GENERATED_FILES = "MENU_ITEM_GENERATED_FILES",
  MENU_ITEM_PLANNING_AND_HOLIDAYS = "MENU_ITEM_PLANNING_AND_HOLIDAYS",
  MENU_ITEM_QUESTIONS_BANK = "MENU_ITEM_QUESTIONS_BANK",
  MENU_ITEM_SATISFACTION = "MENU_ITEM_SATISFACTION",
  MENU_ITEM_INTERVIEW = "MENU_ITEM_INTERVIEW",
  MENU_ITEM_GENERAL_INFORMATION = "MENU_ITEM_GENERAL_INFORMATION",
  MENU_ITEM_MODULE_SHEET = "MENU_ITEM_MODULE_SHEET",
  MENU_ITEM_PEDAGOGICAL_SENARIO = "MENU_ITEM_PEDAGOGICAL_SENARIO",
  MENU_ITEM_PEDAGOGICAL_SUPPORT = "MENU_ITEM_PEDAGOGICAL_SUPPORT",
  MENU_ITEM_PROCEDURES = "MENU_ITEM_PROCEDURES",
  MENU_ITEM_POSITIONING = "MENU_ITEM_POSITIONING",
  MENU_ITEM_TRAINING = "MENU_ITEM_TRAINING",
  MENU_ITEM_TRACK = "MENU_ITEM_TRACK",
  MENU_ITEM_FOLDERS = "MENU_ITEM_FOLDERS",
  MENU_ITEM_REQUIRED_FILES = "MENU_ITEM_REQUIRED_FILES",
  MENU_ITEM_TASKS = "MENU_ITEM_TASKS",
  MENU_ITEM_INTERVENTIONS = "MENU_ITEM_INTERVENTIONS"

}