import { Entity, Folder, Session } from 'src/model/model';
import { EventRoom } from '../Planning/EventRoom';

export class Group extends Entity {
  label: string = '';

  folders: Folder[] = [];

  session: Session;

  eventRooms: EventRoom[];

  constructor(data: Partial<Group> = null, clone: boolean = false) {
    if (!clone && data instanceof Group) return data;
    super(data);
    this.label = data?.label;
    this.session = data?.session ? new Session(data.session) : null;
    this.folders =
      data?.folders?.map((folder: Folder) => new Folder(folder)) ?? [];
    this.eventRooms =
      data?.eventRooms?.map(
        (eventRoom: EventRoom) => new EventRoom(eventRoom)
      ) ?? [];
  }
}
