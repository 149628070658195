import Form, { FormElement } from 'src/tools/form/core/Form';
import { InputType } from 'src/tools/form/types/InputType';
import {Address} from 'src/model/model';
import  { SubFormBuilder } from '../../FormBuilder/FormBuilder';

import { InputProps } from '../InputBuilder';

export default function AddressInput({
  controlProps,
  formField,
  control
}: InputProps) {
  const handleChange = (values: Address) => {
    controlProps.field.onChange(values);
    formField.onChange?.(values);
  };

  const prefix = formField.subFormNamePrefix ?? 'address';

  const addressFormFields: FormElement[] = [
    {
      columns: [
        {
          name: `${prefix}.street`,
          label: 'street',
          type: InputType.Text,
          viewWidth: { xs: 6 }
        },
        {
          name: `${prefix}.zipCode`,
          label: 'zipCode',
          type: InputType.Text,
          viewWidth: { xs: 6 }
        }
      ]
    },
    {
      columns: [
        {
          name: `${prefix}.country`,
          label: 'country',
          type: InputType.Text,
          viewWidth: { xs: 6 }
        },
        {
          name: `${prefix}.city`,
          label: 'city',
          type: InputType.Text,
          viewWidth: { xs: 6 }
        }
      ]
    },
  ];

  return (
    <SubFormBuilder
      control={control}
      form={
        new Form<Address>({
          fields: addressFormFields,
          handleSubmit: handleChange,
          initialValues: controlProps.field.value ?? new Address()
        })
      }
    />
  );
}
