import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

type GuestProps = {
  children: ReactNode;
};

export default function Guest({ children }: GuestProps) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}
