import { InvoiceStatus } from "src/misc/enums/File/InvoiceStatus";
import { Entity, Trainer, Upload } from "../model";
import { TrainerPurchaseOrder } from "./TrainerPurchaseOrder";

export class TrainerInvoice extends Entity {

    upload: Upload | File;

    trainer: Trainer | null;

    status: InvoiceStatus = InvoiceStatus.Pending;

    purchaseOrder: TrainerPurchaseOrder | null;

    constructor(data: Partial<TrainerInvoice> = null, clone: boolean = false) {
        if (!clone && data instanceof TrainerInvoice) return data;
        super(data);

        this.trainer = data?.trainer ? new Trainer(data.trainer) : null;

        this.purchaseOrder = data?.purchaseOrder ? new TrainerPurchaseOrder(data.purchaseOrder) : null;

        this.status = data?.status ?? 0;

        this.upload = data?.upload ? new Upload(data.upload as Upload) : null;

    }
}