import { Option } from "src/tools/form/types/Option";

export enum TariffMode {
    ProductSum = 1,
    FixedPrice = 2,
    HoursSum = 3,
}

export const tariffModes: Option[] = [
    {
        id: TariffMode.ProductSum,
        label: "Somme des tarifs des articles"
    },
    {
        id: TariffMode.HoursSum,
        label: "Tarif par heure"
    },
    {
        id: TariffMode.FixedPrice,
        label: "Prix fixe"
    },
]

export async function getTariffModesOptions() {
    return tariffModes
}