import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from 'src/hooks/useIsMounted';
import { Option } from 'src/tools/form/types/Option';
import { InputProps } from '../InputBuilder';

export default function RadioGroupInput({
  controlProps,
  formField
}: InputProps) {
  const [options, setOptions] = useState<Option[]>([]);
  const { t } = useTranslation();

  const error = controlProps.fieldState.error;
  const isMounted = useIsMounted();

  useEffect(() => {
    (async function loadOptions() {
      const loadedOptions = await formField.options();
      if (isMounted()) {
        const result = loadedOptions.map((option) => {
          if (typeof option.label === 'string')
            option.label = t(option.label);
          return option;
        });
        setOptions(result);
      }
    })();
  }, []);

  return (
    <FormControl error={!!error} sx={{ width: "100%"}}>
      <FormLabel component="legend">{t(formField.label)}</FormLabel>
      <RadioGroup
        {...(!formField.extras?.columnDirection ? { row: true } : {})}
        sx={
          formField.extras?.spaced
            ? { display: 'flex', justifyContent: 'space-between'}
            : {}
        }
        value={controlProps.field.value}
        name={formField.name}
        onChange={(e, v) => {
          controlProps.field.onChange(+v);
          formField.onChange?.(v);
        }}
      >
        {options.map((option: Option) => {
          return (
            <FormControlLabel
              value={option.id}
              control={<Radio />}
              key={option.id}
              style={{ marginRight: '2rem' }}
              label={option.label}
            />
          );
        })}
      </RadioGroup>
      <FormHelperText>{!!error ? error.message : ''}</FormHelperText>
    </FormControl>
  );
}
