import FormItemFactory from 'src/misc/factories/FormItemFactory';
import Column from 'src/tools/form/core/Column';
import FormField from 'src/tools/form/core/FormField';
import Row from 'src/tools/form/core/Row';

export default class FormItemsHelper {
  public static getFieldsFromRows(rows: Row[]): FormField[] {
    const fields: FormField[] = [];
    let nestedRowFields: FormField[] = [];

    rows.forEach((row: Row) => {
      row.columns.forEach((column: FormField | Column) => {
        if (FormField.isFormField(column)) {
          fields.push(column);
        } else {
          if (FormField.isFormField(column.content)) {
            fields.push(column.content);
          } else {
            nestedRowFields = FormItemsHelper.getFieldsFromRows([
              column.content
            ]);
          }
        }
      });
    });
    return [...fields, ...nestedRowFields];
  }

  public static createRows(formElements: (Row | FormField)[]): Row[] {
    const rows: Row[] = [];

    formElements.forEach((formElement: Row | FormField) => {
      if (FormField.isFormField(formElement)) {
        const formField = FormItemFactory.create(formElement) as FormField;
        const column = FormItemFactory.create({
          viewWidth: formField.viewWidth,
          content: formField
        }) as Column;
        rows.push(FormItemFactory.create({ columns: [column] }) as Row);
      } else {
        rows.push(FormItemFactory.create(formElement) as Row);
      }
    });
    return rows;
  }
}
