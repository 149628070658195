import { ReactNode } from 'react';
import useAuth from 'src/hooks/useAuth';
import { UserRoles } from 'src/misc/enums/Users/UserRoles';
import AccessDenied from 'src/pages/Status/AccessDenied';

type GrantedProps = {
  roles: UserRoles[];
  children: ReactNode;
};

export default function Granted({ children, roles }: GrantedProps) {
  const { user } = useAuth();

  if (!user.hasRoles(roles) && !user.hasRoles([UserRoles.ROLE_DEV])) {
    return <AccessDenied />;
  }

  return <>{children}</>;
}
