import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { ProductSheet } from 'src/model/model';

interface ProductSheetState {
  productSheet: ProductSheet;
}

const initialState: ProductSheetState = {
  productSheet: null
};

const slice = createSlice({
  name: 'product-sheet',
  initialState,
  reducers: {
    setProductSheet(
      state: ProductSheetState,
      action: PayloadAction<{ productSheet: ProductSheet }>
    ) {
      state.productSheet = action.payload.productSheet;
    }
  }
});

export const reducer = slice.reducer;

export const dispatchProductSheet =
  (productSheet: ProductSheet): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.setProductSheet({
        productSheet: productSheet ? new ProductSheet(productSheet) : null
      })
    );
  };

export default slice;
