import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  useTheme
} from '@mui/material';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'src/store';
import Avatar from '../Avatar/Avatar';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { Folder } from 'src/model/model';
import { dispatchConnectedFolder } from 'src/slices/externalPersonFolders';

export default function FoldersPicker() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const foldersState = useSelector((state) => state.externalPersonFolders);
  const connectedFolder = foldersState.connectedFolder;
  const otherFolders = foldersState.folders.filter(
    (folder) => folder.id !== connectedFolder?.id
  );
  const thereIsOtherFolders = !!otherFolders?.length;

  const handleOpen = (): void => setOpen(true);

  const handleClose = (): void => setOpen(false);

  const switchFolder = (folderId: number) => {
    dispatch(dispatchConnectedFolder(folderId));
    handleClose();
  };

  if (!connectedFolder)
    return (
      <Box alignItems={'center'} justifyContent="center" pt={1.5}>
        aucun dossier trouvé
      </Box>
    );

  return (
    <>
      <Button
        sx={{ p: theme.spacing(0, 0.5), height: theme.spacing(6) }}
        color="secondary"
        ref={ref}
        onClick={thereIsOtherFolders ? handleOpen : null}
      >
        <Avatar
          alt={foldersState.connectedFolder?.session?.name}
          sx={{ mt: -0.5 }}
        />
        <Box
          component="span"
          sx={{ display: { xs: 'none', md: 'inline-block' } }}
        >
          <Typography
            variant="body1"
            sx={{ color: 'white', pl: theme.spacing(1), mt: -1 }}
          >
            {foldersState.connectedFolder?.session?.name}
          </Typography>
        </Box>
        {thereIsOtherFolders && (
          <Box
            component="span"
            sx={{ display: { xs: 'none', sm: 'inline-block' } }}
          >
            <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
          </Box>
        )}
      </Button>
      {thereIsOtherFolders && (
        <Popover
          disableScrollLock
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box
            sx={{
              minWidth: 210,
              background: theme.colors.alpha.black[5],
              padding: theme.spacing(2)
            }}
            display="flex"
          >
            <Avatar alt={foldersState.connectedFolder?.session?.name} />
            <Box
              component="span"
              sx={{ display: { xs: 'none', md: 'inline-block' } }}
            >
              <Typography variant="body1">
                {foldersState.connectedFolder?.session?.name}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ mb: 0 }} />
          <List sx={{ p: 1 }} component="nav">
            {otherFolders.map((folder: Folder, index: number) => {
              return (
                <ListItem
                  key={index}
                  onClick={() => switchFolder(folder.id)}
                  button
                >
                  <ListItemText primary={folder.session.name} />
                </ListItem>
              );
            })}
          </List>
        </Popover>
      )}
    </>
  );
}
