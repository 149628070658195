import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { CalendarApi } from '@fullcalendar/react';
import dayjs, { Dayjs } from 'dayjs';
import EventHelper from 'src/tools/calendar/helpers/EventHelper';
import { CalendarView } from 'src/tools/calendar/types/CalendarView';
import { Event, Planning } from 'src/model/model';

export type SelectedRange = {
  start: Dayjs;
  end: Dayjs;
};

type CalendarState = {
  // bug with redux reducer that's why i used any type
  api: CalendarApi | any;
  drawerOpen: boolean;
  selectedEvent: Event | null;
  selectedRange: SelectedRange | null;
  events: Event[];
  date: Dayjs;
  view: CalendarView;
  planning: Planning;
};

const initialState: CalendarState = {
  api: null,
  drawerOpen: false,
  selectedEvent: null,
  selectedRange: null,
  events: [],
  date: dayjs(),
  view: CalendarView.DayGridMonth,
  planning: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setApi: (
      state: CalendarState,
      action: PayloadAction<{ api: CalendarApi | null }>
    ) => {
      state.api = action.payload.api;
    },
    openDrawer(state: CalendarState) {
      state.drawerOpen = true;
    },
    closeDrawer(state: CalendarState) {
      state.drawerOpen = false;
      state.selectedRange = null;
      state.selectedEvent = null;
    },
    setSelectedEvent(
      state: CalendarState,
      action: PayloadAction<{ eventId: number }>
    ) {
      state.selectedEvent = EventHelper.findEvent(
        state.events,
        action.payload.eventId
      );
      state.drawerOpen = !!state.selectedEvent;
    },
    setEvents(
      state: CalendarState,
      action: PayloadAction<{ events: Event[] }>
    ) {
      state.events = action.payload.events;
    },
    setSelectedRange(
      state: CalendarState,
      action: PayloadAction<{ range: SelectedRange }>
    ) {
      state.api?.unselect();
      state.selectedRange = action.payload.range;
      state.drawerOpen = !!action.payload.range;
    },
    setDayToday(state: CalendarState) {
      state.api.today();
      state.date = state.api.getDate();
    },
    setDayNext(state: CalendarState) {
      state.api.next();
      state.date = state.api.getDate();
    },
    setDayPrevious(state: CalendarState) {
      state.api.prev();
      state.date = state.api.getDate();
    },
    setView(
      state: CalendarState,
      action: PayloadAction<{ changedView: CalendarView }>
    ) {
      state.api.changeView(action.payload.changedView);
      state.view = action.payload.changedView;
    },
    setPlanning(
      state: CalendarState,
      action: PayloadAction<{ planning: Planning }>
    ) {
      state.planning = action.payload.planning;
      if (action.payload.planning) {
        state.events = action.payload.planning.events;
      }
      if (state.selectedEvent) {
        state.selectedEvent = state.events.find(event => event.id === state.selectedEvent.id);

        if (!state.selectedEvent) {
          state.drawerOpen = false;
        }
      }
    }
  }
});

export const reducer = slice.reducer;

export const dispatchCalendarApi =
  (api: CalendarApi): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setApi({ api }));
  };

export const dispatchCloseDrawer = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeDrawer());
};

export const dispatchOpenDrawer = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openDrawer());
};

export const dispatchSelectedEvent =
  (eventId: number): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSelectedEvent({ eventId }));
  };

export const dispatchEvents =
  (events: Event[]): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setEvents({ events }));
  };

export const dispatchSelectedRange =
  (range: SelectedRange): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSelectedRange({ range }));
  };

export const dispatchDayNext = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setDayNext());
};

export const dispatchDayPrevious = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setDayPrevious());
};

export const dispatchDayToday = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setDayToday());
};

export const dispatchView =
  (changedView: CalendarView): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setView({ changedView }));
  };

export const dispatchPlanning =
  (planning: Planning): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setPlanning({ planning }));
  };

export default slice;
