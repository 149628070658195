import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { Option } from 'src/tools/form/types/Option';

export enum InvoiceStatus {
    Pending = 0,
    ACCEPTED,
    REFUSED,
    PAID
}
export const invoiceStatusAsOptions: Option[] = [
    {
        id: InvoiceStatus.Pending,
        label: I18nKeys.FORM_LABEL_WAITING
    },
    {
        id: InvoiceStatus.ACCEPTED,
        label: I18nKeys.FORM_LABEL_ACCEPTED
    },
    {
        id: InvoiceStatus.REFUSED,
        label: I18nKeys.FORM_LABEL_REFUSED
    },
    {
        id: InvoiceStatus.PAID,
        label: I18nKeys.FORM_LABEL_PAID
    },

]

export function getInvoiceStatusLabel(value: InvoiceStatus): string {

    const res = invoiceStatusAsOptions.filter((option: Option) => option.id === value)
    return res[0]?.label as string;
}