import {
  Entity,
  Folder,
  Quiz,
  SessionItem,
  User,
  UserQuestionAnswer
} from 'src/model/model';

export class UserQuizAnswer extends Entity {
  user: User;

  folder: Folder;

  quiz: Quiz;

  sessionItem: SessionItem;

  userQuestionAnswers: UserQuestionAnswer[];

  constructor(data: Partial<UserQuizAnswer> = null, clone: boolean = false) {
    if (!clone && data instanceof UserQuizAnswer) return data;
    super(data);

    this.quiz = data?.quiz ? new Quiz(data?.quiz) : null;
    this.folder = data?.folder ? new Folder(data?.folder) : null;
    this.user = data?.user ? new User(data?.user) : null;
    this.sessionItem = data?.sessionItem
      ? new SessionItem(data?.sessionItem)
      : null;
    this.userQuestionAnswers =
      data?.userQuestionAnswers?.map(
        (userQuestionAnswer: UserQuestionAnswer) =>
          new UserQuestionAnswer(userQuestionAnswer)
      ) ?? [];
  }
}
