import { Delete } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  CircularProgress,
  createFilterOptions,
  FilterOptionsState,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField
} from '@mui/material';
import React, {
  HTMLAttributes,
  SyntheticEvent,
  useEffect,
  useState
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { devMode } from 'src/api-path';
import useIsMounted from 'src/hooks/useIsMounted';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { Option } from 'src/tools/form/types/Option';
import { InputProps } from '../InputBuilder';

type AutocompleteRenderOptionParams = {
  deletable: boolean;
  handleDelete: (optionToDelete: Option) => Promise<any>;
};

export type AutocompleteRenderOptionProps = {
  props: HTMLAttributes<HTMLLIElement>;
  option: Option;
  params: AutocompleteRenderOptionParams;
};

export default function AutocompleteInput({
  controlProps,
  formField
}: InputProps) {
  const DEFAULT_OPTION = { id: null, label: '' };
  const [options, setOptions] = useState<Option[]>(
    formField.extras?.multiple ? [] : [DEFAULT_OPTION]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<number[]>(
    controlProps.field.value
  );
  const filter = createFilterOptions<Option>();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const methods = useFormContext();

  useEffect(() => {
    (async function loadOptions() {
      setIsLoading(true);
      const loadedOptions = await formField.options();
      if (isMounted()) {
        const result = [...options, ...loadedOptions].map((option) => {
          if (typeof option.label === 'string') option.label = t(option.label);
          return option;
        });
        setOptions(result);
        setIsLoading(false);
      }
    })();
  }, []);

  const error = controlProps.fieldState.error;
  const multiple = formField.extras?.multiple;
  const groupBy = formField.extras?.groupBy;
  const groupLabel = formField.extras?.groupLabel;
  const creatable = formField.extras?.creatable;
  const onCreate = formField.extras?.onCreate;
  const deletable = formField.extras?.deletable;
  const onDelete = formField.extras?.onDelete;

  const handleDelete = async (optiontoDelete: Option) => {
    try {
      await onDelete?.(optiontoDelete.id);
      const newOptions = options.filter(
        (option: Option) => option.id !== optiontoDelete.id
      );
      setOptions(newOptions);
    } catch (error: any) {
      if (devMode) console.error(error);
    }
  };

  const filterIds = (options: Option | Option[]): number[] | number => {
    if (!options) return null;
    if (Array.isArray(options)) return options.map((option) => option.id);
    return options.id;
  };

  const handleChange = async (
    event: SyntheticEvent<Element, Event>,
    newValue: Option | Option[]
  ) => {
    let optionToCreate: Option = null;
    let createdOption: Option = null;
    // check if there is an option to create
    if (Array.isArray(newValue)) {
      optionToCreate = newValue.find((option: Option) => option.isCreated);
    } else {
      optionToCreate = newValue?.isCreated ? newValue : null;
    }
    // if there is an option to create send the creation request and update the value
    if (optionToCreate && onCreate) {
      try {
        createdOption = await onCreate(optionToCreate);
        setOptions([...options, createdOption]);
        if (multiple) {
          const newSelectedOptions = [
            ...selectedOptions,
            filterIds(createdOption) as number
          ];
          setSelectedOptions(newSelectedOptions);
          triggerChange(newSelectedOptions);
          return;
        } else {
          triggerChange(filterIds(createdOption));
          return;
        }
      } catch (error: any) {
        if (devMode) console.error(error);
      }
    }
    // else there is no option to create update the new value
    const valueIds = filterIds(newValue);
    if (multiple && Array.isArray(newValue) && Array.isArray(valueIds)) {
      setSelectedOptions(valueIds);
    }
    triggerChange(valueIds);
  };

  const triggerChange = (value: any) => {
    controlProps.field.onChange(value);
    formField.onChange?.(value);
    formField.extras?.trigger?.map(async (observe) => {
      let data = await observe.getValue(value);
      methods.setValue(observe.name, data);
    });
  };

  const getOptionLabel = (option: any): string => (option ? option.label : '');

  const isOptionEqualToValue = (option: any, value: any): boolean => {
    return option.id === value.id;
  };

  const filterOptions = (options: any[], params: FilterOptionsState<any>) => {
    const filtered = filter(options, params);
    if (params.inputValue !== '' && formField.extras?.creatable) {
      filtered.push({
        id: Math.random(),
        label: params.inputValue,
        isCreated: true
      });
    }
    return filtered;
  };

  const getOptionsFromValues = (
    values: number | number[]
  ): Option | Option[] => {
    let option: Option = null;
    if (Array.isArray(values) && formField.extras?.multiple) {
      const output = [];
      values.forEach((value) => {
        option = options.find((option) => option.id === value);
        if (typeof option !== 'undefined') {
          output.push(option);
        }
      });
      return output;
    } else {
      const output = options.find((option) => option.id === values);
      if (typeof output === 'undefined') {
        return DEFAULT_OPTION;
      }
      return output;
    }
  };

  const transformValues = (values: any): number | number[] => {
    if (multiple && Array.isArray(values)) {
      return values.map((value) => {
        if (typeof value === 'number') return value;
        return value?.id;
      });
    } else {
      if (typeof values === 'number') return values;
      return values?.id ?? null;
    }
  };

  const value = options.length
    ? getOptionsFromValues(transformValues(controlProps.field.value))
    : formField.extras?.multiple
    ? []
    : '';

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: Option
  ) => {
    return (
      <ListItem {...props} key={Math.random() + props.id}>
        <ListItemText
          primary={
            option.isCreated
              ? `${t(I18nKeys.LABEL_ADD)} ${t(option.label)}`
              : t(option.label)
          }
        />
        <ListItemSecondaryAction>
          {option?.id && deletable && onDelete && (
            <IconButton
              onClick={async () => {
                await handleDelete(option);
              }}
            >
              <Delete color="error" fontSize="small" />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        error={!!error}
        helperText={!!error ? error.message : ''}
        label={t(formField.label)}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
              {params.InputProps.endAdornment}
            </>
          )
        }}
      />
    );
  };
  return (
    <Autocomplete
      freeSolo={creatable}
      handleHomeEndKeys
      multiple={multiple}
      filterSelectedOptions
      options={options}
      loading={isLoading}
      getOptionLabel={getOptionLabel}
      {...(groupBy ? { groupBy } : {})}
      {...(groupLabel
        ? {
            renderGroup: (params: AutocompleteRenderGroupParams) => (
              <React.Fragment key={params.key}>
                <li style={{ margin: '0.5rem 0' }}>
                  <div
                    style={{
                      marginLeft: '0.7rem',
                      color: 'rgba(34, 51, 84, 0.7)',
                      marginBottom: '0.5rem'
                    }}
                  >
                    {t(groupLabel?.(params.group) ?? params.group)}
                  </div>
                </li>
                {params.children}
              </React.Fragment>
            )
          }
        : {})}
      isOptionEqualToValue={isOptionEqualToValue}
      value={value}
      onChange={handleChange}
      filterOptions={filterOptions}
      noOptionsText={t(I18nKeys.LABEL_NO_OPTION_AVAILABLE)}
      loadingText={t(I18nKeys.LABEL_LOADING)}
      renderOption={
        formField.extras?.renderOption
          ? (props, option) =>
              formField.extras?.renderOption({
                props,
                option,
                params: {
                  deletable: deletable && !!onDelete,
                  handleDelete
                }
              })
          : renderOption
      }
      renderInput={renderInput}
    />
  );
}
