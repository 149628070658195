import dayjs, { Dayjs } from 'dayjs';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { Gender } from 'src/misc/enums/Users/Gender';
import { UserRoles } from 'src/misc/enums/Users/UserRoles';
import { Entity, Upload, TrainingCenter, Address } from 'src/model/model';

export class User extends Entity {
  email: string;

  firstname: string;

  lastname: string;

  roles: UserRoles[];

  phone: string;

  bornAt: Dayjs | string;

  avatar: Upload | File;

  gender: Gender;

  isActive: boolean;

  comment: string;

  address: Address;

  rgpdSignature: File | Upload;

  rgpdSignedAt: Dayjs | string;

  constructor(data: Partial<User> = null, clone: boolean = false) {
    if (!clone && data instanceof User) return data;
    super(data);

    this.email = data?.email ?? '';
    this.firstname = data?.firstname ?? '';
    this.lastname = data?.lastname ?? '';
    this.roles = data?.roles ?? [];
    this.phone = data?.phone ?? '';
    this.avatar = new Upload(data?.avatar as Upload);
    this.bornAt = data?.bornAt ? dayjs(data?.bornAt) : null;
    this.gender = data?.gender ?? null;
    this.isActive = data?.isActive ?? false;
    this.comment = data?.comment ?? '';
    this.address = new Address(data?.address);
    this.rgpdSignature = data?.rgpdSignature
      ? new Upload(this.rgpdSignature)
      : null;
    this.rgpdSignedAt = data?.rgpdSignedAt ? dayjs(data.rgpdSignedAt) : null;
  }

  public getFullname(): string {
    return this.firstname + ' ' + this.lastname.toLocaleUpperCase();
  }

  public hasRole(roleToCheck: UserRoles): boolean {
    return !!this.roles.find((role) => role === roleToCheck);
  }

  public getUserRoleJob() {
    for (let i = 0; i < this.roles.length; i++) {
      switch (this.roles[i]) {
        case UserRoles.ROLE_ADMIN:
          return I18nKeys.LABEL_ADMIN;
        case UserRoles.ROLE_STUDENT:
          return I18nKeys.LABEL_STUDENT;
        case UserRoles.ROLE_EMPLOYEE:
          return I18nKeys.LABEL_TCM;
        case UserRoles.ROLE_TRAINER:
          return I18nKeys.LABEL_TRAINER;
        case UserRoles.ROLE_ENTERRPISE:
          return I18nKeys.LABEL_ENTERPRISE;
      }
    }
  }

  // check with logic OR between args and logic AND between same array
  public hasRoles(...rolesToCheck: UserRoles[][]): boolean {
    return rolesToCheck.some(
      (roles) => this.roles.filter((role) => roles.includes(role)).length !== 0
    );
  }

  public static isUser(object: any): object is User {
    if (object instanceof User) return true;

    return (
      // the minimum fields needed to prove it's a user
      typeof object.id === 'number' &&
      typeof object.email === 'string' &&
      typeof object.firstname === 'string' &&
      typeof object.lastname === 'string' &&
      Array.isArray(object.roles)
    );
  }
}
