export default class ApiResponse<T> {
  status: number;

  data: T;

  message: string;

  constructor(apiResponse: Partial<ApiResponse<T>>) {
    this.status = apiResponse.status;
    this.data = apiResponse.data;
    this.message = apiResponse.message;
  }

  static createSuccessApiResponse<T>(data: T) {
    return new ApiResponse<T>({
      status: 200,
      data,
      message: ''
    });
  }

  static createInternalErrorApiResponse<T>(data: T) {
    return new ApiResponse<T>({
      status: 500,
      data,
      message: ''
    });
  }

  isOk() {
    return this?.status && this.status > 199 && this.status < 300;
  }
}
