import { devMode } from 'src/api-path';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { HttpMethod } from 'src/misc/enums/Http/HttpMethods';
import { HttpStatus } from 'src/misc/enums/Http/HttpStatus';
import { LocalstorageKeys } from 'src/misc/enums/LocalstorageKeys';
import UnauthorizedError from 'src/misc/Errors/UnauthorizedError';
import ApiResponse from 'src/misc/types/ApiResponse';

type AdditionalHeaders = { [key: string]: string };

type FetcherArgs = {
  headers?: Headers;
  baseUrl?: string;
};

type FetcherOptions = {
  token?: string;
  headers?: AdditionalHeaders;
  no401Blocking?: boolean;
};

export default class Fetcher {
  public readonly headers: Headers;

  public readonly baseUrl: string;

  public constructor(fetcherArgs?: FetcherArgs) {
    this.headers = fetcherArgs?.headers ?? new Headers();
    this.baseUrl = fetcherArgs?.baseUrl ?? '';
  }

  public get<T>(
    url: string | URL,
    options: FetcherOptions = {}
  ): Promise<ApiResponse<T>> {
    return this.sendRequest<T>(url, HttpMethod.GET, null, options);
  }

  public post<T>(url: string | URL, body: any, options: FetcherOptions = {}) {
    return this.sendRequest<T>(url, HttpMethod.POST, body, options);
  }

  public put<T>(url: string | URL, body: any, options: FetcherOptions = {}) {
    return this.sendRequest<T>(url, HttpMethod.PUT, body, options);
  }

  public delete<T>(url: string | URL, options: FetcherOptions = {}) {
    return this.sendRequest<T>(url, HttpMethod.DELETE, null, options);
  }

  private async sendRequest<T>(
    url: string | URL,
    method: HttpMethod,
    body: any = null,
    options: FetcherOptions = {}
  ): Promise<ApiResponse<T>> {
    if (body && !(body instanceof FormData)) {
      body = JSON.stringify(body);
    }

    let response: Response;
    let apiResponse: ApiResponse<T>;

    try {
      response = await fetch(this.baseUrl + url, {
        method,
        headers: this.getRequestHeaders(body, options),
        ...(body ? { body } : {})
      });

      apiResponse = new ApiResponse<T>(await response.json());
    } catch (fetchError) {
      if (devMode) console.error(fetchError);
      throw new Error(I18nKeys.LABEL_FETCH_FAILURE);
    }

    if (
      !options?.no401Blocking &&
      apiResponse.status === HttpStatus.Unauthorized
    ) {
      throw new UnauthorizedError(apiResponse.message);
    }

    return apiResponse;
  }

  private getRequestHeaders(body: any, options: FetcherOptions = {}): any {
    const isFormData = body instanceof FormData;
    const requestHeaders = {};

    const token =
      options?.token ?? window.localStorage.getItem(LocalstorageKeys.TOKEN);

    if (!isFormData) requestHeaders['Content-Type'] = 'application/json';

    if (token) {
      requestHeaders['Authorization'] = `Bearer ${token}`;
    }

    requestHeaders['Accept'] = 'application/json; plain/text';

    this.headers.forEach(
      (headerValue: string, header: string) =>
        (requestHeaders[header] = headerValue)
    );
    Object.keys(options?.headers ?? {}).forEach(
      (header: string) => (requestHeaders[header] = options?.headers[header])
    );

    return requestHeaders;
  }
}
