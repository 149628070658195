import { Entity } from 'src/model/model';


export   class Category extends Entity{
  label: string;

  constructor(data: Partial<Category> = null, clone: boolean = false) {
    if (!clone && data instanceof Category) return data;
    super(data);

    this.label = data?.label ?? "";
  }
}
