import Column from './Column';
import FormField from './FormField';

export default class Row {
  readonly id?: number;

  label?: string | JSX.Element

  columns: (FormField | Column)[];

  constructor(label: string | JSX.Element, columns: (FormField | Column)[]) {
    this.id = Math.random();
    this.label = label;
    this.columns = columns;
  }

  static isRow(object: any): object is Row {
    if (object instanceof Row) return true;

    let test = object.columns && typeof object.viewWidth === 'undefined';
    if (!test || !object.columns.length) return !!test;

    for (let i = 0; i < object.columns.length; i++) {
      if (!test) break;
      test =
        test &&
        (FormField.isFormField(object.columns[i]) ||
          Column.isColumn(object.columns[i]));
    }
    return !!test;
  }
}
