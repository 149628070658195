import { Paper, Stack, styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import ForgotPasswordForm from '../ForgotPassword/Form/ForgotPasswordForm';
import LoginForm from './Form/LoginForm';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../shared/AuthLayout';
import Title from 'src/components/Title/Title';

export enum LoginPageFlags {
  LOGIN = 'LOGIN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD'
}

const FormSide = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 5,

  background: '#242659',

  [theme.breakpoints.down('lg')]: {
    flex: 1
  }
}));

const FormHolder = styled(Paper)(({ theme }) => ({
  margin: '2rem'
}));

const BrandName = styled(Typography)(({ theme }) => ({
  fontSize: '4rem',
  fontWeight: 300,
  animation: `fade-down 1s ${theme.transitions.easing.easeIn}`
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: '4rem',
  fontWeight: 300,
  animation: `fade-down 1.5s ${theme.transitions.easing.easeIn}`
}));

export default function LoginPage() {
  const [mode, setMode] = useState<LoginPageFlags>(LoginPageFlags.LOGIN);
  const [isRecoveryMailSent, setIsRecoveryMailSent] = useState<boolean>(false);
  const loginMode = mode === LoginPageFlags.LOGIN;
  const { t } = useTranslation();

  return (
    <>
      <Title title={t(I18nKeys.TITLE_LOGIN)} />

      <Stack direction={'row'} height="100vh">
        <FormSide>
          <FormHolder>
            {loginMode && (
              <LoginForm
                setMode={setMode}
                isRecoveryMailSent={isRecoveryMailSent}
              />
            )}
            {!loginMode && (
              <ForgotPasswordForm
                setMode={setMode}
                setIsRecoveryMailSent={setIsRecoveryMailSent}
              />
            )}
          </FormHolder>
        </FormSide>
        <AuthLayout imageOnSide>
          <Stack>
            <BrandName variant="h1">{t(I18nKeys.LABEL_BRAND_NAME)}</BrandName>
            <Description variant="h4">
              {t(I18nKeys.LABEL_BRAND_DESCRIPTION)}
            </Description>
          </Stack>
        </AuthLayout>
      </Stack>
    </>
  );
}
