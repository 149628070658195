import dayjs from 'dayjs';
import { DateTimeFormat } from 'src/misc/enums/DateTimeFormat';
import { LogicalOperator } from 'src/misc/enums/Paginator/LogicalOperator';
import { FeedbackStatus } from 'src/misc/enums/Quality/FeedbackStatus';
import {
  GetAllParams,
  PaginatorFilterParams
} from 'src/misc/types/Paginator/GetAllParams';
import Paginated from 'src/misc/types/Paginator/Paginated';
import { Feedback } from 'src/model/model';
import { fetcher } from 'src/utils/fetcher';
import { generateQueryParams } from 'src/utils/generateQueryParams';

const PREFIX = '/api/feedback';
export default class FeedbackApiFetcher {
  static async getAll(params: GetAllParams) {
    const queryParams: PaginatorFilterParams = {
      ...params,
      logicalOperator: LogicalOperator.OR,
      searchFields: ['type', 'status', 'inputContent', 'outputContent']
    };
    const response = await fetcher.get<Paginated<Feedback[]>>(
      PREFIX + generateQueryParams(queryParams)
    );
    response.data.data = response.data.data.map(
      (feedback: Feedback) => new Feedback(feedback)
    );
    return response;
  }

  static async delete(feedback: Feedback) {
    const response = await fetcher.delete<Feedback>(`${PREFIX}/${feedback.id}`);
    return response;
  }

  static update(feedback: Feedback) {
    if (feedback.status === FeedbackStatus.Done) {
      feedback.processedAt = dayjs().format(
        DateTimeFormat.DefaultDateFormatToAPI
      );
    }
    return fetcher.put<Feedback>(`${PREFIX}/${feedback.id}`, feedback);
  }

  static async create(feedback: Feedback) {
    feedback.requestedAt = dayjs().format(
      DateTimeFormat.DefaultDateFormatToAPI
    );
    const response = await fetcher.post<Feedback>(`${PREFIX}`, feedback);
    response.data = new Feedback(response.data);
    return response;
  }
}
