import { studentMenuItems } from 'src/tools/menu/menus/mainMenu/studentMenuItems';
import { tcmMenuItems } from 'src/tools/menu/menus/mainMenu/tcmMenuItems';
import { trainerMenuItems } from 'src/tools/menu/menus/mainMenu/trainerMenuItems';
import { studentUserBoxMenuItems } from 'src/tools/menu/menus/userBoxMenuItems/studentUserBoxMenuItems';
import { tcmUserBoxMenuItems } from 'src/tools/menu/menus/userBoxMenuItems/tcmUserBoxMenuItems';
import { trainerUserBoxMenuItems } from 'src/tools/menu/menus/userBoxMenuItems/trainerUserBoxMenuItems';
import { User } from 'src/model/model';
import { inStudentSpace, inTcmSpace, inTrainerSpace } from 'src/utils/inSpace';
import MenuItems from './MenuBlock';
import { UserBoxMenuItem } from './MenuItem';

export default class Menu {
  user: User;

  public constructor(user: User) {
    this.user = user;
  }

  get mainMenuItems(): MenuItems[] {
    let menuItems: MenuItems[] = [];

    if (inTcmSpace()) {
      menuItems = tcmMenuItems;
    } else if (inStudentSpace()) {
      menuItems = studentMenuItems;
    } else if (inTrainerSpace()) {
      menuItems = trainerMenuItems;
    }

    menuItems = menuItems.filter(
      (menuItem: MenuItems) =>
        !menuItem.roles || this.user.hasRoles(menuItem.roles)
    );

    menuItems = menuItems.map((menuItem) => {
      menuItem.items = menuItem.items.filter(
        (item) => !item.roles || this.user.hasRoles(item.roles)
      );
      return menuItem;
    });

    return menuItems;
  }

  get userBoxMenuItems(): UserBoxMenuItem[] {
    let menuItems: UserBoxMenuItem[] = [];

    if (inTcmSpace()) {
      menuItems = tcmUserBoxMenuItems;
    } else if (inStudentSpace()) {
      menuItems = studentUserBoxMenuItems;
    } else if (inTrainerSpace()) {
      menuItems = trainerUserBoxMenuItems;
    }

    menuItems = menuItems.filter(
      (menuItem: UserBoxMenuItem) =>
        !menuItem.roles || this.user.hasRoles(menuItem.roles)
    );

    return menuItems;
  }
}
