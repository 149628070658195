import { Option } from '../../../tools/form/types/Option';

export enum InterventionState {
  Accepted = 1,
  Pending,
  Refused
}

export const interventionStates: Option[] = [
  {
    id: InterventionState.Accepted,
    label: 'Acceptée'
  },
  {
    id: InterventionState.Pending,
    label: 'En attente de réponse'
  },
  {
    id: InterventionState.Refused,
    label: 'Refusée'
  }
];

export function getInterventionStateLabel(
  interventionStateId: InterventionState
) {
  return interventionStates.find(
    (interventionState) => interventionState.id === Number(interventionStateId)
  ).label;
}

export const interventionStatesRecord: Record<
  InterventionState,
  {
    label: string;
    color:
      | 'default'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning';
  }
> = {
  [InterventionState.Accepted]: {
    label: getInterventionStateLabel(InterventionState.Accepted),
    color: 'success'
  },
  [InterventionState.Pending]: {
    label: getInterventionStateLabel(InterventionState.Pending),
    color: 'warning'
  },
  [InterventionState.Refused]: {
    label: getInterventionStateLabel(InterventionState.Refused),
    color: 'error'
  }
};
