import { Alert, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormBuilder from 'src/tools/form/components/FormBuilder/FormBuilder';
import { useHttp } from 'src/hooks/useHttp';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import Form from 'src/tools/form/core/Form';
import AutheticationApiFetcher from 'src/services/Authentication/Api/AuthenticationApiFercher';
import { LoginPageFlags } from '../../Login/LoginPage';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { forgotPasswordFormFields } from './forgotPassworfFormField';
import Logo from 'src/components/Logo/Logo';

type RecoverFormProps = {
  setMode: Dispatch<SetStateAction<LoginPageFlags>>;
  setIsRecoveryMailSent: Dispatch<SetStateAction<boolean>>;
};

export default function ForgotPassword({
  setMode,
  setIsRecoveryMailSent
}: RecoverFormProps) {
  const { sendRequest, isLoading, error, clearError } = useHttp();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (values: { email: string }) => {
    await sendRequest(() =>
      AutheticationApiFetcher.forgotPassword(values.email)
    );
    if (error) clearError();
    setIsSubmitted(true);
  };

  useEffect(() => {
    if (isSubmitted && !error) {
      setIsRecoveryMailSent(true);
      setMode(LoginPageFlags.LOGIN);
    }
  }, [error, isSubmitted]);

  useEffect(() => {
    if (error) setIsSubmitted(false);
  }, [error]);

  const form = new Form<{ email: string }>({
    fields: forgotPasswordFormFields,
    handleSubmit,
    initialValues: { email: '' }
  });

  return (
    <Stack gap={1} margin={1} marginBottom={2}>
      <Stack direction={'row'} display={'flex'} marginTop={1} marginRight={1}>
        <Tooltip title={t(I18nKeys.LABEL_BACK_TO_LOGIN)}>
          <IconButton onClick={() => setMode(LoginPageFlags.LOGIN)}>
            <ArrowBackIosNewIcon />
          </IconButton>
        </Tooltip>
        <Logo disableLink />
      </Stack>
      <Stack style={{ whiteSpace: 'pre-line' }}>
        <Typography variant="h3" marginLeft={'1.5rem'}>
          {t(I18nKeys.LABEL_FORGOT_PASSWORD)}
        </Typography>
        <Typography variant="h6" marginLeft={'1.5rem'}>
          {t(I18nKeys.TEXT_RECOVER_HELPER_TEXT)}
        </Typography>
        {error && (
          <Alert
            style={{ margin: '0.5rem 1.2rem' }}
            variant="standard"
            severity="error"
          >
            {t(error.message)}
          </Alert>
        )}
      </Stack>

      <FormBuilder
        form={form}
        isLoading={isLoading}
        submitButton={{
          text: t(I18nKeys.LABEL_RECOVER),
          style: { width: '100%' },
          icon: null
        }}
        extraContentProps={{ afterContentProps: { email: { setMode } } }}
      />
    </Stack>
  );
}
