import { FolderActionState } from 'src/misc/enums/Folder/Actions/FolderActionState';
import { FolderActionType } from 'src/misc/enums/Folder/Actions/FolderActionType';
import { Quiz } from 'src/model/model';

export class FolderAction {
  type: FolderActionType;

  state: FolderActionState;

  primaryText: string;

  secondaryText: string;

  extra: any;

  quiz: Quiz;

  constructor(data: Partial<FolderAction> = null, clone: boolean = false) {
    if (!clone && data instanceof FolderAction) return data;

    this.type = data?.type ?? null;
    this.state = data?.state ?? null;
    this.primaryText = data?.primaryText ?? '';
    this.secondaryText = data?.secondaryText ?? '';
    this.extra = data?.extra ?? null;
    this.quiz = data?.quiz ? new Quiz(data.quiz) : null;
  }
}
