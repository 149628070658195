import { Entity, Upload } from 'src/model/model';


export   class HandicapContact extends Entity {
  place: string | null;

  contacts: string | null;

  phone: string | null;

  email: string | null;

  upload: Upload | File;

  constructor(data: Partial<HandicapContact> = null, clone: boolean = false) {
    if (!clone && data instanceof HandicapContact) return data;
    super(data);

    this.place = data?.place ?? '';
    this.contacts = data?.contacts ?? '';
    this.email = data?.email ?? '';
    this.phone = data?.phone ?? '';
    this.upload = data?.upload ? new Upload(data.upload as Upload) : null;
  }
}
