import UserFactory from 'src/misc/factories/UserFactory';
import { FeedbackStatus } from 'src/misc/enums/Quality/FeedbackStatus';
import { FeedbackType } from 'src/misc/enums/Quality/FeedbackType';
import { Entity, User, TrainingCenter } from 'src/model/model';

import dayjs, { Dayjs } from 'dayjs';

export   class Feedback extends Entity {
  type: FeedbackType;

  user: User | null;

  status: FeedbackStatus;

  inputContent: string | null;

  outputContent: string | null;

  requestedAt: string | Dayjs;

  processedAt: string | Dayjs;

  constructor(data: Partial<Feedback> = null, clone: boolean = false) {
    if (!clone && data instanceof Feedback) return data;
    super(data);

    this.inputContent = data?.inputContent ?? '';
    this.outputContent = data?.outputContent ?? '';
    this.status = data?.status ?? null;
    this.type = data?.type ?? null;
    this.processedAt = data?.processedAt ? dayjs(data.processedAt) : null;
    this.requestedAt = data?.requestedAt ? dayjs(data.requestedAt) : null;
    this.user = data?.user ? UserFactory.create(data.user) : null;
  }
}
