import {
  alpha,
  Badge,
  IconButton,
  Tooltip,
  styled
} from '@mui/material';
import { useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import NotificationsList from 'src/components/NotificationsList/NotificationsList';
import { useTranslation } from 'react-i18next';


const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
`
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const {t} = useTranslation()

  return (
    <>
      <Tooltip arrow title={t('Notifications')}>
        <IconButtonWrapper color="secondary" ref={ref} onClick={() =>setOpen(true)}>
          <NotificationsBadge
            badgeContent={2}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButtonWrapper>
      </Tooltip>
      <NotificationsList open={isOpen} buttonRef={ref} handleClose={() => setOpen(false)}/>
    </>
  );
}

export default HeaderNotifications;
