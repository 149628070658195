import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel
} from '@mui/material';
import { check } from 'prettier';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from 'src/hooks/useIsMounted';
import { Option } from 'src/tools/form/types/Option';
import { InputProps } from '../InputBuilder';

export default function CheckboxGroupInput({
  controlProps,
  formField
}: InputProps) {
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<number[]>(
    controlProps.field.value
  );
  const isMounted = useIsMounted();
  const error = controlProps.fieldState.error;
  const { t } = useTranslation();

  useEffect(() => {
    (async function loadOptions() {
      const options = await formField.options();
      if (isMounted()) {
        const result = options.map((option) => {
          if (typeof option.label === 'string')
            option.label = t(option.label);
          return option;
        });
        setOptions(result);
      }
    })();
  }, []);

  if (options.length == 0) return <></>;

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    let newValue;
    const value = parseInt(event.target.value);
    if (checked) {
      newValue = [...selectedOptions, value];
    } else {
      newValue = selectedOptions.filter((option) => option !== value);
    }
    setSelectedOptions(newValue);
    controlProps.field.onChange(newValue);
    formField.onChange?.(newValue);
  };

  const isChecked = (valueId: number): boolean =>
    !!selectedOptions?.filter((optionId) => optionId === valueId).length;

  return (
    <FormControl size={'small'} variant={'outlined'} error={!!error}>
      <FormLabel component="legend">{formField.label}</FormLabel>
      <FormGroup {...(!formField.extras?.columnDirection ? { row: true } : {})}>
        {options.map((option: Option) => {
          return (
            <FormControlLabel
              style={{ marginRight: '2rem' }}
              control={
                <Checkbox
                  value={option.id}
                  onChange={handleChange}
                  checked={isChecked(option.id)}
                />
              }
              label={t(option.label)}
              key={option.id}
            />
          );
        })}
      </FormGroup>
      <FormHelperText>{!!error ? error.message : ''}</FormHelperText>
    </FormControl>
  );
}
