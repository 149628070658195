import { combineReducers } from '@reduxjs/toolkit';
import { reducer as moduleReducer } from 'src/slices/modules';
import { reducer as sessionReducer } from 'src/slices/sessions';
import { reducer as skillReducer } from 'src/slices/skills';
import { reducer as moduleBlockReducer } from 'src/slices/moduleBlock';
import { reducer as educationalPathReducer } from 'src/slices/educationalPath';
import { reducer as trainingCenterReducer } from 'src/slices/trainingCenters';
import { reducer as trainerReducer } from 'src/slices/trainers';
import { reducer as externalPersonReducer } from 'src/slices/externalPerson';
import { reducer as externalPersonFoldersReducer } from 'src/slices/externalPersonFolders';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as settingsReducer } from 'src/slices/settings';
import { reducer as folderDetailsReducer } from 'src/slices/folderDetails';
import { reducer as productSheetReducer } from 'src/slices/productSheet';
import { reducer as sessionTrackReducer } from 'src/slices/sessionTrack';

const rootReducer = combineReducers({
  module: moduleReducer,
  session: sessionReducer,
  skill: skillReducer,
  moduleBlock: moduleBlockReducer,
  educationalPath: educationalPathReducer,
  trainingCenter: trainingCenterReducer,
  trainer: trainerReducer,
  externalPerson: externalPersonReducer,
  externalPersonFolders: externalPersonFoldersReducer,
  calendar: calendarReducer,
  settings: settingsReducer,
  folderDetails: folderDetailsReducer,
  productSheet: productSheetReducer,
  sessionTrack: sessionTrackReducer,
});

export default rootReducer;
