import { Entity } from 'src/model/model';

export type JobSheetMainActivity = {
  title: string;
  content: string;
};

export   class JobSheet extends Entity {
  title: string = '';

  missions: string = '';

  knowledgesAndSkills: string = '';

  qualities: string = '';

  mainActivities: JobSheetMainActivity[] = [];

  constructor(data: Partial<JobSheet> = null, clone: boolean = false) {
    if (!clone && data instanceof JobSheet) return data;
    super(data);

    this.title = data?.title ?? '';
    this.missions = data?.missions ?? '';
    this.knowledgesAndSkills = data?.knowledgesAndSkills ?? '';
    this.qualities = data?.qualities ?? '';
    this.mainActivities = data?.mainActivities ?? [];
  }
}
