import { Entity, TaskFile, Skill, EducationalPath } from 'src/model/model';

export class Task extends Entity {
  description: string;

  taskFiles: TaskFile[];

  skill: Skill;

  label: string;

  educationalPath: EducationalPath;

  constructor(data: Partial<Task> = null, clone: boolean = false) {
    if (!clone && data instanceof Task) return data;
    super(data);

    this.description = data?.description ?? '';
    this.label = data?.label ?? '';
    this.skill = data?.skill ? new Skill(data?.skill) : null;
    this.educationalPath = data?.educationalPath
      ? new EducationalPath(data?.educationalPath)
      : null;
    this.taskFiles =
      data?.taskFiles?.map((taskFile: TaskFile) => new TaskFile(taskFile)) ??
      [];
  }
}
