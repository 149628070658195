import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { ModuleBlock } from 'src/model/model';
import { dispatchProductSheet } from './productSheet';

interface ModuleBlockState {
  moduleBlock: ModuleBlock;
}

const initialState: ModuleBlockState = {
  moduleBlock: null
};

const slice = createSlice({
  name: 'moduleBlock',
  initialState,
  reducers: {
    getModuleBlock(
      state: ModuleBlockState,
      action: PayloadAction<{ moduleBlock: ModuleBlock }>
    ) {
      const { moduleBlock } = action.payload;
      state.moduleBlock = moduleBlock;
    }
  }
});

export const reducer = slice.reducer;

export const dispatchModuleBlock =
  (moduleBlock: ModuleBlock): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.getModuleBlock({
        moduleBlock: moduleBlock ? new ModuleBlock(moduleBlock) : null
      })
    );
  };

export default slice;
