import { TrainingCenter } from 'src/model/model';
import { Option } from 'src/tools/form/types/Option';

export default class TrainingCenterHelper {
  public static ALL_TRAINING_CENTERS_OPTION: Option = {
    id: -1,
    label: "Tous les centres",
    groupBy: 'Tous'
  };

  static transformToOptions(trainingCenters: TrainingCenter[], withShowAll: boolean = false): Option[] {
    let array = [...trainingCenters]
    if (withShowAll) {
      array = [null, ...array];
    }
    return array.map((tc: TrainingCenter) =>
      TrainingCenterHelper.transformToOption(tc)
    );
  }

  static transformToOption(trainingCenter: TrainingCenter): Option {
    if (!trainingCenter) {
      return TrainingCenterHelper.ALL_TRAINING_CENTERS_OPTION
    }

    return {
      id: trainingCenter.id,
      label: trainingCenter.name,
      groupBy: trainingCenter.company.name
    };
  }

  static getTrainingCenterByIdOrOption(
    trainingCenters: TrainingCenter[],
    optionOrId: Option | number
  ): TrainingCenter {
    if (optionOrId === -1) return null;
    if (!optionOrId) return trainingCenters[0];
    if (typeof optionOrId === 'number') {
      return trainingCenters.find((tc) => tc.id === optionOrId);
    } else {
      return trainingCenters.find((tc) => tc.id === optionOrId.id);
    }
  }
}
