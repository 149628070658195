import { Entity, ChapterData, Module } from 'src/model/model';

export class Chapter extends Entity {
  title: string;

  mainChpater: Chapter;

  subChapters: Chapter[];

  chapterData: ChapterData[];

  module: Module;

  rank: number;

  constructor(data: Partial<Chapter> = null, clone: boolean = false) {
    if (!clone && data instanceof Chapter) return data;
    super(data);

    this.title = data?.title ?? '';
    this.module = data.module ? new Module(data.module) : null;
    this.rank = data.rank ?? 0;
    this.mainChpater = data.mainChpater ? new Chapter(data.mainChpater) : null;
    this.subChapters =
      data.subChapters?.map((subchapter: Chapter) => new Chapter(subchapter)) ??
      [];
    this.chapterData =
      data.chapterData?.map(
        (chapterData: ChapterData) => new ChapterData(chapterData)
      ) ?? [];
  }
}
