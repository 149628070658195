import { InputProps } from '../InputBuilder';
import { TextField } from '@mui/material';
import { Dayjs } from 'dayjs';
import { DateTimeFormat } from 'src/misc/enums/DateTimeFormat';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/lab';
import { useFormContext } from 'react-hook-form';

export default function DateTimePickerInput({
  formField,
  controlProps
}: InputProps) {
  const error = controlProps.fieldState.error;
  const { t } = useTranslation();
  const methods = useFormContext();

  const triggerChange = (date: Dayjs) => {
    const value = date?.format(DateTimeFormat.DefaultDateTimeFormatToAPI)
    controlProps.field.onChange(value);
    formField.onChange?.(value);
    formField.extras?.trigger?.map(async (observe) => {
      let data = await observe.getValue(value);
      methods.setValue(observe.name, data);
    });
  };

  return (
    <DateTimePicker
      ampm={false}
      ampmInClock={false}
      label={t(formField.label)}
      value={controlProps.field.value}
      onChange={triggerChange}
      views={formField.extras?.views ?? ['year', 'day', 'hours', 'minutes']}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          name={formField.name}
          error={!!error}
          helperText={!!error ? error.message : ''}
        />
      )}
    />
  );
}
