import { Entity, Upload, GeneratedFile } from 'src/model/model';

export class GeneratedFileImage extends Entity {
  upload: Upload | File;

  generatedFile: GeneratedFile | null;

  constructor(
    data: Partial<GeneratedFileImage> = null,
    clone: boolean = false
  ) {
    if (!clone && data instanceof GeneratedFileImage) return data;
    super(data);

    this.upload = data?.upload ? new Upload(data.upload as Upload) : null;
    this.generatedFile = data?.generatedFile
      ? new GeneratedFile(data.generatedFile)
      : null;
  }
}
