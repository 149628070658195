export enum UserRoles {
  ROLE_USER = 'ROLE_USER',
  ROLE_TRAINER = 'ROLE_TRAINER',
  ROLE_STUDENT = 'ROLE_STUDENT',
  ROLE_EMPLOYEE = 'ROLE_EMPLOYEE',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_ENTERRPISE = 'ROLE_ENTERPRISE',
  ROLE_DEV = "ROLE_DEV",
  ROLE_CAN_IMPERSONATE = "ROLE_CAN_IMPERSONATE",
}
