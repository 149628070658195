import dayjs, { Dayjs } from 'dayjs';
import { Entity, Session, PlanningException, Event } from 'src/model/model';

export class Planning extends Entity {
  parent: Planning = null;

  session: Session = null;

  planningExceptions: PlanningException[] = [];

  events: Event[] = [];

  plannings: Planning[] = [];

  startsAt: Dayjs;

  endsAt: Dayjs;

  constructor(data: Partial<Planning> = null, clone: boolean = false) {
    if (!clone && data instanceof Planning) return data;
    super(data);

    this.parent = data?.parent ? new Planning(data?.parent) : null;
    this.session = data?.session ? new Session(data?.session) : null;
    this.planningExceptions =
      data?.planningExceptions?.map(
        (planningException: PlanningException) =>
          new PlanningException(planningException)
      ) ?? [];
    this.events = data?.events?.map((event: Event) => new Event(event)) ?? [];
    this.plannings =
      data?.plannings?.map((planning: Planning) => new Planning(planning)) ??
      [];
    this.startsAt = data?.startsAt ? dayjs(data.startsAt) : null;
    this.endsAt = data?.endsAt ? dayjs(data.endsAt) : null;
  }
}
