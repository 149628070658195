import { TariffMode } from 'src/misc/enums/Tariff/TariffMode';
import { Entity, ModuleBlock, Product, Category } from 'src/model/model';

export class EducationalPath extends Entity {
  label: string;

  moduleBlocks: ModuleBlock[];

  product: Product;

  tariffMode: TariffMode = TariffMode.ProductSum;

  categories: Category[];

  constructor(data: Partial<EducationalPath> = null, clone: boolean = false) {
    if (!clone && data instanceof EducationalPath) return data;
    super(data);

    this.label = data?.label;
    this.product = data?.product ? new Product(data.product) : null;
    this.tariffMode = data?.tariffMode ?? TariffMode.ProductSum;
    this.moduleBlocks =
      data?.moduleBlocks?.map((moduleBlock) => new ModuleBlock(moduleBlock)) ??
      [];
    this.categories =
      data?.categories?.map((category: Category) => new Category(category)) ??
      [];
  }
}
