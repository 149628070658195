import { AbsenceReason, Folder, SessionItem } from '../../../model';

export type FolderAttendancePerDatPerTiming = {
  presence: boolean;
  justifiedAbsence: AbsenceReason;
};

export type FolderDayAttendance = {
  morning: FolderAttendancePerDatPerTiming;
  afternoon: FolderAttendancePerDatPerTiming;
};

export type FolderDaysAttendance = { [key: string]: FolderDayAttendance };

export class FolderSessionItemAttendanceStats {
  folder: Folder;

  sessionItem: SessionItem;

  presence: number;

  justifiedAbsences: number;

  unjustifiedAbsences: number;

  constructor(
    data: Partial<FolderSessionItemAttendanceStats> = null,
    clone: boolean = false
  ) {
    if (!clone && data instanceof FolderSessionItemAttendanceStats) return data;

    this.sessionItem = data?.sessionItem
      ? new SessionItem(data.sessionItem)
      : null;
    this.folder = data?.folder ? new Folder(data.folder) : null;
    this.presence = data?.presence ?? 0;
    this.justifiedAbsences = data?.justifiedAbsences ?? 0;
    this.unjustifiedAbsences = data?.unjustifiedAbsences ?? 0;
  }
}

export class FolderSessionItemAttendance {
  folder: Folder;

  sessionItem: SessionItem;

  attendance: FolderDaysAttendance;

  constructor(
    data: Partial<FolderSessionItemAttendance> = null,
    clone: boolean = false
  ) {
    if (!clone && data instanceof FolderSessionItemAttendance) return data;

    this.folder = data?.folder ? new Folder(data.folder) : null;
    this.sessionItem = data?.sessionItem
      ? new SessionItem(data.sessionItem)
      : null;
    this.attendance = data?.attendance
      ? Array.isArray(data.attendance) && data.attendance.length == 0
        ? {}
        : data.attendance
      : {};
  }
}
