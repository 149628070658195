import { Entity } from 'src/model/model';

export   class Address extends Entity {
  street: string;

  zipCode: string;

  country: string;

  city: string;

  constructor(data: Partial<Address> = null, clone: boolean = false) {
    if (!clone && data instanceof Address) return data;
    super(data);

    this.street = data?.street ?? '';
    this.zipCode = data?.zipCode ?? '';
    this.country = data?.country ?? '';
    this.city = data?.city ?? '';
  }
}
