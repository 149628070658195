import { Alert, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import useAuth from 'src/hooks/useAuth';
import { useHttp } from 'src/hooks/useHttp';
import useToast from 'src/hooks/useToast';
import { FeedbackStatus } from 'src/misc/enums/Quality/FeedbackStatus';
import { FeedbackType } from 'src/misc/enums/Quality/FeedbackType';
import { Feedback } from 'src/model/model';
import FeedbackApiFetcher from 'src/services/Quality/Api/FeedbackApiFetcher';
import FormBuilder from 'src/tools/form/components/FormBuilder/FormBuilder';
import Form from 'src/tools/form/core/Form';
import { InputType } from 'src/tools/form/types/InputType';
import { responseIsOk } from 'src/utils/fetcher';

type FeedbackModalProps = {
  closeModal: () => any;
};

export default function FeedbackModalContent(props: FeedbackModalProps) {
  const { sendRequest, isLoading } = useHttp();
  const { user } = useAuth();
  const toast = useToast();

  const handleSubmit = async (values: any) => {
    const response = await sendRequest(() => FeedbackApiFetcher.create(values));

    if (responseIsOk(response)) {
      toast.success('votre message a été envoyé');
      props.closeModal();
    } else {
      toast.error();
    }
  };

  return (
    <Stack gap={2} m={2}>
      <Alert severity="info">
        <Typography variant="h6">
          Le retour d'expérience vous permet de transmettre des informations à
          votre centre de formation. S'il s'agit d'une réclamation, merci de
          préciser le contexte de votre problème, nous ferons le maximum pour le
          résoudre ensemble.
        </Typography>
      </Alert>
      <FormBuilder
        form={
          new Form<Feedback>({
            fields: [
              {
                name: 'inputContent',
                label: 'Description',
                type: InputType.Textarea,
                rules: { required: true }
              }
            ],
            initialValues: new Feedback({
              user,
              type: FeedbackType.Reclamation,
              requestedAt: dayjs(),
              status: FeedbackStatus.ToDo
            }),
            handleSubmit
          })
        }
        isLoading={isLoading}
      />
    </Stack>
  );
}
