import dayjs, { Dayjs } from 'dayjs';
import { DateTimeFormat } from 'src/misc/enums/DateTimeFormat';
import { Entity, Company, Upload, Address } from 'src/model/model';

export class TrainingCenter extends Entity {
  name: string;

  morningStartsAt: string | Dayjs;

  morningBreakStartsAt: string | Dayjs;

  morningBreakEndsAt: string | Dayjs;

  lunchStartsAt: string | Dayjs;

  lunchEndsAt: string | Dayjs;

  afternoonBreakStartsAt: string | Dayjs;

  afternoonBreakEndsAt: string | Dayjs;

  afternoonEndsAt: string | Dayjs;

  company: Company;

  avatar: Upload | File;

  address: Address;

  restDays: number[];

  workHours: number;

  responsibleContact: string;

  constructor(data: Partial<TrainingCenter> = null, clone: boolean = false) {
    if (!clone && data instanceof TrainingCenter) return data;
    super(data);

    this.name = data?.name ?? '';
    this.morningStartsAt = data?.morningStartsAt
      ? dayjs(data?.morningStartsAt, DateTimeFormat.DefaultTimeFormatToApi)
      : null;
    this.morningBreakStartsAt = data?.morningBreakStartsAt
      ? dayjs(data?.morningBreakStartsAt, DateTimeFormat.DefaultTimeFormatToApi)
      : null;
    this.morningBreakEndsAt = data?.morningBreakEndsAt
      ? dayjs(data?.morningBreakEndsAt, DateTimeFormat.DefaultTimeFormatToApi)
      : null;
    this.lunchStartsAt = data?.lunchStartsAt
      ? dayjs(data?.lunchStartsAt, DateTimeFormat.DefaultTimeFormatToApi)
      : null;
    this.lunchEndsAt = data?.lunchEndsAt
      ? dayjs(data?.lunchEndsAt, DateTimeFormat.DefaultTimeFormatToApi)
      : null;
    this.afternoonBreakStartsAt = data?.afternoonBreakStartsAt
      ? dayjs(
          data?.afternoonBreakStartsAt,
          DateTimeFormat.DefaultTimeFormatToApi
        )
      : null;
    this.afternoonBreakEndsAt = data?.afternoonBreakEndsAt
      ? dayjs(data?.afternoonBreakEndsAt, DateTimeFormat.DefaultTimeFormatToApi)
      : null;
    this.afternoonEndsAt = data?.afternoonEndsAt
      ? dayjs(data?.afternoonEndsAt, DateTimeFormat.DefaultTimeFormatToApi)
      : null;
    this.address = data?.address ? new Address(data?.address) : null;
    this.avatar = data?.avatar ? new Upload(data?.avatar as Upload) : null;
    this.company = data?.company ? new Company(data?.company) : null;
    this.restDays = data?.restDays?.length ? data.restDays : [];
    this.workHours = data?.workHours ?? null;
    this.responsibleContact = data?.responsibleContact ?? null;
  }
}
