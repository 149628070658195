import { Entity, GeneratedFile, Upload, Trainer, SessionItem, RequiredFile, Folder } from 'src/model/model';


export class TrainerFile extends Entity {
  isSentByCenter: boolean;

  visibility: boolean;

  upload: Upload | File;

  type: number = 0;

  generatedFile: GeneratedFile | null;

  trainer: Trainer | null;

  label: string = null;

  sessionItem: SessionItem = null;

  requiredFile: RequiredFile = null;

  folder: Folder | null;

  constructor(data: Partial<TrainerFile> = null, clone: boolean = false) {
    if (!clone && data instanceof TrainerFile) return data;
    super(data);

    this.trainer = data?.trainer ? new Trainer(data.trainer) : null;
    this.isSentByCenter = data?.isSentByCenter ?? false;
    this.visibility = data?.visibility ?? false;
    this.upload = data?.upload ? new Upload(data.upload as Upload) : null;
    this.type = data?.type;
    this.label = data.label;
    this.sessionItem = data.sessionItem ? new SessionItem(data.sessionItem) : null;
    this.generatedFile = data?.generatedFile
      ? new GeneratedFile(data.generatedFile)
      : null;
    this.requiredFile = data?.requiredFile ? new RequiredFile(data.requiredFile) : null
    this.folder = data?.folder ? new Folder(data.folder) : null;
  }
}
