import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import ExtensionTwoToneIcon from '@mui/icons-material/ExtensionTwoTone';
import SupervisorAccountTwoToneIcon from '@mui/icons-material/SupervisorAccountTwoTone';
import BuildTwoToneIcon from '@mui/icons-material/BuildTwoTone';
import ContactPageTwoToneIcon from '@mui/icons-material/ContactPageTwoTone';
import FeedbackTwoToneIcon from '@mui/icons-material/FeedbackTwoTone';
import PublishedWithChangesTwoToneIcon from '@mui/icons-material/PublishedWithChangesTwoTone';
import ConstructionTwoToneIcon from '@mui/icons-material/ConstructionTwoTone';
import DocumentScannerTwoToneIcon from '@mui/icons-material/DocumentScannerTwoTone';
import LocalMallTwoToneIcon from '@mui/icons-material/LocalMallTwoTone';
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import DiscountTwoToneIcon from '@mui/icons-material/DiscountTwoTone';
import MilitaryTechTwoToneIcon from '@mui/icons-material/MilitaryTechTwoTone';
import MenuBlock from 'src/tools/menu/MenuBlock';
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import { RouteParts } from 'src/misc/enums/Router/RouteParts';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import FolderOpenTwoToneIcon from '@mui/icons-material/FolderOpenTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import { UserRoles } from 'src/misc/enums/Users/UserRoles';
import ApartmentTwoToneIcon from '@mui/icons-material/ApartmentTwoTone';

export const tcmMenuItems: MenuBlock[] = [
  {
    heading: I18nKeys.MENU_HEADER_GENERAL,
    items: [
      {
        name: I18nKeys.MENU_ITEM_DASHBOARD,
        icon: HomeTwoToneIcon,
        link: RouteParts.DASHBOARD
      }
    ]
  },
  {
    heading: I18nKeys.MENU_HEADER_CENTERS_MANAGEMENT,
    items: [
      {
        name: I18nKeys.MENU_ITEM_COMPANIES,
        icon: ApartmentTwoToneIcon,
        link: RouteParts.COMPANIES
      },
      {
        name: I18nKeys.MENU_ITEM_TRAINING_CENTERS,
        icon: SchoolTwoToneIcon,
        link: RouteParts.TRAINING_CENTERS
      }
    ],
    roles: [UserRoles.ROLE_ADMIN, UserRoles.ROLE_DEV]
  },
  {
    heading: I18nKeys.MENU_HEADER_SETTINGS,
    items: [
      {
        name: I18nKeys.MENU_ITEM_GENERAL_SETTINGS,
        icon: BuildTwoToneIcon,
        link: `${RouteParts.SETTINGS}/${RouteParts.SETTINGS_GENERAL_SETTINGS}`
      },
      {
        name: I18nKeys.MENU_ITEM_QUIZZES,
        icon: QuizTwoToneIcon,
        link: `${RouteParts.SETTINGS}/${RouteParts.QUIZZES}`
      },
      {
        name: I18nKeys.MENU_ITEM_DOCUMENTS,
        icon: ArticleTwoToneIcon,
        link: `${RouteParts.SETTINGS}/${RouteParts.DOCUMENTS}`
      }
    ]
  },
  {
    heading: I18nKeys.MENU_HEADER_TRAINING,
    items: [
      {
        name: I18nKeys.MENU_ITEM_MODULES,
        icon: MenuBookTwoToneIcon,
        link: `${RouteParts.TRAINING}/${RouteParts.TRAINING_MODULES}`
      },
      {
        name: I18nKeys.MENU_ITEM_MODULES_SKILLS,
        icon: MilitaryTechTwoToneIcon,
        items: [
          {
            name: I18nKeys.TITLE_SKILLS,
            icon: MilitaryTechTwoToneIcon,
            link: `${RouteParts.TRAINING}/${RouteParts.TRAINING_SKILLS}`
          },
          {
            name: I18nKeys.TITLE_PERFORMANCE_CRITERIA,
            icon: ListTwoToneIcon,
            link: `${RouteParts.TRAINING}/${RouteParts.TRAINING_PERFORMANCE_CRITERIAS}`
          }
        ]
      },
      {
        name: I18nKeys.MENU_ITEM_PRODUCTS,
        icon: LocalMallTwoToneIcon,
        items: [
          {
            name: I18nKeys.MENU_ITEM_SIMPLE_PRODUCTS,
            icon: ListTwoToneIcon,
            link: `${RouteParts.TRAINING}/${RouteParts.TRAINING_PRODUCTS}/${RouteParts.TRAINING_SIMPLE_PRODUCT}`
          },
          {
            name: I18nKeys.MENU_ITEM_ED_PATHS,
            icon: ExtensionTwoToneIcon,
            link: `${RouteParts.TRAINING}/${RouteParts.TRAINING_EDUCATIONAL_PATH}`
          },
          {
            name: I18nKeys.MENU_ITEM_DISCOUNTS,
            icon: DiscountTwoToneIcon,
            link: `${RouteParts.TRAINING}/${RouteParts.TRAINING_PRODUCTS}/${RouteParts.TRAINING_DISCOUNTS}`
          },
          {
            name: I18nKeys.MENU_ITEM_TVA,
            icon: ReceiptLongTwoToneIcon,
            link: `${RouteParts.TRAINING}/${RouteParts.TRAINING_PRODUCTS}/${RouteParts.TRAINING_TVA}`
          }
        ]
      },
      {
        name: I18nKeys.MENU_ITEM_SESSIONS,
        icon: TodayTwoToneIcon,
        link: `${RouteParts.TRAINING}/${RouteParts.TRAINING_SESSIONS}`
      },
      {
        name: I18nKeys.MENU_ITEM_LEARNERS,
        icon: SchoolTwoToneIcon,
        items: [
          {
            name: I18nKeys.MENU_ITEM_FOLDERS,
            icon: FolderOpenTwoToneIcon,
            link: `${RouteParts.TRAINING}/${RouteParts.TRAINING_FOLDERS}`
          },
          {
            name: I18nKeys.MENU_ITEM_PEOPLE,
            icon: PersonOutlineTwoToneIcon,
            link: `${RouteParts.TRAINING}/${RouteParts.TRAINING_LEARNERS}`
          }
        ]
      },
      {
        name: I18nKeys.MENU_ITEM_ENTERPRISE,
        icon: WorkTwoToneIcon,
        link: `${RouteParts.TRAINING}/${RouteParts.TRAINING_ENTERPRISES}`
      }
    ]
  },
  {
    heading: I18nKeys.MENU_HEADER_TEAMS,
    items: [
      {
        name: I18nKeys.MENU_ITEM_EXTERNAL_TEAM,
        icon: SupervisorAccountTwoToneIcon,
        link: `${RouteParts.TEAMS}/${RouteParts.TEAMS_EXTERNAL}`
      },
      {
        name: I18nKeys.MENU_ITEM_INTERNAL_TEAM,
        icon: AdminPanelSettingsTwoToneIcon,
        link: `${RouteParts.TEAMS}/${RouteParts.TEAMS_INTERNAL}`
      },
      {
        name: I18nKeys.MENU_ITEM_JOB_SHEET,
        icon: ContactPageTwoToneIcon,
        link: `${RouteParts.TEAMS}/${RouteParts.TEAMS_JOB_SHEET}`
      }
    ]
  },
  {
    heading: I18nKeys.MENU_HEADER_QUALITY,
    items: [
      {
        name: I18nKeys.MENU_ITEM_FEEDBACK,
        icon: FeedbackTwoToneIcon,
        link: `${RouteParts.QUALITY}/${RouteParts.QUALITY_FEEDBACK}`
      },
      {
        name: I18nKeys.MENU_ITEM_DEV_PROSPECTS,
        icon: PublishedWithChangesTwoToneIcon,
        link: `${RouteParts.QUALITY}/${RouteParts.QUALITY_DEV_PROSPECTS}`
      },
      {
        name: I18nKeys.MENU_ITEM_WATCH,
        icon: DocumentScannerTwoToneIcon,
        link: `${RouteParts.QUALITY}/${RouteParts.QUALITY_WATCHES}`
      },
      {
        name: I18nKeys.MENU_ITEM_HANDICAP,
        icon: PeopleAltTwoToneIcon,
        link: `${RouteParts.QUALITY}/${RouteParts.QUALITY_HANDICAP}`
      },
      {
        name: I18nKeys.MENU_ITEM_MATERIALS,
        icon: ConstructionTwoToneIcon,
        link: `${RouteParts.QUALITY}/${RouteParts.QUALITY_MATERIALS}`
      }
    ]
  }
];
