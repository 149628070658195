import {
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { InputProps } from '../InputBuilder';

export default function ColorPickerInput({
  formField,
  controlProps
}: InputProps) {
  const { t } = useTranslation();
  const error = controlProps.fieldState.error;
  return (
    <FormControl
      error={!!error}
      fullWidth
      disabled={formField.extras?.disabled}
      sx={{ mb: 1 }}
    >
      <Stack gap={1}>
        <Box display={'flex'} justifyContent="center" alignItems={'center'}>
          <Typography variant="subtitle1">{t(formField.label)}</Typography>
        </Box>
        <Box display={'flex'} justifyContent="center" alignItems={'center'}>
          <input
            type={'color'}
            name={formField.name}
            value={controlProps.field.value ?? ''}
            onChange={(e) => {
              controlProps.field.onChange(e);
              formField.onChange?.(e.target.value);
            }}
          />
        </Box>
      </Stack>
      <FormHelperText>{!!error ? error.message : ''}</FormHelperText>
    </FormControl>
  );
}
