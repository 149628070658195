import dayjs, { Dayjs } from 'dayjs';
import { InterventionState } from 'src/misc/enums/Intervention/InterventionState';
import { Entity, Trainer, Folder, Event, TrainerPurchaseOrder } from 'src/model/model';

export class Intervention extends Entity {
  dailyAverageRate: number;

  commission: number;

  hours: number;

  requestSentAt: string | Dayjs;

  responseSentAt: string | Dayjs;

  state: InterventionState;

  event: Event;

  folder: Folder;

  trainer: Trainer;

  trainerPurchaseOrder: TrainerPurchaseOrder | null;

  constructor(data: Partial<Intervention> = null, clone: boolean = false) {
    if (!clone && data instanceof Intervention) return data;
    super(data);

    this.dailyAverageRate = data?.dailyAverageRate ?? 0;
    this.commission = data?.commission ?? 0;
    this.hours = data?.hours ?? 0;
    this.requestSentAt = data?.requestSentAt
      ? dayjs(data?.requestSentAt)
      : null;
    this.responseSentAt = data?.responseSentAt
      ? dayjs(data?.responseSentAt)
      : null;
    this.state = data?.state ?? InterventionState.Pending;
    this.event = data?.event ? new Event(data?.event) : null;
    this.trainerPurchaseOrder = data?.trainerPurchaseOrder ? new TrainerPurchaseOrder(data?.trainerPurchaseOrder) : null;
    this.folder = data?.folder ? new Folder(data?.folder) : null;
    this.trainer = data?.trainer ? new Trainer(data.trainer) : null;
  }
}
