import { Navigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { RouteParts } from 'src/misc/enums/Router/RouteParts';
import { RoutePrefix } from 'src/misc/enums/Router/RoutePrefix';
import { UserRoles } from 'src/misc/enums/Users/UserRoles';

export default function DefaultRoute() {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to={`/${RouteParts.LOGIN}`} replace />;
  }

  if (user.hasRole(UserRoles.ROLE_EMPLOYEE))
    return <Navigate to={RoutePrefix.TCM} replace />;

  if (user.hasRole(UserRoles.ROLE_STUDENT))
    return <Navigate to={RoutePrefix.STUDENT} replace />;

  if (user.hasRole(UserRoles.ROLE_TRAINER))
    return <Navigate to={RoutePrefix.TRAINER} replace />;
}