import { EventColor } from 'src/misc/enums/Events/EventType';
import { GeneratedFilesDefaultStyle } from 'src/misc/enums/File/GeneratedFilesDefaultStyle';
import { Entity } from 'src/model/model';

export class Settings extends Entity {
  logMail: string = '';

  daysBeforeSendingConvocation: number;

  daysBeforeSendingConvocationRecall: number;

  eventColors: EventColor[];

  generatedFilesStyle: GeneratedFilesDefaultStyle;

  constructor(data: Partial<Settings> = null, clone: boolean = false) {
    if (!clone && data instanceof Settings) return data;
    super(data);

    this.logMail = data?.logMail ?? '';
    this.daysBeforeSendingConvocation = data?.daysBeforeSendingConvocation;
    this.daysBeforeSendingConvocationRecall =
      data?.daysBeforeSendingConvocationRecall;
    this.eventColors = data?.eventColors ?? [];
    this.generatedFilesStyle = data?.generatedFilesStyle ?? {
      h1: null,
      h2: null,
      p: null
    };
  }
}
