import { QuizTarget } from 'src/misc/enums/Quiz/QuizTarget';
import { QuizTiming } from 'src/misc/enums/Quiz/QuizTiming';
import { QuizType } from 'src/misc/enums/Quiz/QuizType';
import { Entity, ChapterData, Session, Chapter, Question, Category } from 'src/model/model';

export   class Quiz extends Entity {
  label: string = '';

  isArchived: boolean = false;

  type: QuizType = null;

  target: QuizTarget = null;

  timing: QuizTiming = null;

  chapterData: ChapterData | null = null;

  questions: Question[] = [];

  categories: Category[] = [];

  constructor(data: Partial<Quiz> = null, clone: boolean = false) {
    if (!clone && data instanceof Quiz) return data;
    super(data);

    this.label = data?.label ?? '';
    this.isArchived = data?.isArchived ?? false;
    this.type = data?.type ?? null;
    this.target = data?.target ?? null;
    this.timing = data?.timing ?? null;
    this.questions =
      data?.questions?.map((chapter: Question) => new Question(chapter)) ?? [];
    this.categories =
      data?.categories?.map((category: Category) => new Category(category)) ?? [];
  }
}
