import { InputLabel, Stack, TextareaAutosize, useTheme } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { InputProps } from '../InputBuilder';

export default function TextareaInput({ controlProps, formField }: InputProps) {
  const error = controlProps.fieldState.error;
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack>
      <InputLabel
        sx={{
          color: theme.palette.primary.main
        }}
      >
        {t(formField.label)}
      </InputLabel>
      <TextareaAutosize
        id={formField.name}
        name={formField.name}
        value={controlProps.field.value}
        onChange={(e) => {
          controlProps.field.onChange(e);
          formField.onChange?.(e.target.value);
        }}
        style={{
          borderRadius: '8px',
          border: '1px solid ' + theme.palette.grey[400],
          padding: '8px',
          fontFamily: theme.typography.fontFamily,
          fontSize: '16px',
          lineHeight: '20px'
        }}
        minRows={3}
      />
      {!!error && (
        <p
          className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled helperText"
          style={{
            color: theme.palette.error.main
          }}
        >
          {error.message}
        </p>
      )}
    </Stack>
  );
}
