import { Alert, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import FormBuilder from 'src/tools/form/components/FormBuilder/FormBuilder';
import Logo from 'src/components/Logo/Logo';
import useAuth from 'src/hooks/useAuth';
import { useHttp } from 'src/hooks/useHttp';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import Form from 'src/tools/form/core/Form';
import { AuthCredentials } from 'src/misc/types/AuthCredentials';
import { LoginPageFlags } from '../LoginPage';
import { loginFormFields } from './LoginFormFields';

type LoginFormProps = {
  setMode: Dispatch<SetStateAction<LoginPageFlags>>;
  isRecoveryMailSent: boolean;
};

export default function LoginForm({
  setMode,
  isRecoveryMailSent
}: LoginFormProps) {
  const { sendRequest, isLoading, error } = useHttp();
  const { login } = useAuth();
  const { t } = useTranslation();

  const handleSubmit = async (values: AuthCredentials) => {
    await sendRequest(() => login(values.email, values.password));
  };

  const form = new Form<AuthCredentials>({
    fields: loginFormFields,
    handleSubmit,
    initialValues: new AuthCredentials()
  });

  return (
    <Stack gap={3} margin={1} marginBottom={2}>
      <Logo disableLink />
      <Stack>
        <Typography variant="h3" marginLeft={'1.5rem'}>
          {t(I18nKeys.TITLE_LOGIN)}
        </Typography>
        {error && (
          <Alert
            style={{ margin: '0.5rem 1.2rem' }}
            variant="standard"
            severity="error"
          >
            {t(error.message)}
          </Alert>
        )}
        {isRecoveryMailSent && (
          <Alert
            style={{ margin: '0.5rem 1.2rem' }}
            variant="standard"
            severity="info"
          >
            {t(I18nKeys.API_MAIL_SENT)}
          </Alert>
        )}
        <FormBuilder
          form={form}
          isLoading={isLoading}
          submitButton={{
            text: t(I18nKeys.LABEL_LOGIN),
            style: { width: '100%' },
            icon: null
          }}
          extraContentProps={{
            afterContentProps: { password: { setMode } }
          }}
        />
      </Stack>
    </Stack>
  );
}
