import { Folder } from '../Folder';
import { FolderAction } from './FolderAction';

export class MissingFolderActions {
  folder: Folder;
 
  actions: FolderAction[];

  constructor(data: Partial<MissingFolderActions> = null, clone: boolean = false) {
    if (!clone && data instanceof MissingFolderActions) return data;

    this.folder = data?.folder ? new Folder(data.folder) : null;
    this.actions = data?.actions?.map((action: FolderAction) => new FolderAction(action)) ?? [];
  }
}
