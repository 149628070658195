import {
  Entity,
  Skill,
  Category,
  Chapter,
  Upload,
  ModuleFile,
  ModuleProcedure
} from 'src/model/model';
export class Module extends Entity {
  label: string;

  reference: string;

  level: number;

  duration: number;

  isAttendanceDisabled: boolean;

  attendanceSequenceCode: string;

  skills: Skill[];

  categories: Category[];

  chapters: Chapter[];

  pedagogicScenario: Upload | File;

  moduleFiles: ModuleFile[];

  moduleProcedures: ModuleProcedure[];

  isArchived: boolean;

  constructor(data: Partial<Module> = null, clone: boolean = false) {
    if (!clone && data instanceof Module) return data;
    super(data);

    this.label = data?.label ?? '';
    this.reference = data?.reference ?? '';
    this.level = data?.level ?? null;
    this.duration = data?.duration ?? 0;
    this.attendanceSequenceCode = data?.attendanceSequenceCode ?? '';
    this.isAttendanceDisabled = data?.isAttendanceDisabled ?? false;
    this.skills = data?.skills?.map((skill: Skill) => new Skill(skill)) ?? [];
    this.categories =
      data?.categories?.map((category: Category) => new Category(category)) ??
      [];
    this.chapters =
      data?.chapters?.map((chapter) => new Chapter(chapter)) ?? [];
    this.pedagogicScenario = data?.pedagogicScenario
      ? new Upload(data?.pedagogicScenario as Upload)
      : null;
    this.isArchived = data?.isArchived ?? false;
    this.moduleFiles =
      data?.moduleFiles?.map(
        (moduleFile: ModuleFile) => new ModuleFile(moduleFile)
      ) ?? [];
    this.moduleProcedures =
      data?.moduleProcedures?.map(
        (moduleProcedure: ModuleProcedure) =>
          new ModuleProcedure(moduleProcedure)
      ) ?? [];
  }
}
