import { Entity, ModuleBlock, Module } from 'src/model/model';


export   class Course extends Entity {
  module: Module;

  moduleBlock: ModuleBlock;

  constructor(data: Partial<Course> = null, clone: boolean = false) {
    if (!clone && data instanceof Course) return data;
    super(data);

    this.module = data?.module ? new Module(data?.module) : null;
    this.moduleBlock = data?.moduleBlock
      ? new ModuleBlock(data.moduleBlock)
      : null;
  }
}
