export enum DateTimeFormat {
  DefaultDateFormatToAPI = 'YYYY-MM-DD',
  DefaultDateFormatToDisplay = 'DD/MM/YYYY',
  DefaultTimeFormatToApi = 'YYYY-MM-DD HH:mm',
  DefaultTimeFormatToDisplay = 'HH:mm',
  YearFormat = 'YYYY',
  DefaultDateTimeFormatToAPI = 'YYYY-MM-DD HH:mm:ss',
  DefaultDateTimeFormatToDisplay = 'DD/MM/YYYY HH:mm',
  MonthFormat = 'MMMM-YYYY',
  LiteralFormat = 'dddd DD MMMM YYYY'
}
