import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { Skill } from 'src/model/model';

interface SkillState {
  skill: Skill;
}

const initialState: SkillState = {
  skill: null
};

const slice = createSlice({
  name: 'skill',
  initialState,
  reducers: {
    getSkill(state: SkillState, action: PayloadAction<{ skill: Skill }>) {
      state.skill = action.payload.skill;
    }
  }
});

export const reducer = slice.reducer;

export const dispatchSkill =
  (skill: Skill): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.getSkill({
        skill: skill ? new Skill(skill) : null
      })
    );
  };

export default slice;
