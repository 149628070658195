const getInitials = (fullName: string) => {
  const seperatedNames = fullName.trim().split(' ');

  let initials = '';

  seperatedNames.forEach((name) => {
    if (name.length) {
      initials += name[0].toUpperCase();
    }
  });

  return initials;
};

export default getInitials;
