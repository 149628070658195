import { useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import Avatar from '../Avatar/Avatar';
import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { Upload } from 'src/model/model';
import { UserBoxMenuItem, UserBoxMenuItemModal } from 'src/tools/menu/MenuItem';
import Menu from 'src/tools/menu/Menu';
import Modal from '../Modal/Modal';

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

type UserBoxProps = {
  lightText?: boolean;
};

export default function Userbox(props: UserBoxProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, logout, isImpersonated } = useAuth();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<UserBoxMenuItemModal>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const theme = useTheme();

  const handleOpen = (): void => setOpen(true);

  const handleClose = (): void => setOpen(false);

  const handleCloseModal = () => {
    setOpenModal(false);
    setModal(null);
  };

  const handleLogout = async (): Promise<void> => {
    handleClose();
    await logout();
    navigate('/');
  };

  const fullname = user.getFullname();
  const avatar = user.avatar as Upload;
  const path = avatar?.path ?? '';

  return (
    <>
      <Button
        sx={{ p: theme.spacing(0, 0.5), height: theme.spacing(6) }}
        color="secondary"
        ref={ref}
        onClick={handleOpen}
      >
        <Avatar src={path} alt={fullname} />
        <Box
          component="span"
          sx={{
            display: { xs: 'none', md: 'inline-block' }
          }}
        >
          <UserBoxText>
            <UserBoxLabel
              variant="body1"
              style={props.lightText ? { color: 'white' } : {}}
            >
              {fullname}
            </UserBoxLabel>
            <UserBoxDescription variant="body2">
              {t(user.getUserRoleJob())}
            </UserBoxDescription>
          </UserBoxText>
        </Box>
        <Box
          component="span"
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1
            }}
          />
        </Box>
      </Button>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Avatar src={path} alt={user.getFullname()} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{fullname}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {t(user.getUserRoleJob())}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <List
          sx={{
            p: 1
          }}
          component="nav"
        >
          {new Menu(user).userBoxMenuItems.map(
            (menuItem: UserBoxMenuItem, index: number) => {
              if (menuItem.divider) return <Divider />;
              let Icon = menuItem.icon as any;
              return (
                <ListItem
                  key={index}
                  onClick={() => {
                    if (menuItem.modal) {
                      setModal(menuItem.modal);
                      setOpenModal(true);
                    }
                    handleClose();
                  }}
                  button={true}
                  disabled={menuItem.disabled}
                  {...(menuItem.to
                    ? { to: menuItem.to, component: NavLink }
                    : {})}
                >
                  <Icon />
                  <ListItemText primary={menuItem.label} />
                </ListItem>
              );
            }
          )}
        </List>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LogoutTwoToneIcon sx={{ mr: 1 }} />
            {isImpersonated ? 'Revenir à mon compte' : t(I18nKeys.LABEL_LOGOUT)}
          </Button>
        </Box>
      </Popover>
      <Modal
        title={modal?.title}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        body={modal?.body(() => setOpenModal(false))}
        size={modal?.size}
      />
    </>
  );
}
