import { Entity } from 'src/model/model';
import { Option } from 'src/tools/form/types/Option';

type Constructor<T extends Entity> = new (data: Partial<T>) => T;

export default class EntityHelper {
  static merge<T extends Entity>(array: T[], entity: T): T[] {
    let output: T[] = [];

    array.forEach((item: T) => {
      if (item.id === entity.id) {
        output.push(entity);
      } else {
        output.push(item);
      }
    });

    return output;
  }

  static contains(array: Entity[], entity: Entity): boolean {
    return !!array.filter((item) => item.id === entity.id).length;
  }

  static transformToFormOption<T extends Entity = Entity>(
    entity: T,
    getLabel: (entity: T) => string
  ): Option {
    return { id: entity.id, label: getLabel(entity as T) };
  }

  static transformToFormOptions<T extends Entity = Entity>(
    entities: T[],
    getLabel: (entity: T) => string
  ): Option[] {
    return entities.map((entity) =>
      EntityHelper.transformToFormOption(entity, getLabel)
    );
  }

  static find<T extends Entity = Entity>(array: T[], id: number) {
    return array.find((e) => e.id === id);
  }
}
