import { FileType } from 'src/misc/enums/File/FileType';
import {
  Entity,
  Folder,
  GeneratedFile,
  RequiredFile,
  Student,
  Upload
} from 'src/model/model';

export class StudentFile extends Entity {
  folder: Folder | null;

  generatedFile: GeneratedFile | null;

  student: Student | null;

  file: Upload | File;

  isSentByCenter: boolean = false;

  visibility: boolean = false;

  type: FileType = FileType.Administrative;

  label: string = null;

  isSent: boolean = false;

  requiredFile: RequiredFile = null;

  constructor(data: Partial<StudentFile> = null, clone: boolean = false) {
    if (!clone && data instanceof StudentFile) return data;
    super(data);
    this.file = data?.file ? new Upload(data.file as Upload) : null;
    this.isSentByCenter = data.isSentByCenter;
    this.visibility = data.visibility;
    this.folder = data?.folder ? new Folder(data.folder) : null;
    this.student = data?.student ? new Student(data.student) : null;
    this.label = data.label;
    this.isSent = data.isSent;
    this.requiredFile = data?.requiredFile ? new RequiredFile(data.requiredFile) : null
    this.generatedFile = data?.generatedFile
      ? new GeneratedFile(data.generatedFile)
      : null;
  }
}
