import {
  Entity,
  Folder,
  Task,
  TaskFile,
  TaskAnswer,
  SessionItem
} from 'src/model/model';

export class TrainerTask extends Entity {
  task: Task;

  trainerFolder: Folder;

  sessionItem: SessionItem;

  taskFiles: TaskFile[];

  taskAnswers: TaskAnswer[];

  description: string;

  constructor(data: Partial<TrainerTask> = null, clone: boolean = false) {
    if (!clone && data instanceof TrainerTask) return data;
    super(data);

    this.task = data?.task ? new Task(data?.task) : null;
    this.description = data?.description;
    this.sessionItem = data?.sessionItem
      ? new SessionItem(data?.sessionItem)
      : null;
    this.trainerFolder = data?.trainerFolder
      ? new Folder(data?.trainerFolder)
      : null;
    this.taskFiles =
      data?.taskFiles?.map(
        (trainerTask: TaskFile) => new TaskFile(trainerTask)
      ) ?? [];
    this.taskAnswers =
      data?.taskAnswers?.map(
        (trainerTask: TaskAnswer) => new TaskAnswer(trainerTask)
      ) ?? [];
  }
}
