import { Entity, PerformanceCriteria, Category, Task } from 'src/model/model';

export class Skill extends Entity {
  label: string;

  performanceCriterias: PerformanceCriteria[];

  categories: Category[];

  tasks: Task[];

  constructor(data: Partial<Skill> = null, clone: boolean = false) {
    if (!clone && data instanceof Skill) return data;
    super(data);

    this.label = data?.label ?? '';
    this.performanceCriterias =
      data?.performanceCriterias?.map(
        (performanceCriteria: PerformanceCriteria) =>
          new PerformanceCriteria(performanceCriteria)
      ) ?? [];
    this.categories =
      data?.categories?.map((category: Category) => new Category(category)) ??
      [];
    this.tasks =
      data?.tasks?.map((task: Task) => new Task(task)) ??
      [];
  }
}
