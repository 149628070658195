import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { devMode } from 'src/api-path';
import UnauthorizedError from 'src/misc/Errors/UnauthorizedError';
import ApiResponse from 'src/misc/types/ApiResponse';
import { responseIsOk } from 'src/utils/fetcher';
import useAuth from './useAuth';
import useIsMounted from './useIsMounted';
import useSuspenseLoader from './useSuspenseLoader';
import useToast from './useToast';

type UseHttpOptions = {
  globalLoader?: boolean;
  errorBoundary?: boolean;
};

/**
 * A custom hook built for sending HTTP requests and handling loading and errors state
 * @param options - object of booleans that changes the behaviour of loading and error handling
 * @field `globalLoader` it triggers the global suspense component for loading while sending request, by default it's false
 * @field `errorBoundary` it triggers the global error boundary, by default it's false
 * @returns `sendRequest` function responsible for sending HTTP requests
 * @returns `isLoading` boolean referring the loading state of the request
 * @returns `error` boolean referring the state of the response
 * @returns `clearError` function responsible for clearing response errors
 */
export const useHttp = (
  options: UseHttpOptions = {
    globalLoader: false,
    errorBoundary: false
  }
) => {
  const { globalLoader, errorBoundary } = options;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { loaderOn, loaderOff } = useSuspenseLoader();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const auth = useAuth();
  const toast = useToast();

  if (errorBoundary && error) {
    throw error;
  }

  /**
   * A function resposible for sending HTTP requests
   * @param callBack - callback function containing the logic of fetching
   * @returns the response data
   * @throws fetching errors
   */
  const sendRequest = useCallback(
    async <T>(callBack: (...args: any) => Promise<ApiResponse<T>>) => {
      setIsLoading(true);
      if (globalLoader) {
        loaderOn();
      }
      let response: ApiResponse<T>;
      try {
        response = await callBack();
        if (!responseIsOk(response)) {
          throw new Error(response?.message);
        }
      } catch (error) {
        setError(error);
        if (error instanceof UnauthorizedError) {
          auth.logout(false);
          toast.error(t(error.message));
        }
        if (devMode) console.error(error.message);
      } finally {
        if (isMounted()) {
          setIsLoading(false);
        }
        if (globalLoader) {
          loaderOff();
        }
        return response;
      }
    },
    []
  );

  const clearError = (callBack?: Function) => {
    setError(null);
    if (callBack) {
      callBack();
    }
  };

  return { sendRequest, isLoading, error, clearError };
};
