import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { Option } from 'src/tools/form/types/Option';

export enum Target {
    Student = 1,
    Trainer,
    Enterprise
}

export const targetsAsOptions: Option[] = [
    {
        id: Target.Student,
        label: I18nKeys.FORM_LABEL_STUDENT,
    },
    {
        id: Target.Trainer,
        label: I18nKeys.FORM_LABEL_TRAINER
    },
    /*{
        id: Target.Enterprise,
        label: I18nKeys.FORM_LABEL_ENTERPRISE
    },*/

]

export function getTargetLabel(target: Target) {
    switch (target) {
        case Target.Student:
            return I18nKeys.FORM_LABEL_STUDENT;
        case Target.Trainer:
            return I18nKeys.FORM_LABEL_TRAINER;
        case Target.Enterprise:
            return I18nKeys.FORM_LABEL_ENTERPRISE;
    }

}