import dayjs from 'dayjs';
import { DateTimeFormat } from 'src/misc/enums/DateTimeFormat';
import addressFormData from 'src/misc/shared/addressFormData';
import { Option } from 'src/tools/form/types/Option';
import { GetAllParams } from 'src/misc/types/Paginator/GetAllParams';
import Paginated from 'src/misc/types/Paginator/Paginated';
import { TrainingCenter } from 'src/model/model';
import { fetcher } from 'src/utils/fetcher';
import { generateQueryParams } from 'src/utils/generateQueryParams';

const PREFIX = '/api/training-centers';

export default class TrainingCenterApiFetcher {
  static async getAll(params: GetAllParams) {
    const response = await fetcher.get<Paginated<TrainingCenter[]>>(
      PREFIX + generateQueryParams(params)
    );
    response.data.data = response.data.data.map(
      (trainingCenter) => new TrainingCenter(trainingCenter)
    );
    return response;
  }

  static async getAllNotPaginated() {
    const response = await fetcher.get<TrainingCenter[]>(`${PREFIX}/all`);
    response.data = response.data.map(
      (trainingCenter) => new TrainingCenter(trainingCenter)
    );
    return response;
  }

  static async create(values: any) {
    let formData = new FormData();

    formData = TrainingCenterApiFetcher.initFormData(formData, values);

    if (values.avatar instanceof File) formData.set('avatar', values.avatar);

    return fetcher.post<TrainingCenter>(`${PREFIX}`, formData);
  }

  static async update(values: any) {
    let formData = new FormData();

    formData = TrainingCenterApiFetcher.initFormData(formData, values);

    if (values.avatar) {
      if (values.avatar instanceof File) {
        formData.set('avatar', values.avatar);
      } else {
        formData.set('avatar', values.avatar.id);
      }
    }

    const response = await fetcher.post<TrainingCenter>(
      `${PREFIX}/${values.id}`,
      formData
    );

    response.data = new TrainingCenter(response.data);

    return response;
  }

  static async scheduleUpdate(values: TrainingCenter) {
    values.morningStartsAt = values.morningStartsAt
      ? dayjs(values.morningStartsAt).format(
          DateTimeFormat.DefaultTimeFormatToApi
        )
      : null;
    values.afternoonEndsAt = values.afternoonEndsAt
      ? dayjs(values.afternoonEndsAt).format(
          DateTimeFormat.DefaultTimeFormatToApi
        )
      : null;
    values.morningBreakStartsAt = values.morningBreakStartsAt
      ? dayjs(values.morningBreakStartsAt).format(
          DateTimeFormat.DefaultTimeFormatToApi
        )
      : null;
    values.morningBreakEndsAt = values.morningBreakEndsAt
      ? dayjs(values.morningBreakEndsAt).format(
          DateTimeFormat.DefaultTimeFormatToApi
        )
      : null;
    values.lunchStartsAt = values.lunchStartsAt
      ? dayjs(values.lunchStartsAt).format(
          DateTimeFormat.DefaultTimeFormatToApi
        )
      : null;
    values.lunchEndsAt = values.lunchEndsAt
      ? dayjs(values.lunchEndsAt).format(DateTimeFormat.DefaultTimeFormatToApi)
      : null;
    values.afternoonBreakStartsAt = values.afternoonBreakStartsAt
      ? dayjs(values.afternoonBreakStartsAt).format(
          DateTimeFormat.DefaultTimeFormatToApi
        )
      : null;
    values.afternoonBreakEndsAt = values.afternoonBreakEndsAt
      ? dayjs(values.afternoonBreakEndsAt).format(
          DateTimeFormat.DefaultTimeFormatToApi
        )
      : null;

    const response = await fetcher.put<TrainingCenter>(
      `${PREFIX}/${values.id}/schedule`,
      values
    );

    response.data = new TrainingCenter(response.data);

    return response;
  }

  static async updateRestDays(values: TrainingCenter) {
    const dataToSend = {
      ...values,
      restDays: JSON.stringify(values.restDays)
    };
    const response = await fetcher.put<TrainingCenter>(
      `${PREFIX}/${values.id}/rest-days`,
      dataToSend
    );
    response.data = new TrainingCenter(response.data);

    return response;
  }

  static async delete(id: number) {
    return fetcher.delete(`${PREFIX}/${id}`);
  }

  static async getAllForSelectOptions() {
    const response = await fetcher.get<Option[]>(`${PREFIX}/select/options`);
    return response.data;
  }

  private static initFormData(formData: FormData, values: any): FormData {
    formData.set('name', values.name);
    formData.set('manager', values.manager?.id ?? values.manager);
    formData.set('company', values.company?.id ?? values.company);
    formData.set("responsibleContact", values.responsibleContact)
    addressFormData(formData, values.address);

    return formData;
  }
}
