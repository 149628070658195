import { Entity, Session, Employee, Job } from 'src/model/model';

export class SessionReferent extends Entity {
  employee: Employee;

  session: Session;

  job: Job;

  comment: string;

  constructor(data: Partial<SessionReferent> = null, clone: boolean = false) {
    if (!clone && data instanceof SessionReferent) return data;
    super(data);

    this.comment = data?.comment ?? '';
    this.session = data?.session ? new Session(data.session) : null;
    this.employee = data?.employee ? new Employee(data.employee) : null;
    this.job = data?.job ? new Job(data.job) : null;
  }
}
