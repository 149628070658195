import { Entity, Room } from 'src/model/model';


export   class Equipment extends Entity {
  label: string;

  comment: string;

  amount: number;

  room: Room | null;

  constructor(data: Partial<Equipment> = null, clone: boolean = false) {
    if (!clone && data instanceof Equipment) return data;
    super(data);

    this.label = data?.label ?? '';
    this.comment = data?.comment ?? '';
    this.amount = data?.amount ?? 0;
    this.room = data?.room ? new Room(data.room) : null;
  }
}
