import { I18nKeys } from 'src/i18n/translations/I18nKeys';
import { Option } from 'src/tools/form/types/Option';

export enum EventType {
  Blended = 1,
  FaceToFace = 2,
  ELearning = 3,
  Holiday = 4,
  Exception = 5
}

export type EventColor = {
  type: EventType;
  color: string;
};

export type EventColorsFormInputs = {
  [EventType.Blended]: string;
  [EventType.FaceToFace]: string;
  [EventType.ELearning]: string;
  [EventType.Holiday]: string;
  [EventType.Exception]: string;
};

export const eventTypeOptions: Option[] = [
  {
    id: EventType.Blended,
    label: I18nKeys.FORM_LABEL_BLENDED
  },
  {
    id: EventType.FaceToFace,
    label: I18nKeys.FORM_LABEL_FACE_TO_FACE
  },
  {
    id: EventType.ELearning,
    label: I18nKeys.FORM_LABEL_ELEARNING
  }
];

export const eventTypes: Option[] = [
  ...eventTypeOptions,
  {
    id: EventType.Holiday,
    label: 'Jours férié'
  },
  {
    id: EventType.Exception,
    label: 'Exception'
  }
];

export function getEventTypeLabel(eventType: EventType): string {
  return eventTypes.find((option) => option.id === Number(eventType)).label;
}

export function getEventTypeEnum(eventType: number): EventType {
  return eventTypes.find((option) => option.id === eventType).id;
}
