import { Entity, Folder, Task, TaskAnswerFile, TrainerTask } from 'src/model/model';


export class TaskAnswer extends Entity {
  link: string;

  folder: Folder | null;

  trainerTask: TrainerTask | null;

  taskAnswerFiles: TaskAnswerFile[];

  constructor(data: Partial<TaskAnswer> = null, clone: boolean = false) {
    if (!clone && data instanceof TaskAnswer) return data;
    super(data);

    this.link = data?.link ?? '';
    this.folder = data?.folder ? new Folder(data?.folder) : null;
    this.trainerTask = data?.trainerTask ? new TrainerTask(data?.trainerTask) : null;
    this.taskAnswerFiles =
      data?.taskAnswerFiles?.map(
        (taskAnswerFile: TaskAnswerFile) => new TaskAnswerFile(taskAnswerFile)
      ) ?? [];
  }
}
