import { GridBreakpoints } from "src/misc/types/GridBreakpoints";
import FormField from "./FormField";
import Row from "./Row";


export default class Column {
    readonly id?: number;
  
    viewWidth: GridBreakpoints;
  
    content: FormField | Row;
  
    constructor(viewWidth: GridBreakpoints, content: FormField | Row) {
      this.id = Math.random();
      this.viewWidth = viewWidth;
      this.content = content;
    }
  
    static isColumn(object: any): object is Column {
      if (object instanceof Column) return true;
  
      let test = object.content && object.viewWidth;
      if (!test) return !!test;
  
      return FormField.isFormField(object.content) || Row.isRow(object.content);
    }
  }