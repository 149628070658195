export class SessionTrainingHours {
  faceToFaceHours: number;

  elearningHours: number;

  blendedHours: number;

  constructor(
    data: Partial<SessionTrainingHours> = null,
    clone: boolean = false
  ) {
    this.faceToFaceHours = data?.faceToFaceHours ?? 0;
    this.elearningHours = data?.elearningHours ?? 0;
    this.blendedHours = data?.blendedHours ?? 0;
  }
}
