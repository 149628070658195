import { Entity, SessionFile } from 'src/model/model';

export class SessionFileType extends Entity {
  label: string;

  sessionFiles: SessionFile[];

  isArchived: boolean;

  constructor(data: Partial<SessionFileType> = null, clone: boolean = false) {
    if (!clone && data instanceof SessionFileType) return data;
    super(data);

    this.label = data?.label ?? '';
    this.isArchived = data?.isArchived ?? false;

    this.sessionFiles =
      data?.sessionFiles?.map(
        (sessionFile: SessionFile) => new SessionFile(sessionFile)
      ) ?? [];
  }
}
