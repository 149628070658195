import { Entity} from 'src/model/model';

export   class Discount extends Entity {
  label: string;

  percentage: number;

  constructor(data: Partial<Discount> = null, clone: boolean = false) {
    if (!clone && data instanceof Discount) return data;
    super(data);

    this.label = data?.label;
    this.percentage = data?.percentage;
  }
}
