import { EventInput } from '@fullcalendar/react';
import dayjs from 'dayjs';
import {
  EventColor,
  EventColorsFormInputs,
  EventType,
  getEventTypeEnum,
  getEventTypeLabel
} from 'src/misc/enums/Events/EventType';
import { Event, Session } from 'src/model/model';
import store from 'src/store';
import Column from 'src/tools/form/core/Column';
import { FormElement } from 'src/tools/form/core/Form';
import FormField from 'src/tools/form/core/FormField';
import { InputType } from 'src/tools/form/types/InputType';

export default class EventHelper {
  static findEvent(events: Event[], eventId: number): Event {
    return events.find((event) => +event.id === eventId);
  }

  static getEventColor(event: Event): string {
    return store
      .getState()
      .settings.settings.eventColors.find(
        (eventColor) => eventColor.type === (event.type ?? EventType.FaceToFace)
      ).color;
  }

  static transformToFullCalendarEvent(event: Event): EventInput {
    const fullCalendarEvent: EventInput = {
      ...event,
      start: dayjs(event.start).toDate(),
      end: dayjs(event.end).toDate(),
      id: event.id + ''
    };

    return fullCalendarEvent;
  }

  static transformToFullCalendarEvents(events: Event[]): EventInput[] {
    return events.map((event) =>
      EventHelper.transformToFullCalendarEvent(event)
    );
  }

  static eventColorsToEventColorsFormInputs(
    eventColors: EventColor[]
  ): EventColorsFormInputs {
    let output: EventColorsFormInputs = {
      [EventType.Blended]: '',
      [EventType.FaceToFace]: '',
      [EventType.ELearning]: '',
      [EventType.Holiday]: '',
      [EventType.Exception]: ''
    };

    eventColors.map((eventColor) => {
      output[eventColor.type] = eventColor.color;
    });

    return output;
  }

  static eventColorsFormInputsToEventColors(
    eventColorFormInputs: EventColorsFormInputs
  ): EventColor[] {
    let output: EventColor[] = [];

    Object.keys(eventColorFormInputs).map((eventType) => {
      output.push({
        type: getEventTypeEnum(+eventType),
        color: eventColorFormInputs[eventType]
      });
    });

    return output;
  }

  static getEventColorsFormElements(
    eventColors: EventColor[],
    itemsPerRow: number = 3
  ): FormElement[] {
    let output: FormElement[] = [];
    const columnXs = 12 / itemsPerRow;
    const rest = eventColors.length % itemsPerRow;
    const numberOfCompleteElements = eventColors.length - rest;
    const numberOfCompleteElemetsRows = numberOfCompleteElements / itemsPerRow;

    let j = 0;
    for (let i = 0; i < numberOfCompleteElemetsRows; i++) {
      j += itemsPerRow;
      let columns: Column[] = [];

      for (let k = 0; k < j; k++) {
        columns.push({
          viewWidth: { xs: columnXs },
          content: EventHelper.transformEventColorToColorFormField(
            eventColors[k]
          )
        });
      }
      output.push({ columns });
    }
    let columns: Column[] = [];

    for (let l = j; l < j + rest; l++) {
      columns.push({
        viewWidth: { xs: 12 / rest },
        content: EventHelper.transformEventColorToColorFormField(eventColors[l])
      });
    }
    output.push({ columns });

    return output;
  }

  static transformEventColorToColorFormField(
    eventColor: EventColor
  ): FormField {
    return {
      name: Number(eventColor.type).toLocaleString(),
      label: getEventTypeLabel(eventColor.type),
      type: InputType.Color
    };
  }

  static transformEventsToEditable(events: Event[]) {
    return events.map((event) => {
      if (
        !(event.type === EventType.Exception) &&
        !(event.type === EventType.Holiday)
      ) {
        event.editable = true;
      }
      return event;
    });
  }
}
